import React from "react";
import { Font, Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import SideLogo from "../sideLogo/side-logo";
import { images } from "../../../assets";

Font.register({
	family: "Nunito",
	fontStyle: "normal",
	fonts: [
		{
			src: "https://fonts.gstatic.com/s/nunito/v16/XRXW3I6Li01BKofAksCkZg.ttf",
			fontWeight: 800,
		},
		{
			src: "https://fonts.gstatic.com/s/nunito/v16/XRXW3I6Li01BKofAjsOkZg.ttf",
			fontWeight: 700,
		},
		{
			src: "https://fonts.gstatic.com/s/nunito/v16/XRXV3I6Li01BKof4Mg.ttf",
			fontWeight: 400,
		},
	],
});

// Create styles
const styles = StyleSheet.create({
	page: {
		backgroundColor: "#fff",
		width: "100%",
		height: "100%",
	},
	homeMainContent: {
		padding: "3%",
		width: "100%",
		height: "100%",
		display: "flex",
		alignItems: "center",
		justifyContent: "space-around",
		flexDirection: "column",
	},
	viewHomeLogo: {
		padding: 0,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		// backgroundColor: 'rgba(0,0,0,.5)'
	},
	dateText: {
		marginBottom: "35px",
		fontSize: "40px",
		color: "#BE1622",
		letterSpacing: "0.2px",
		textAlign: "center",
		fontFamily: "Nunito",
		fontWeight: 800,
	},
	titleText: {
		marginTop: "5px",
		fontSize: "16px",
		color: "#BE1622",
		letterSpacing: "0.2px",
		textAlign: "center",
		fontFamily: "Nunito",
		fontWeight: 400,
	},
	objectiveText: {
		marginTop: "35px",
		fontSize: "35px",
		color: "#BE1622",
		letterSpacing: "0.2px",
		textAlign: "center",
		fontFamily: "Nunito",
		fontWeight: 700,
	},
	imageProduct: {
		width: 700,
		height: "auto",
		borderTopLeftRadius: 4,
		borderTopRightRadius: 4,
		borderBottomRightRadius: 4,
		borderBottomLeftRadius: 4,
	},
	border: {
		borderTopLeftRadius: 4,
		borderTopRightRadius: 4,
		borderBottomRightRadius: 4,
		borderBottomLeftRadius: 4,
		backgroundColor: "#BE1622",
		border: "2px solid #BE1622",
	},
});

const PageHome = ({ liveName, liveDate, thumb }) => {
	return (
		<Page size="A4" orientation="landscape" style={styles.page}>
			{/* LOGO FIXED */}
			<SideLogo />

			<View style={styles.homeMainContent}>
				<Text style={styles.dateText}>{liveDate}</Text>
				<View style={styles.viewHomeLogo}>
					{/* LOGO CENTER */}
					<View style={styles.border}>
						<Image style={styles.imageProduct} src={`${thumb}`} />
					</View>
					<Text style={styles.titleText}>{liveName}</Text>
				</View>
				<Text style={styles.objectiveText}>RELATÓRIO GERAL</Text>
			</View>
		</Page>
	);
};

export default PageHome;
