import { createTypes } from "reduxsauce";

export const liveTypes = createTypes(
  `
  SET_CHAT
  SET_FAVORITOS
  SET_PRODUTOS
  SET_GRADES
  RESET_STATE
  SET_STARTED
  SET_FORMAS_PAGAMENTO
  SET_TEST_USERS
`,
  { prefix: "LIVE/" }
);
var resp = [];

const setChat = (chat) => {
  if (!Object.keys(chat).find((item) => item === 0)) {
    resp.push(chat);
  } else {
    resp = chat;
  }

  return (dispatch) => {
    dispatch({ type: liveTypes.SET_CHAT, chat: resp });
  };
};
const setFavoritos = (favoritos) => {
  return (dispatch) => {
    dispatch({ type: liveTypes.SET_FAVORITOS, favoritos });
  };
};
const setStarted = () => {
  return (dispatch) => {
    dispatch({ type: liveTypes.SET_STARTED });
  };
};
const setProdutos = (produtos) => {
  return (dispatch) => {
    dispatch({ type: liveTypes.SET_PRODUTOS, produtos });
  };
};
const setGrades = (grades) => {
  return (dispatch) => {
    dispatch({ type: liveTypes.SET_GRADES, grades });
  };
};
const setFormasPagamento = (formasPagamento) => {
  return (dispatch) => {
    dispatch({ type: liveTypes.SET_FORMAS_PAGAMENTO, formasPagamento });
  };
};
const resetState = () => {
  return (dispatch) => {
    dispatch({ type: liveTypes.RESET_STATE });
  };
};
const setTestUsers = (testUsers) => {
  return (dispatch) => {
    dispatch({ type: liveTypes.SET_TEST_USERS, testUsers });
  };
};

export const liveActions = {
  setChat,
  setFavoritos,
  setProdutos,
  setGrades,
  resetState,
  setStarted,
  setFormasPagamento,
  setTestUsers,
};
