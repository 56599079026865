import React, { useEffect, useState } from "react";
import * as s from "./styled-salas-tab";
import { Button, Check } from "../../../../components";
import { storageRef } from "../../../../services";

const CardSalaUnica = ({
	setSalaUnica,
	salaUnica,
	liveId,
	saving,
	setChanges,
	aoVivo,
}) => {
	const [introP, setIntroP] = useState();
	const [videoP, setVideoP] = useState();
	const [sala, setSala] = useState(salaUnica);
	const [hasVideo, setHasVideo] = useState(false);

	const checkExists = (videoUrl) => {
		var v = document.createElement("video");
		v.src = videoUrl;

		v.onerror = () => {
			setHasVideo(false);
		};

		v.addEventListener("loadedmetadata", () => {
			setHasVideo(true);
		});
	};

	useEffect(() => {
		if (sala.chat || sala.live || sala.intro) setSalaUnica(sala);
		checkExists(sala?.live);

		return () => setHasVideo(false);
	}, [sala, setSalaUnica]);

	const uploadVideo = (file, intro) => {
		const uploadTask = storageRef
			.child(
				`lives/${liveId}/${intro ? "intro" : "live"}-sala-unica-${file.name}`,
			)
			.put(file);
		let newSala = sala;
		uploadTask.on(
			"state_changed",
			(snapshot) => {
				let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
				if (intro) setIntroP(progress);
				else setVideoP(progress);
			},
			() => {
				alert("Ocorreu um erro ao tentar realizar seu upload :(");
				if (intro) setIntroP();
				else setVideoP();
			},
			() => {
				uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
					if (intro) {
						newSala.intro = downloadURL;
						setIntroP();
					} else {
						newSala.live = downloadURL;
						setVideoP();
					}
					checkExists(downloadURL);
					setSala(newSala);
				});
			},
		);
	};

	return (
		<section className="fade-in" style={{ maxHeight: "280px" }}>
			<s.Title>Sala única para todos os usuários</s.Title>
			<div className="header-card-sala">
				<div className="sala" style={{ width: "130px" }}>
					Sala única
				</div>
			</div>
			<input
				type="file"
				id={`video-sala-unica`}
				style={{ display: "none" }}
				onChange={(e) => e.target.files[0] && uploadVideo(e.target.files[0])}
				accept="video/*"
			/>
			<label>
				Esta é a sala única, vai servir para todos os usuários desta live.
			</label>

			<Button
				disabled={videoP || saving || aoVivo}
				green={hasVideo}
				label={
					aoVivo
						? "Live ao vivo!"
						: videoP
						? `${parseInt(videoP)}%`
						: hasVideo
						? "Alterar video"
						: "Carregar video"
				}
				style={{ margin: "20px 22px" }}
				onClick={() => document.getElementById(`video-sala-unica`).click()}
			/>
			<input
				type="file"
				id={`video-intro-unica`}
				style={{ display: "none" }}
				accept="video/*"
				onChange={(e) =>
					e.target.files[0] && uploadVideo(e.target.files[0], true)
				}
			/>

			<Button
				disabled={saving}
				green={sala.intro}
				label={
					introP
						? `${parseInt(introP)}%`
						: sala.intro
						? "Alterar introdução"
						: "Adicionar introdução"
				}
				style={{ margin: "0 22px" }}
				outline={!sala.intro}
				onClick={() => document.getElementById(`video-intro-unica`).click()}
			/>
			<s.Chat active={sala.chat}>
				<Check
					disabled={saving}
					active={sala.chat}
					onClick={() => {
						setChanges(true);
						setSala({ ...sala, chat: !sala.chat });
					}}
				/>
				Habilitar chat
			</s.Chat>
		</section>
	);
};

export default CardSalaUnica;
