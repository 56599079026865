import styled from "styled-components";
import { colors, images } from "../../assets";

export const Container = styled.div`
	width: 100%;
	min-height: 100vh;
	background: ${colors.gray};
	background-image: url(${images.bg});
	background-repeat: no-repeat;
	background-size: cover;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

export const Lives = styled.div`
	width: 100%;
	max-width: 584px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-bottom: 37px;
`;

export const Logo = styled.img`
	width: 60vw;
	max-width: 383px;
	margin: 45px auto;
`;

export const LiveContainer = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	cursor: ${(props) => (props.finalizada ? "not-allowed" : "pointer")};
	width: 282px;

	h1 {
		font-weight: 900;
		font-size: 16px;
		color: #ffffff;
		text-transform: uppercase;
		text-align: center;

		background: #e94e5c;
		width: 100%;
		padding: 5px 0;

		border-radius: 4px 4px 0px 0px;
	}

	h2 {
		font-weight: 900;
		font-size: 18px;
		text-transform: uppercase;
		color: #ffffff;

		background: #be1622;

		width: 100%;
		height: 30px;

		display: flex;
		align-items: center;
		justify-content: center;
		margin: auto;
		padding-top: 10px;
		transform: translateY(-10px);

		border-radius: 0px 0px 14px 14px;
		${(props) => !props.display && "opacity: 0;"}
	}
`;

export const Live = styled.div`
	width: 100%;
	pointer-events: none;
	z-index: 1;

	display: grid;
	background: white;
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1), -1px -1px 3px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1),
		-1px -1px 3px rgba(0, 0, 0, 0.1);
	-webkit-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1),
		-1px -1px 3px rgba(0, 0, 0, 0.1);
	border-radius: 4px;

	.image-card {
		width: 282px;
		height: 111px;
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
		overflow: hidden;
		background-size: cover;
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-color: #ffffff;
		position: relative;

		img {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 100%;
			height: fit-content;
		}
	}

	label {
		padding: 5px 8px;
		height: 40px;
		font-weight: 700;
		font-size: 16px;
		text-align: center;

		word-break: break-all;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
`;
