import React, { useEffect, useState } from "react";
import * as s from "./styled-login";
import { useDispatch } from "react-redux";
import { usuariosActions } from "../../store";
import { Button, RadioButton } from "../../components";
import { usuarioService } from "../../services";
import { images } from "../../assets";
import { mascaraCNPJ, orderLives } from "../../utils";

const Login = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [cnpj, setCnpj] = useState("");
	const [useCnpj, setUseCnpj] = useState(true);
	const [error, setError] = useState();
	const [manager, setManager] = useState(false);
	const [password, setPassword] = useState("");
	const dispatch = useDispatch();

	useEffect(() => {
		localStorage.clear();
	}, []);

	const tryLogin = (event) => {
		event.preventDefault();
		setIsLoading(true);
		if (manager) {
			usuarioService
				.loginManager(cnpj, password, manager.token)
				.then(() => {
					dispatch(usuariosActions.setUsuario(manager));
					setIsLoading(false);
				})
				.catch((error) => {
					setIsLoading(false);
					setError(error);
				});
		} else {
			usuarioService
				.login(cnpj, useCnpj)
				.then((data) => {
					// console.log(data);
					if (data.manager) {
						setManager({ ...data, manager: true });
						setIsLoading(false);
					} else {
						localStorage.setItem("isCnpj", useCnpj);
						dispatch(usuariosActions.setUsuario(data));
						setIsLoading(false);
					}
					localStorage.setItem("user", cnpj);
				})
				.catch((error) => {
					setError(error);
					setIsLoading(false);
				});
		}
	};

	return (
		<s.Container className="fade-in">
			<s.Login>
				<s.Logo src={"./logo.svg"} className="fade-in" />
				<s.FormContainer onSubmitCapture={(e) => tryLogin(e)}>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							flexWrap: "wrap",
						}}>
						<label className="entrar-com">
							{manager ? "Digite sua senha" : "Entrar com:"}
						</label>
						<div style={{ display: "flex", marginLeft: "10px" }}>
							{!manager && (
								<>
									<RadioButton
										label={"CNPJ"}
										value={useCnpj}
										onChange={() => setUseCnpj(true)}
										style={{ margin: "0 10px" }}
									/>
									<RadioButton
										label={"Código de usuário"}
										value={!useCnpj}
										onChange={() => setUseCnpj(false)}
										style={{ margin: "0 10px" }}
									/>
								</>
							)}
						</div>
					</div>
					{manager ? (
						<s.Input
							disabled={isLoading}
							className="animated-linear"
							type="password"
							name="senha"
							id="senha-manager"
							value={password || ""}
							onChange={(v) => {
								setPassword(v.target.value);
								setError();
							}}
						/>
					) : (
						<s.Input
							disabled={isLoading}
							className="animated-linear"
							name="cnpj"
							value={useCnpj ? mascaraCNPJ(cnpj) : cnpj}
							onChange={({ target }) => {
								setCnpj(target.value);
								setError();
							}}
							maxLength={useCnpj ? 18 : 99}
						/>
					)}
					<div className="footer-login">
						<Button
							style={{ width: "180px", height: "50px", fontSize: "14px" }}
							label={"Entrar"}
							isLoading={isLoading}
							disabled={!cnpj}
							onClick={tryLogin}
						/>
					</div>
				</s.FormContainer>
			</s.Login>
			<s.Error className="fade-in">{error}</s.Error>
		</s.Container>
	);
};

export default Login;
