import React, { useState, useEffect, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import * as s from "./styled-favoritos";
import { icons, images } from "../../assets";
import Produto from "./produto";
import { produtosService } from "../../services/index.js";
import Modal from "react-modal";
import { Conclusao } from "../";
import { Button } from "../../components";
Modal.setAppElement(document.getElementById("root"));

const Favoritos = ({ noHeader, show }) => {
  const live = useSelector((state) => state.live);
  const { produtos, grades } = live;
  const [favoritos, setFavoritos] = useState([]); //Favoritos agrupados por id (para agrupar cores)
  const [favoritados, setFavoritados] = useState([]); //Favoritados temporariamente na modal de add cor
  const [pares, setPares] = useState({}); //Quantidade de pares dos produtos por id
  const [grade, setGrade] = useState({}); //Grades dos produtos por id
  const [deleting, setDeleting] = useState(false);
  const [deletados, setDeletados] = useState([]); //Lista de produtos deletados usada para animação
  const [aplicar, setAplicar] = useState(false);
  const [isOpen, setIsOpen] = useState(false); //Produto selecionado para add mais cores
  const [size, setSize] = useState({ height: 0, width: 0 }); //Tamanho dos favoritos
  const [conclusao, setConclusao] = useState(false); //Modal de conclusão, está aqui porque a conclusão não é renderizada quando fechamos o pedido
  const [realizouPedido, setRealizouPedido] = useState(false); //Altera texto quando o usuário já realizou algum pedido

  const resizeWindow = () => {
    let divElement = document.getElementById("player-container");
    if (divElement) {
      let elemHeight = divElement.offsetHeight;
      let elemWidth = divElement.offsetWidth;
      setSize({
        height: elemHeight - 2 + "px",
        width: elemWidth - 2 + "px",
      });
    }
  };

  useLayoutEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  useEffect(() => setFavoritados(live.favoritos.map((p) => p.produto)), [
    live.favoritos,
  ]);

  useEffect(() => {
    let fav = live.favoritos;
    let newFavoritos = {};
    let ids = {};
    fav.forEach((favorito, i) => {
      if (newFavoritos[ids[favorito.produto.ID]])
        newFavoritos[ids[favorito.produto.ID]].push(favorito);
      else {
        ids[favorito.produto.ID] = i;
        newFavoritos[i] = [favorito];
      }
    });
    let data = [];
    Object.entries(newFavoritos).map((prod) => data.unshift(prod[1]));
    let newGrade = {};
    let newPares = {};
    data.forEach((prod, i) => {
      prod.forEach((p, i2) => {
        let prodGrade = [];
        let gradeTotal;
        grades.forEach((g) => {
          if (g.id === p.grade) {
            prodGrade = g.tamanXquantid;
            gradeTotal = g.gradeTotal;
          }
        });
        newPares = { ...newPares, [p.docId]: p.quantidade };
        newGrade = {
          ...newGrade,
          [p.docId]: {
            label: `Grade ${p.grade}`,
            value: p.grade,
            grade: prodGrade,
            gradeTotal,
          },
        };
        if (i + 1 === data.length && i2 + 1 === prod.length) {
          setFavoritos(data);
          setGrade(newGrade);
          setPares(newPares);
        }
      });
    });
  }, [live]);

  const removeProd = (prod) => {
    setDeleting(prod);
    setTimeout(() => setDeletados([...deletados, prod.docId]), 800);
    produtosService.deleteFavorito(prod.docId).then(() => setDeleting());
  };

  const verifyHearth = (prod) => {
    let test = false;
    favoritos.forEach((items) => {
      items.forEach((p) => {
        if (
          p.produto.ID === prod.ID &&
          p.produto.COR === prod.COR &&
          p.produto.CABEDAL === prod.CABEDAL
        )
          test = true;
      });
    });
    return test;
  };

  const addItems = () => {
    favoritados.map((item) => {
      if (!verifyHearth(item)) {
        let gradeTotal;
        grades.forEach((g) => {
          if (g.id === item.GRADE_DISPONIVEL.split(",")[0])
            gradeTotal = g.gradeTotal;
        });
        produtosService.addFavorito(
          item,
          item.GRADE_DISPONIVEL.split(",")[0],
          gradeTotal,
          item.docId
        );
      }
    });
  };

  const addItem = (prod) => {
    if (!favoritados.includes(prod)) setFavoritados([...favoritados, prod]);
    else {
      let newFavoritados = [];
      favoritados.forEach((p) => p !== prod && newFavoritados.push(p));
      setFavoritados(newFavoritados);
    }
  };

  if (!show) return <div />;
  return (
    <s.Favoritos maxWidth={size.width}>
      <Modal
        isOpen={isOpen ? true : false}
        style={s.customStyles}
        onRequestClose={() => setIsOpen(false)}
      >
        <s.ModalContainer>
          <div className="title">
            <h1>SELECIONAR CORES</h1>
          </div>
          <div className="list">
            {produtos.map(
              (prod) =>
                prod.ID === isOpen && (
                  <s.ProdModal
                    key={prod.docId}
                    onClick={() => addItem(prod)}
                    className="animated-linear"
                  >
                    <img
                      alt="hearth"
                      src={
                        verifyHearth(prod) || favoritados.includes(prod)
                          ? icons.hearthRed
                          : icons.hearthGray
                      }
                      className={`hearth ${
                        verifyHearth(prod) ||
                        (favoritados.includes(prod) && `pulse-heart-fav`)
                      }`}
                    />
                    <img
                      alt="produto-pequeno"
                      src={prod.IMAGEM_PEQUENA || images.default}
                    />
                    <div className="ref-container">
                      <label className="ref">{prod.COR}</label>
                    </div>
                  </s.ProdModal>
                )
            )}
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              paddingBottom: "20px",
            }}
          >
            <Button
              label="Confirmar"
              onClick={() => {
                setIsOpen(false);
                addItems();
              }}
            />
          </div>
        </s.ModalContainer>
      </Modal>
      <Modal
        isOpen={conclusao ? true : false}
        style={s.customStyles}
        onRequestClose={() => setConclusao(false)}
      >
        <s.ModalContainer style={{ padding: "20px" }}>
          <h1
            style={{ fontSize: "14px", fontWeight: "600", textAlign: "center" }}
          >
            {conclusao !== 2
              ? `Obrigado por comprar conosco! A solicitação do seu pedido está em análise e uma cópia foi enviada para seu e-mail. Você pode continuar favoritando para gerar outro pedido!`
              : "Uma cópia do seu pedido foi enviada para seu email com sucesso!"}
          </h1>
          <Button
            label={"Entendi"}
            onClick={() => setConclusao(false)}
            style={{ margin: "20px auto 0 auto" }}
          />
        </s.ModalContainer>
      </Modal>
      <s.Content>
        {!noHeader && (
          <s.Header>
            <img
              alt="hearth-favoritos"
              src={icons.hearthWhite}
              id={"hearth-favoritos"}
            />
          </s.Header>
        )}
        <s.List border={favoritos.length > 0 ? true : false}>
          {live.favoritos.length > 0 ? (
            favoritos &&
            favoritos.map((items, i) => (
              <Produto
                key={`carosel-${items[0].ID}-${i}`}
                items={items}
                i={i}
                removeProd={removeProd}
                deleting={deleting}
                deletados={deletados}
                openColors={setIsOpen}
                setGrade={setGrade}
                pares={pares}
                setPares={setPares}
                setAplicar={setAplicar}
                aplicar={aplicar}
                grade={grade}
                last={i + 1 === favoritos.length}
              />
            ))
          ) : (
            <s.Empty>
              <img alt="imagem-vazio" src={images.empty} />
              <h1>
                {realizouPedido
                  ? "Seu último pedido foi enviado por email. Por isso sua sacola "
                  : "Sua sacola ainda "}
                está vazia!{" "}
                <strong>
                  Comece a <br></br>favoritar produtos para adicioná-los aqui.
                </strong>
              </h1>
            </s.Empty>
          )}
        </s.List>
      </s.Content>
      {live.favoritos.length > 0 && favoritos && (
        <Conclusao
          pares={pares}
          setConclusao={setConclusao}
          setRealizouPedido={setRealizouPedido}
        />
      )}
    </s.Favoritos>
  );
};

export default Favoritos;
