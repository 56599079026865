import styled from "styled-components";

export const Container = styled.div`
  border-radius: 4px;
  video {
    border-radius: 4px;
    outline: none;
  }
  background: #3f3f3f;
  box-shadow: -10px -10px 30px rgba(0, 0, 0, 0.08),
    10px 10px 30px rgba(0, 0, 0, 0.08);
  display: flex;
  position: relative;
  flex-direction: ${({ ladoCard: l }) => (l === "centro" ? "row" : "column")};
  align-items: ${({ ladoCard: l }) =>
    l === "direito" ? `flex-end` : l === "esquerdo" ? "flex-start" : "center"};
`;

export const Poppup = styled.img`
  transition: all 2s;
  width: 85px;
  height: 75px;
  cursor: pointer;
  position: absolute;
  z-index: 99;
  ${({ ladoCard: l, bottom: b }) =>
    l === "direito"
      ? "padding-right: 10px;"
      : l === "esquerdo"
      ? "margin-left: 10px;"
      : b
      ? "right: 120px;"
      : "left: 120px;"}
  margin-top: ${({ bottom: b, ladoCard: l }) =>
    l === "centro" ? "0px" : b ? "95px" : "10px"};
`;
