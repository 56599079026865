import styled from "styled-components";
import { colors } from "../../assets";

export const Favoritos = styled.div`
  margin: 0 20px 20px 20px;
  border-radius: 4px;
  flex: 1;
  @media (min-width: 1450px) {
    max-width: ${(props) => props.maxWidth};
  }
  @media (max-width: 725px) {
    max-width: calc(${(props) => props.maxWidth} - 0px + 2px);
    /* precisa fazer isso para não aparecer a barra de rolagem na parte inferior do navegador */
  }
  @media (max-width: 1450px) {
    margin: 0;
  }
`;
export const ModalContainer = styled.div`
  background: #f4f4f4;
  border-radius: 4px;
  max-width: 340px;
  .title {
    border-bottom: 1px dashed #8f8f8f;
    margin: 0 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 54px;
    flex: 1;
    h1 {
      font-weight: 800;
      font-size: 18px;
    }
  }
  .list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 25px;
  }
`;
export const ProdModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 7px;
  margin: 5px;
  cursor: pointer;
  background: #ffffff;
  img {
    width: 83px;
    height: 55px;
    max-width: 83px;
    max-height: 55px;
  }
  .ref-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .ref {
      font-size: 12px;
      font-weight: bold;
      cursor: pointer;
    }
  }
  :hover {
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.05),
      -2px -2px 6px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
  }
  .hearth {
    position: absolute;
    width: 18px;
    height: 16px;
  }
  .pulse-heart-fav {
    animation: pulse-heart-fav 1s 1;
    @keyframes pulse-heart-fav {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(2);
      }
      100% {
        transform: scale(1);
      }
    }
  }
`;
export const Header = styled.div`
  background: ${colors.primary};
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  img {
    width: 39px;
    height: 34px;
  }
  z-index: 20;
`;
export const List = styled.div`
  border-radius: 4px;
`;
export const Content = styled.div`
  @media (min-width: 1450px) {
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4), -2px -2px 6px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4),
      -2px -2px 6px rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4),
      -2px -2px 6px rgba(0, 0, 0, 0.4);
  }
`;
export const Empty = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  min-height: 392px;
  h1 {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    color: #3e3e3e;
    max-width: 450px;
    @media (max-width: 600px) {
      font-size: 14px;
      margin-left: -20px;
    }
  }
  img {
    max-width: 276px;
    width: 20vw;
  }
`;

export const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    zIndex: 99,
    border: "none",
    borderRadius: "none",
    backgroundColor: "rgba(45, 37, 40, 0.9)",
    padding: 0,
    maxWidth: "380px",
  },
  overlay: {
    zIndex: 99,
    backgroundColor: "rgba(45, 37, 40, 0.9)",
  },
};
