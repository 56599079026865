import React, { useEffect } from "react";
import * as s from "./styled-header.js";
import { useDispatch, useSelector } from "react-redux";
import {
	usuariosActions,
	liveActions,
	subscriptionActions,
} from "../../store/index.js";
import { usePubNub } from "pubnub-react";
import { BurgerMenu } from "../../components/";
import { images } from "../../assets/index.js";
import { withRouter } from "react-router-dom";
// import { INTRO_KEY } from "../../utils/index.js";

const Header = ({ history }) => {
	const dispatch = useDispatch();
	const usuario = useSelector((state) => state.usuario);
	const pubnub = usePubNub();
	const admin = usuario.usuario && usuario.usuario.tipoUsuario === 5;
	const manager = usuario.usuario && usuario.usuario.manager;

	const logout = () => {
		dispatch(usuariosActions.setUsuario());
		dispatch(liveActions.resetState());
		localStorage.removeItem("user");
		localStorage.removeItem("isCnpj");
	};

	useEffect(() => {
		pubnub.addListener({
			presence: function (presenceEvent) {
				dispatch(subscriptionActions.setCurrentUsers(presenceEvent.occupancy));
			},
		});

		pubnub.subscribe({
			channels: ["geral"],
			withPresence: true,
		});

		pubnub.hereNow({ channels: ["geral"] }, (status, response) => {
			if (response)
				dispatch(subscriptionActions.setCurrentUsers(response.totalOccupancy));
		});

		return () => {
			pubnub.unsubscribeAll();
		};
	}, [dispatch, pubnub]);

	return (
		<div
			style={{
				background:
					history.location.pathname === "/lives" ? "#f2f2f2" : "#383838",
			}}>
			<s.Container adm={admin || manager}>
				{admin || manager ? (
					<BurgerMenu logout={logout} />
				) : (
					<img
						alt="logo"
						src={images.logotipo}
						className="logo"
						onClick={() => history.push("/lives")}
					/>
				)}
				{admin || manager ? (
					<img
						alt="logo"
						src={images.logotipo}
						className="logo"
						onClick={() => history.push("/gerenciador")}
					/>
				) : (
					<a onClick={logout} className="animated-linear">
						Sair
					</a>
				)}
			</s.Container>
		</div>
	);
};

export default withRouter(Header);
