import { createTypes } from "reduxsauce";

export const subscribeTypes = createTypes(`SET_SUBSCRIBE`, {
  prefix: "SUBSCRIBE/",
});

const setSubscribe = (data) => {
  return (dispatch) => {
    dispatch({
      type: subscribeTypes.SET_SUBSCRIBE,
      subscribe: data,
    });
  };
};

export const subscribeActions = {
  setSubscribe,
};
