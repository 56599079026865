import { lives, firebase, axiosApp } from "./";
import { store, liveActions } from "../store";

const initProdutos = () => {
  const user = store.getState().usuario.usuario;
  let live = user
    ? user.live
      ? user.live
      : user.lives && user.lives[0]
      ? user.lives[0]
      : null
    : null;
  const db = lives.doc(live.id).collection("produtos");
  db.onSnapshot((snapshot) => {
    let produtos = [];
    snapshot.docs.map((doc, i) =>
      produtos.push({ ...doc.data(), docId: doc.id })
    );
    store.dispatch(liveActions.setProdutos(produtos));
  });
};

const initFavoritos = () => {
  const user = store.getState().usuario.usuario;
  let live = user
    ? user.live
      ? user.live
      : user.lives && user.lives[0]
      ? user.lives[0]
      : null
    : null;
  const db = lives
    .doc(live.id)
    .collection("favoritos")
    .where("codUsuario", "==", user.codUsuario)
    .orderBy("timeAddFavorito");
  db.onSnapshot((snapshot) => {
    let favoritos = [];
    snapshot.docs.map((doc, i) =>
      favoritos.push({ ...doc.data(), docId: doc.id })
    );
    store.dispatch(liveActions.setFavoritos(favoritos));
  });
};

const getFavoritos = () => {
  return new Promise((resolve, reject) => {
    const user = store.getState().usuario.usuario;
    let live = user
      ? user.live
        ? user.live
        : user.lives && user.lives[0]
        ? user.lives[0]
        : null
      : null;
    lives
      .doc(live.id)
      .collection("favoritos")
      .where("codUsuario", "==", user.codUsuario)
      .orderBy("timeAddFavorito")
      .get()
      .then((snapshot) => {
        let favoritos = [];
        snapshot.docs.map((doc, i) =>
          favoritos.push({ ...doc.data(), docId: doc.id })
        );
        resolve(favoritos);
      })
      .catch(() => resolve([]));
  });
};

const initGrades = () => {
  firebase
    .firestore()
    .collection("grades")
    .get()
    .then((resp) => {
      let grades = resp.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      store.dispatch(liveActions.setGrades(grades));
    });
};
const addFavorito = (produto, grade, quantidade, idProduto) => {
  return new Promise((resolve, reject) => {
    const user = store.getState().usuario.usuario;
    let live = user
      ? user.live
        ? user.live
        : user.lives && user.lives[0]
        ? user.lives[0]
        : null
      : null;
    axiosApp
      .post(`/product/addFavoritos/${live.id}`, {
        produto,
        grade,
        quantidade,
        idProduto,
      })
      .then(() => resolve())
      .catch(() => reject());
  });
};
const editFavorito = (produto, grade, quantidade, idProduto) => {
  return new Promise((resolve, reject) => {
    const user = store.getState().usuario.usuario;
    let live = user
      ? user.live
        ? user.live
        : user.lives && user.lives[0]
        ? user.lives[0]
        : null
      : null;
    axiosApp
      .put(`/product/alterarFavoritos/${live.id}/${idProduto}`, {
        produto,
        grade,
        quantidade,
      })
      .then(() => resolve())
      .catch(() => reject());
  });
};
const deleteFavorito = (idProduto) => {
  return new Promise((resolve, reject) => {
    const user = store.getState().usuario.usuario;
    let live = user
      ? user.live
        ? user.live
        : user.lives && user.lives[0]
        ? user.lives[0]
        : null
      : null;
    axiosApp
      .delete(`/product/deletarFavoritos/${live.id}/${idProduto}`)
      .then(() => resolve())
      .catch(() => reject());
  });
};
const fecharPedido = (favoritos, email, pagamento, observacao) => {
  const idsFavoritos = favoritos.map((fav) => ({ id: fav.docId }));
  const { label, value, livroCodigo } = pagamento;
  return new Promise((resolve, reject) => {
    const user = store.getState().usuario.usuario;
    let live = user
      ? user.live
        ? user.live
        : user.lives && user.lives[0]
        ? user.lives[0]
        : null
      : null;
    axiosApp
      .post(`/product/fecharPedido/${live.id}/${email}`, {
        idsFavoritos,
        condicaoCodigo: value,
        condicaoDescricao: label,
        livroCodigo,
        observacao,
      })
      .then(() => resolve())
      .catch(() => reject());
  });
};
const sendEmail = (email, pedido, observacao) => {
  const user = store.getState().usuario.usuario;
  let { id: live } = user
    ? user.live
      ? user.live
      : user.lives && user.lives[0]
      ? user.lives[0]
      : null
    : null;
  const body = { email, pedido, observacao, live };
  return new Promise((resolve, reject) => {
    axiosApp
      .post(`/product/emailPedido`, body)
      .then(() => resolve())
      .catch(() => reject());
  });
};

export const produtosService = {
  initProdutos,
  initGrades,
  initFavoritos,
  addFavorito,
  editFavorito,
  deleteFavorito,
  fecharPedido,
  sendEmail,
  getFavoritos,
};
