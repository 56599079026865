import React, {
	useState,
	useEffect,
	useLayoutEffect,
	useRef,
	useCallback,
	useMemo,
} from "react";
import * as s from "./styled-chat.js";
import { Scrollbars } from "react-custom-scrollbars";
import { useSelector } from "react-redux";
import { Loader } from "../../components";
import { chatService, lives, firebase } from "../../services/index.js";
import { icons } from "../../assets/index.js";

const Chat = () => {
	var headerHeight = 48;
	const inputHeight = 106;
	const Emojis = [
		{ label: "Olá!", value: "Olá!" },
		{ label: icons.EmojiLove, value: "😍" },
		{ label: icons.EmojiHeart, value: "❤️" },
		{ label: icons.EmojiShoe, value: "👠" },
		{ label: icons.EmojiClap, value: "👏" },
	];
	const subscription = useSelector((state) => state.subscription);
	const usuario = useSelector((state) => state.usuario);
	const { usuario: user } = usuario;
	const [chat, setChat] = useState([]);
	const [usersMock, setUsersMock] = useState(0);
	const [size, setSize] = useState({ height: 0, width: 0 });
	const [loading, setLoading] = useState(true);
	const [emojiClicked, setEmojiClicked] = useState();
	const users = subscription.currentUsers + "";
	const [mensagem, setMensagem] = useState("");
	const [autoScroll, setAutoScroll] = useState(true);
	const [chatRef, setChatRef] = useState();
	const [liveFinalizada, setLiveFinalizada] = useState(false);
	const userMockRef = useRef(null);

	const resizeWindow = useCallback(() => {
		let divElement = document.getElementById("container-chat");
		if (divElement) {
			let elemHeight = divElement.offsetHeight;
			let elemWidth = divElement.offsetWidth;
			if (elemWidth < 782 && elemHeight <= 250) {
				setSize({
					height: "146px",
					width: elemWidth + "px",
				});
			} else {
				setSize({
					height: elemHeight - headerHeight - inputHeight + "px",
					width: elemWidth + "px",
				});
			}
		}
	}, [headerHeight]);

	useEffect(() => {
		let live = user
			? user.live
				? user.live
				: user.lives && user.lives[0]
				? user.lives[0]
				: null
			: null;

		if (live && live.sala) {
			lives
				.doc(live.id)
				.collection("chat")
				.doc(live.sala)
				.collection("mensagens")
				.orderBy("timeEnvio", "desc")
				.limit(200)
				.onSnapshot((snapshot) => {
					let chat = [];
					snapshot.docs.map((doc) =>
						chat.unshift({ ...doc.data(), id: doc.id }),
					);
					setChat(chat);
					setLoading(false);
				});
			lives.doc(live.id).onSnapshot((snapshot) => {
				snapshot.data().salas.forEach((sala) => {
					if (sala.regional === live.regional) {
						setLiveFinalizada(!sala.chat);
					}
				});
			});
		} else setLoading(false);
	}, [user]);

	useLayoutEffect(() => {
		window.addEventListener("resize", resizeWindow);
		return () => window.removeEventListener("resize", resizeWindow);
	}, [resizeWindow]);

	useEffect(() => {
		resizeWindow();
		let live = user
			? user.live
				? user.live
				: user.lives && user.lives[0]
				? user.lives[0]
				: null
			: null;
		firebase
			.firestore()
			.collection("mocks")
			.doc(live.id)
			.onSnapshot((snapshot) => {
				if (snapshot.data() && snapshot.data().users) {
					setUsersMock(snapshot.data().users);
				}
			});
	}, [resizeWindow, user]);

	useEffect(() => {
		if (chat && autoScroll && chat.length >= 9 && chatRef)
			chatRef.scrollToBottom();
	}, [chat]);

	useEffect(() => {
		if (chatRef) chatRef.scrollToBottom();
	}, [loading]);

	// const variantUsersMockValue = useCallback(() => {
	// 	const randomInterval = Math.floor(Math.random() * (60000 - 10000)) + 10000;

	// 	if (usersMock && userMockRef.current && users) {
	// 		const rangePercent = usersMock * 0.05;
	// 		const rangeOfUsers =
	// 			Math.floor(
	// 				Math.random() *
	// 					(usersMock + rangePercent - (usersMock - rangePercent)),
	// 			) +
	// 			(usersMock - rangePercent);

	// 		if (usersMock !== 0)
	// 			userMockRef.current.innerHTML = parseInt(users) + rangeOfUsers;

	// 		setTimeout(variantUsersMockValue, randomInterval);
	// 	}
	// }, [usersMock, users]);

	// useEffect(() => {
	// 	if (usersMock > 0) variantUsersMockValue();
	// }, [usersMock, variantUsersMockValue]);

	const handleUpdate = async ({ scrollTop, scrollHeight, clientHeight }) => {
		if (scrollHeight - scrollTop === clientHeight) setAutoScroll(true);
		else if (chat.length >= 10) setAutoScroll(false);
	};

	const send = (e, msgDirect, emoji) => {
		e.preventDefault();
		let msg = mensagem.trim();
		if (msg || emoji) {
			if (emoji) {
				setEmojiClicked(msgDirect);
				setTimeout(() => setEmojiClicked(), 1000);
				msg = msgDirect.trim();
			} else setMensagem("");
			chatService.sendMessage(msg);
		}
	};

	return (
		<s.Container id="container-chat">
			<s.Header h={headerHeight}>
				<div
					className="title"
					style={{ display: "flex", alignItems: "center" }}>
					<s.Status>
						{loading ? <Loader size={10} color="red" /> : <s.On />}
					</s.Status>
					<h1>LIVE CHAT</h1>
				</div>
				<div
					className="viewers"
					style={{ display: "flex", marginLeft: "auto" }}>
					<h2 style={{ marginLeft: "auto" }} ref={userMockRef}>
						{Number(usersMock) + Number(users)}
					</h2>
					<img
						alt="icone-usuarios"
						src={icons.userGray}
						style={{ marginRight: "10px" }}
					/>
				</div>
			</s.Header>
			<Scrollbars
				style={{
					height: size.height,
					width: size.width,
					background: "#282828",
				}}
				ref={(c) => setChatRef(c)}
				onUpdate={handleUpdate}>
				{!loading &&
					chat.map((msg, i) => {
						console.log(msg);
						return typeof msg.mensagem === "string" ? (
							<s.Msg
								key={msg?.id}
								rep={
									msg?.codUsuario.substring(0, 5) === "BRREP" ? true : false
								}>
								<span className="nome">{msg?.nome[0] + msg?.nome[1]}</span>
								<div>
									<h1>{msg?.nome.toLowerCase()}</h1>
									{msg?.mensagem === "❤️" ? (
										<s.Heart>{msg?.mensagem}</s.Heart>
									) : (
										<label>{msg?.mensagem}</label>
									)}
								</div>
							</s.Msg>
						) : null;
					})}
			</Scrollbars>
			{liveFinalizada && (
				<s.NoMessage className="fade-in" width={size.width}>
					Desculpe, não é mais possível enviar mensagens para esta live.
				</s.NoMessage>
			)}
			<form onSubmit={send}>
				<s.EmojiContainer>
					{Emojis.map((src) =>
						src.label === "Olá!" ? (
							<s.EmojiBox
								className={emojiClicked === src.value && `pulse-emoji`}
								disabled={liveFinalizada}
								onClick={(e) => !liveFinalizada && send(e, src.value, true)}>
								{src.label}
							</s.EmojiBox>
						) : (
							<s.EmojiBox
								className={emojiClicked === src.value && `pulse-emoji`}
								disabled={liveFinalizada}
								onClick={(e) => !liveFinalizada && send(e, src.value, true)}>
								<s.Emoji src={src.label} />
							</s.EmojiBox>
						),
					)}
				</s.EmojiContainer>
				<s.FooterChat disabled={loading || liveFinalizada}>
					<s.Comment
						type="text"
						value={mensagem}
						placeholder={"Comentar..."}
						onChange={(e) => !liveFinalizada && setMensagem(e.target.value)}
						disabled={loading || liveFinalizada}
					/>
					<button
						disabled={liveFinalizada}
						onClick={(e) => !loading || (!liveFinalizada && send(e))}>
						<img alt="icone-enviar" src={icons.send} />
					</button>
				</s.FooterChat>
			</form>
		</s.Container>
	);
};

export default Chat;
