import styled from "styled-components";

export const Container = styled.div`
	width: 100%;
	display: ${({ visible }) => (visible ? "flex" : "none")};
	flex-wrap: wrap;
	justify-content: center;
	section {
		margin: 15px 10px;
		width: 100%;
		/* height: 460px; */

		&.reduceMargin {
			margin: 10px;
		}

		border-radius: 4px;
		:first-child {
			width: 460px;
			display: flex;
			flex-direction: column;
		}
		:last-child {
			article {
				margin: 10px;
				display: flex;
				flex-wrap: wrap;
			}
			background: #3f3f3f;
			box-shadow: -10px -10px 30px rgba(0, 0, 0, 0.08),
				10px 10px 30px rgba(0, 0, 0, 0.08);
			max-width: 1000px;
		}
	}
	.bg-white {
		background: white !important;
	}
	.bg-gray {
		background: #f7f7f7 !important;
	}
`;
export const HeaderCard = styled.header`
	position: absolute;
	width: 100%;
	margin-top: -25px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	> div {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 1rem;
	}
`;
export const Title = styled.h1`
	font-weight: 600;
	font-size: 14px;
	color: #989898;
`;
export const Icon = styled.img`
	cursor: ${({ isDisable }) => (isDisable ? "not-allowed" : "pointer")};
	width: 18px;

	animation-name: ${({ isLoading }) => (isLoading ? "rotate" : "none")};
	animation-duration: 2s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;

	@keyframes rotate {
		100% {
			transform: rotate(360deg);
		}
	}
`;

export const ProdContainer = styled.div`
	margin: 15px;
	display: flex;
	background: #eeeeee;
	border-radius: 4px;
	:hover {
		.absolute {
			img {
				display: flex;
				cursor: pointer;
			}
		}
	}
	input[type="time"]::-webkit-calendar-picker-indicator {
		/* background: transparent; */
		position: absolute;
		display: none;
	}

	.middle {
		width: 100%;
		max-width: 220px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		.img-container {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			min-height: 80px;
			height: 80px;
			border-radius: 4px;
			margin-top: 10px;
			img {
				height: 100%;
			}
		}
		button {
			width: 100%;
		}
		div {
			margin: 0;
		}
	}
`;
export const Produto = styled.div`
	display: flex;
	width: 60%;
	height: 220px;
	margin: 10px;
	background: #ffffff;
	border-radius: 4px;

	.absolute {
		width: 555px;
		position: absolute;
		display: flex;
		justify-content: space-between;
		legend {
			width: 75px;
			height: 40px;
			background: #75b6cb;
			border-radius: 4px 0px;
			font-weight: 800;
			font-size: 16px;
			display: flex;
			align-items: center;
			justify-content: center;
			text-transform: uppercase;
			color: #ffffff;
		}
		img {
			display: none;
		}
	}
	div {
		margin: 0 auto 10px auto;
	}
	.first {
		width: 100%;
		max-width: 100px;
		margin-top: 40px;
		margin-top: auto;
		label {
			font-weight: 600;
			font-size: 14px;
			color: #989898;
		}
		span {
			width: 65px;
			height: 30px;
			border: 0.1rem solid #e9505e;
			box-sizing: border-box;
			border-radius: 4px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 5px 25px;

			background: ${(p) =>
				p.timerError ? `rgba(233, 80, 94, 0.1)` : `transparent`};

			input {
				color: #e9505e;
				font-weight: bold;
				font-size: 14px;
				width: 40px;
				background: transparent;
			}
			img {
				cursor: pointer;
				width: 15px;
				position: absolute;
				margin-right: 100px;
			}
		}
	}
	.last {
		width: 100%;
		max-width: 120px;
		display: flex;
		flex-direction: column;

		justify-content: space-between;
		align-items: center;
		.card {
			width: 85px;
			height: 85px;
			margin-bottom: 25px;
			box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
			border-radius: 4px;
		}
		div {
			margin-top: auto;
			display: flex;
			font-weight: 600;
			font-size: 14px;
			color: #989898;
		}
	}
`;
export const Variacoes = styled.div`
	width: 40%;
	height: 210px;
	margin: 10px 10px 10px 0;
	background: #ffffff;
	border-radius: 4px;
	display: flex;
	justify-content: center;
	padding-bottom: 10px;
	.deletar-variacao {
		position: absolute;
		margin: 10px 0 0 335px;
		display: none;
	}
	:hover {
		.deletar-variacao {
			display: flex;
			cursor: pointer;
		}
	}
`;
export const Button = styled.button`
	height: 40px;
	border-radius: 4px;
	border: 2px solid #d0d0d0;
	font-weight: 600;
	font-size: 14px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;
	color: #c5c5c5;
	cursor: pointer;
	background: white;
	outline: none;
	:hover {
		background: #e9505e;
		color: white;
		border: 2px solid #e9505e;
	}
	:disabled {
		background: white;
		color: #c5c5c5;
		border: 2px solid #d0d0d0;
		cursor: not-allowed;
	}
`;
export const Header = styled.span`
	width: 350px;
	height: 50px;
	background: #e9505e;
	border-radius: 4px 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 18px;
	color: #ffffff;
`;
export const Valores = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	label {
		font-weight: 600;
		font-size: 14px;
		color: #989898;
	}
	input {
		color: #e9505e;
		font-weight: bold;
		font-size: 14px;
		width: 105px;
		margin-left: 15px;
		border: none;
		text-align: center;
		background: transparent;
		::placeholder {
			color: #e9505e;
		}
	}
	span {
		width: 65px;
		height: 30px;
		border: 0.1rem solid #e9505e;
		box-sizing: border-box;
		border-radius: 4px;
		font-weight: bold;
		font-size: 14px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #e9505e;

		margin: 5px 0 5px 25px;
		img {
			width: 15px;
			position: absolute;
			margin-right: 100px;
		}
		label {
			position: absolute;
			margin-right: 90px;
		}
	}
`;
export const ModalContent = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin: 20px;
	.bg-gray {
		background: #f7f7f7 !important;
	}
	label {
		font-weight: 600;
		font-size: 14px;
		color: #434343;
	}
	textarea {
		background: #f7f7f7;
		border-radius: 4px;
		width: 260px;
		height: 70px;
		padding: 10px;
	}
	section {
		margin: 20px;
		display: flex;
		flex-direction: column;
	}
	.img-container-produto {
		width: 260px;
		height: 160px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 5px;
		img {
			height: 100%;
			margin: 10px;
		}
	}
`;
export const Grades = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: flex-start;
	border-radius: 4px;
	padding: 10px;
	min-height: 150px;
	span {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 110px;
		margin: 5px;
		height: 35px;
		background: #e9505e;
		border-radius: 4px;
		font-weight: 600;
		font-size: 14px;
		color: #ffffff;
		padding-left: 10px;
		a {
			padding: 0 10px 3px 10px;
			cursor: pointer;
		}
	}
`;
export const Footer = styled.div`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	section {
		:last-child {
			width: 415px;
		}
	}
`;
export const Arrows = styled.div`
	width: 300px;
	margin-top: 33px;
	position: absolute;
	display: flex;
	justify-content: space-between;
	img {
		cursor: pointer;
		padding: 10px;
		width: 15px;
		height: 15px;
		border-radius: 100px;
		:hover {
			background: #f7f7f7;
		}
	}
	div {
		position: absolute;
		width: 300px;
		display: flex;
		justify-content: center;
		background: transparent;
		margin-top: 50px;
		* {
			border-radius: 30px;
			width: 5px;
			height: 5px;
			margin: 0 3px;
			border: 2px solid #e74e5c;
		}
		span {
			background: transparent;
		}
		a {
			background: #e94e5c;
		}
	}
`;
export const InputContainer = styled.form`
	display: flex;
	input {
		width: 240px;
		height: 50px;
		padding: 0 10px;
		background: #f7f7f7;
		border-radius: 4px;
	}
	button {
		border: none;
		outline: none;
		cursor: pointer;
		height: 50px;
		width: 50px;
		background: #e94e5c;
		border-radius: 0px 5px 5px 0px;
		display: flex;
		align-items: center;
		justify-content: center;
		img {
			height: 20px;
			width: 20px;
		}
		:disabled {
			opacity: 0.8;
			cursor: not-allowed;
		}
	}
`;
export const Drop = styled.div`
	width: 308px;
	max-height: 200px;
	height: ${({ elements }) => elements * 35}px;
	position: absolute;
	box-shadow: -1px -1px 3px rgba(0, 0, 0, 0.1), 1px 1px 3px rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	margin: 53px 0 0 1px;
	background: white;
	.item {
		cursor: pointer;
		font-size: 16px;
		line-height: 25px;
		padding: 5px 10px;
		color: gray;
		:hover {
			background: #f4f4f4;
		}
	}
	legend {
		color: gray;
		text-align: center;
		margin-top: 20px;
	}
`;
export const PlayerContainer = styled.div`
	width: 100%;
	background: #3f3f3f;
	box-shadow: -10px -10px 30px rgba(0, 0, 0, 0.08),
		10px 10px 30px rgba(0, 0, 0, 0.08);
	border-radius: 4px;
	video {
		max-width: 100%;
		border-radius: 4px;
	}
`;

export const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		zIndex: 99,
		border: "none",
		borderRadius: "5px",
		backgroundColor: "white",
		padding: 0,
		maxWidth: "1010px",
	},
	overlay: {
		zIndex: 99,
		backgroundColor: "rgba(45, 37, 40, 0.9)",
	},
};
