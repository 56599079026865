import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: ${({ visible }) => (visible ? "flex" : "none")};
  justify-content: center;
  flex-wrap: wrap;
  section {
    display: flex;
    flex-direction: column;
    margin: 10px;
    width: 330px;
    height: 300px;
    background: #ffffff;
    box-shadow: -1px -1px 3px rgba(0, 0, 0, 0.1), 1px 1px 3px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    label {
      font-weight: 600;
      font-size: 14px;
      color: #3e3e3e;
      margin: 0 0 5px 22px;
    }
    .header-card-sala {
      display: flex;
      align-items: center;
      margin: 20px 22px 15px 22px;
    }
    .sala {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 90px;
      height: 34px;
      align-self: flex-start;
      border: 1px solid #e94e5c;
      border-radius: 4px;
      font-weight: 800;
      font-size: 12px;
      text-transform: uppercase;
      color: #e94e5c;
    }
    .duplicar {
      margin: 0 auto 0 10px;
      opacity: 0.8;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 115px;
      height: 36px;
      background: #75b6cb;
      border-radius: 4px;
      font-weight: 800;
      font-size: 10px;
      text-transform: uppercase;
      color: #ffffff;
      :hover {
        opacity: 1;
      }
    }
    .trash {
      cursor: pointer;
      width: 30px;
      height: 30px;
    }
  }
`;
export const Title = styled.h1`
  font-weight: 600;
  font-size: 14px;
  color: #989898;
  position: absolute;
  margin-top: -25px;
`;

export const Chat = styled.div`
  margin: 20px 0 0 22px;
  font-weight: 600;
  font-size: 14px;
  display: flex;
  color: ${({ active }) => (active ? "#383838" : "#989898")};
`;

export const ModalContent = styled.form`
  background: #ffffff;
  box-shadow: -2px -2px 6px rgba(0, 0, 0, 0.05), 2px 2px 6px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 20px;

  h1 {
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    padding: 0 0 20px;
    max-width: 210px;
    margin: auto;
  }
  section {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    button {
      width: 140px;
      margin: 0 10px;
    }
  }
`;

export const selectStyles = {
  container: (provided) => ({
    ...provided,
    width: 285,
    margin: "0 auto",
    fontFamily: "Nunito",
    borderRadius: 4,
    boxShadow:
      "1px 1px 3px rgba(0, 0, 0, 0.1), -1px -1px 3px rgba(0, 0, 0, 0.1)",
    color: "#989898",
  }),
  control: (provided) => ({
    ...provided,
    minHeight: 34,
    fontWeight: 500,
    border: "none",
    boxShadow: "none",
    backgroundColor: "white",
    color: "#989898",
  }),
  placeholder: (provided) => ({
    ...provided,
    whiteSpace: "nowrap",
    maxWidth: 285,
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: "#989898",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
  option: (provided, state) => ({
    ...provided,
    minHeight: 34,
    color: state.isFocused ? "#383838" : "#989898",
    fontWeight: 500,
    display: "flex",
    alignItems: "center",
  }),
  singleValue: (provided) => ({
    ...provided,
    fontWeight: 500,
    color: "#383838",
    fontSize: 16,
  }),
};

export const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    zIndex: 99,
    border: "none",
    borderRadius: "none",
    backgroundColor: "rgba(45, 37, 40, 0.9)",
    padding: 0,
    maxWidth: "380px",
  },
  overlay: {
    zIndex: 99,
    backgroundColor: "rgba(45, 37, 40, 0.9)",
  },
};
