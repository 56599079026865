import styled from "styled-components";

export const Container = styled.div`
  margin: 20px 20px 20px 0px;
  max-width: 400px;
  min-width: 400px;
  height: 680px;
  border-radius: 4px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4), -2px -2px 6px rgba(0, 0, 0, 0.4);
  @media (max-width: 1700px) {
    height: 40vw;
  }
  @media (max-width: 1010px) {
    width: 100%;
    max-width: 1000px;
    height: 50vh;
    min-height: 200px;
    min-width: 250px;
    margin: 0;
    border: none;
  }
  @media (max-width: 800px) {
    height: 250px;
  }
  form {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: space-evenly;
  }
`;
export const Header = styled.div`
  width: 100%;
  border-radius: 4px 4px 0px 0px;
  height: ${(props) => props.h}px;
  display: flex;
  align-items: center;
  background: #383838;
  -moz-box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
  @media (max-width: 800px) {
    display: block;
    width: 130px;
    height: 60px;
    float: right;
    background: #282828;
    z-index: 2;
    box-shadow: none;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    .title {
      display: flex;
      h1 {
        font-size: 14px;
      }
    }
    .viewers {
      margin-left: 8px !important;
      h2 {
        font-size: 15px;
        margin-left: 10px;
      }
    }
  }
  h1 {
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    color: #989898;
  }
  h2 {
    font-family: Nunito;
    font-weight: normal;
    font-size: 20px;
    color: #989898;
    margin-right: 11px;
  }
`;
export const Status = styled.div`
  margin: 0 10px;
  @media (max-width: 1010px) {
    margin: 8px 10px;
  }
`;
export const On = styled.div`
  width: 10px;
  height: 10px;
  background: #e94e5c;
  border-radius: 100px;
`;
export const Msg = styled.div`
  flex: 1;
  display: flex;
  padding: 5px 20px;
  /* border-bottom: 1px solid #f3f3f3; */
  .nome {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 30px;
    min-height: 30px;
    max-width: 30px;
    max-height: 30px;
    border-radius: 100px;
    background: ${(props) => (props.rep ? "#EDCFCB" : "#CBE3ED")};
    font-weight: bold;
    font-size: 12px;
    color: ${(props) => (props.rep ? "#863C3C" : "#3C5A86")};
  }
  div {
    flex: 1;
    margin-left: 5px;
    align-items: center;
    h1 {
      color: ${(props) => (props.rep ? "#E94E5C" : "#75B6CB")};
      font-size: 11px;
      margin-right: 10px;
      text-transform: capitalize;
    }
    label {
      font-size: 11px;
      font-weight: normal;
      color: #f4f4f4;
    }
  }
`;

export const Heart = styled.span`
  display: flex;
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  max-height: 30px;
  animation: pulse 1s infinite;
  @keyframes pulse {
    0% {
      transform: scale(0.75);
    }
    20% {
      transform: scale(1);
    }
    40% {
      transform: scale(0.75);
    }
    60% {
      transform: scale(1);
    }
    80% {
      transform: scale(0.75);
    }
    100% {
      transform: scale(0.75);
    }
  }
`;

export const FooterChat = styled.div`
  width: calc(100% - 20px);
  margin: 0 10px;
  display: flex;
  border-bottom: 1px solid #989898;
  button {
    height: 100%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    outline: 0;
    border: 0;
    margin: auto;
    cursor: pointer;
    :disabled {
      cursor: not-allowed;
    }
  }
`;
export const Comment = styled.input`
  width: calc(100% - 25px);
  height: ${(props) => props.h || "38"}px;
  background: #383838;
  margin-left: 15px;
  color: #989898;
  font-size: 14px;
  margin-right: 10px;
  :disabled {
    cursor: not-allowed;
  }
`;

export const EmojiContainer = styled.div`
  width: calc(100% - 10px);
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 8px 5px;
  .pulse-emoji {
    animation: shadow-pulse 1s 1;
  }
  @keyframes shadow-pulse {
    0% {
      box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
    }
    100% {
      box-shadow: 0 0 0 35px rgba(0, 0, 0, 0);
    }
  }
`;

export const EmojiBox = styled.div`
  display: flex;
  width: 49px;
  height: 33px;
  background: #282828;
  border-radius: 20px;
  color: #fff;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  z-index: 2;
`;

export const Emoji = styled.img`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
`;

export const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0px;
`;

export const NoMessage = styled.label`
  z-index: 3;
  border: 2px solid #fff;
  margin: -70px 10px 10px 10px;
  width: ${(props) => props.width && `calc(${props.width} - 50px)`};
  padding: 10px;
  color: #000;
  background: #fff;
  font-size: 14px;
  font-weight: bold;
  border-radius: 4px;
  position: absolute;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4), -2px -2px 6px rgba(0, 0, 0, 0.4);
  z-index: 3;
`;
