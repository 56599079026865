import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as s from "./styled-timer";
import { images } from "../../assets";
import { liveActions } from "../../store";
import { firestore } from "../../services";

const Timer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { usuario } = useSelector((state) => state.usuario);
  const { started } = useSelector((state) => state.live);
  const live = useSelector((state) => state.live);
  const [data, setData] = useState();
  const [testUsers, setTestUsers] = useState([]);

  useEffect(() => {
    if (started) history.push("/");
  }, [started]);

  useEffect(() => {
    var x = setInterval(function () {
      if (testUsers.includes(usuario.codUsuario)) {
        dispatch(liveActions.setStarted());
        clearInterval(x);
      }
      var now = new Date().getTime();
      var countDownDate = new Date(usuario.live.dataInicio).getTime();
      if (countDownDate <= now) {
        dispatch(liveActions.setStarted());
        clearInterval(x);
      } else {
        var distance = countDownDate - now;
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);
        setData(days + "d " + hours + "h " + minutes + "m " + seconds + "s ");
      }
    }, 1000);
  }, [usuario, dispatch, testUsers]);

  useEffect(() => {
    const myLive = usuario
      ? usuario.live
        ? usuario.live
        : usuario.lives
        ? usuario.lives[0]
        : null
      : null;
    if (myLive && myLive.id)
      firestore
        .collection("mocks")
        .doc(myLive.id)
        .onSnapshot((snapshot) => {
          if (snapshot.data()) {
            if (snapshot.data().testUsers)
              setTestUsers([...snapshot.data().testUsers]);
          }
        });
  }, [usuario]);

  return (
    <s.CountdownContainer>
      <s.LogoContainer>
        <s.Image src={images.logo} />
      </s.LogoContainer>
      <s.CountDown>
        <s.TimeBox>
          <label className="starts">Começará em:</label>
          <label className="countdown">{data}</label>
        </s.TimeBox>
      </s.CountDown>
    </s.CountdownContainer>
  );
};

export default Timer;
