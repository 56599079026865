import React from "react";
import * as s from "./styled-button";
import { Loader } from "..";
const Button = (props) => {
	const {
		disabled,
		type,
		onClick,
		label,
		isLoading = false,
		style,
		green,
		outline,
		...rest
	} = props;

	if (outline)
		return (
			<s.ButtonOutline
				{...rest}
				type={type || "submit"}
				onClick={onClick}
				disabled={isLoading || disabled}
				className="animated-linear"
				style={style}>
				{isLoading ? <Loader size={20} /> : label || "Label"}
			</s.ButtonOutline>
		);
	return (
		<s.Button
			{...rest}
			green={green ? true : false}
			type={type || "submit"}
			onClick={onClick}
			disabled={isLoading || disabled}
			className="animated-linear"
			style={style}>
			{isLoading ? <Loader size={20} /> : label || "Label"}
		</s.Button>
	);
};
export default Button;
