import { subscriptionTypes } from "../actions";

const INITIAL_STATE = {
	subscribed: false,
	pubnub: null,
	currentUsers: 0,
};

export function subscription(state = INITIAL_STATE, action) {
	if (action.type === subscriptionTypes.INITIALIZE) {
		return { ...state, pubnub: action.pubnub };
	} else if (action.type === subscriptionTypes.RESET) {
		return { ...state, pubnub: null };
	} else if (action.type === subscriptionTypes.SUBSCRIBE) {
		return { ...state, subscribed: true };
	} else if (action.type === subscriptionTypes.UNSUBSCRIBE) {
		return { ...state, subscribed: false };
	} else if (action.type === subscriptionTypes.SET_CURRENT_USERS) {
		return { ...state, currentUsers: action.currentUsers };
	} else {
		return state;
	}
}
