import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: ${({ visible }) => (visible ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h1 {
    margin: 20px 0;
    color: #989898;
  }
`;
