import React, { useState, useEffect } from "react";
import * as s from "./styled-realtime-tab";
import { Scrollbars } from "react-custom-scrollbars";
import { lives } from "../../../../services";
import { Loader } from "../../../../components";
import { icons, images } from "../../../../assets";

const RealtimeTab = ({ liveId, visible, next }) => {
	const [loading, setLoading] = useState(true);
	const [produtos, setProdutos] = useState([]);
	const [oldProdutos, setOldProdutos] = useState([]);
	const [p1, setP1] = useState();
	const [p2, setP2] = useState();
	const [prod, setProd] = useState(false);
	const [search, setSearch] = useState("");
	const [changing, setChanging] = useState();
	const db = lives.doc(liveId);

	useEffect(() => {
		db.collection("produtos").onSnapshot((snapshot) => {
			let prods = [];
			snapshot.docs.map((doc) => prods.push({ ...doc.data(), docId: doc.id }));

			const filteredProds = prods.filter(
				(prod) => prod.IMAGEM_VIDEO_LIVE && !prod.SEM_CARD,
			);
			// console.log('file', filteredProds);
			// console.log(prods);

			setProdutos(filteredProds.sort((a, b) => a.ID - b.ID));
			setOldProdutos(filteredProds);
			setLoading(false);
		});
		db.collection("realtime").onSnapshot((snapshot) => {
			let prods = [];
			snapshot.docs.map((doc) =>
				prods.push({ ...doc.data(), position: doc.id }),
			);
			setP1(prods.filter((p) => p.position === "1")[0]);
			setP2(prods.filter((p) => p.position === "2")[0]);
		});
	}, [liveId]);

	const setRealTime = (isP1) => {
		db.collection("realtime")
			.doc(isP1 ? "1" : "2")
			.set(prod)
			.then(() => setProd())
			.catch(() => alert("Ocorreu um erro ao tentar subir esse produto!"));
	};
	const removeRealTime = (isP1) => {
		db.collection("realtime")
			.doc(isP1 ? "1" : "2")
			.delete()
			.catch(() => alert("Ocorreu um erro ao tentar remover esse produto!"));
	};
	const handleSearch = (text) => {
		if (text) {
			let results;
			let t = text.toLowerCase();
			if (t.includes("combo")) results = produtos.filter((p) => p.OWNER);
			else
				results = produtos.filter(
					(p) =>
						(p.LINHA && p.LINHA == t) ||
						(p.REFERENCIA && p.REFERENCIA == t) ||
						(p.CABEDAL && p.CABEDAL == t) ||
						(p.COR && p.COR == t) ||
						(p.DESCRICAO_PRODUTO &&
							p.DESCRICAO_PRODUTO.toLowerCase().includes(t)) ||
						(p.LINHA &&
							p.REFERENCIA &&
							p.CABEDAL &&
							p.COR &&
							`${p.LINHA}-${p.REFERENCIA}-${p.CABEDAL}-${p.COR}`.includes(t)),
				);
			setProdutos(results);
		} else setProdutos(oldProdutos);
	};

	if (loading && visible)
		return (
			<div
				style={{
					display: "flex",
					flex: 1,
					justifyContent: "center",
					height: "100%",
					alignItems: "center",
				}}>
				<Loader />
			</div>
		);

	return (
		<s.Container visible={visible} className="fade-in">
			<section>
				<s.Title>Seus produtos e combos</s.Title>
				<Scrollbars
					style={{
						height: "460px",
					}}>
					<article>
						{produtos &&
							produtos.length > 0 &&
							produtos.map((c, i) => {
								if (
									(c.PRINCIPAL === "S" && !c.COMBO) ||
									c.OWNER ||
									c.SEM_CARD === true
								)
									return (
										<s.Card
											key={`card-${i}`}
											onClick={() => setProd(prod === c ? false : c)}>
											<img
												alt={c.DESCRICAO_PRODUTO}
												src={c.IMAGEM_VIDEO_LIVE}
											/>
											<span>
												{c.OWNER
													? `Combo ${c.COMBO_ID}`
													: `${c.LINHA}-${c.REFERENCIA}-${c.CABEDAL}-${c.COR}`}
											</span>
										</s.Card>
									);
							})}
					</article>
				</Scrollbars>
				<s.Search
					placeholder="Pesquise para filtrar"
					value={search}
					onChange={({ target: t }) => {
						if (changing) clearInterval(changing);
						setChanging(setTimeout(() => handleSearch(t.value), 2000));
						setSearch(t.value);
					}}
				/>
				<s.SearchIcon src={icons.search} alt="Pesquisar" />
			</section>
			<section>
				<s.Title>Cards ao vivo</s.Title>
				<s.FirstCard>
					{prod ? (
						<>
							<img alt="prod-selecionado" src={prod.IMAGEM_VIDEO_LIVE} />
							<label>{prod.DESCRICAO_PRODUTO}</label>
						</>
					) : (
						<h2>Selecione um produto para ele apareceu aqui!</h2>
					)}
				</s.FirstCard>
				<s.LiveCards>
					<article>
						<s.BlueButton onClick={() => setRealTime(true)} disabled={!prod}>
							{p1 ? "Trocar" : "Colocar"}
						</s.BlueButton>
						<img
							alt="prod-selecionado"
							src={p1?.IMAGEM_VIDEO_LIVE || images.default}
							style={{
								width: "120px",
								height: "115px",
								margin: "20px 0",
							}}
						/>
						<s.RedButton onClick={() => removeRealTime(true)} disabled={!p1}>
							Remover
						</s.RedButton>
					</article>
					<article>
						<s.BlueButton onClick={() => setRealTime(false)} disabled={!prod}>
							{p2 ? "Trocar" : "Colocar"}
						</s.BlueButton>
						<img
							alt="prod-selecionado"
							src={p2?.IMAGEM_VIDEO_LIVE || images.default}
							style={{
								width: "120px",
								height: "115px",
								margin: "20px 0",
							}}
						/>
						<s.RedButton onClick={() => removeRealTime(false)} disabled={!p2}>
							Remover
						</s.RedButton>
					</article>
				</s.LiveCards>
			</section>
		</s.Container>
	);
};

export default RealtimeTab;
