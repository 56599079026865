import styled from "styled-components";
import { colors } from "../../assets";

export const Container = styled.div`
	background: ${(props) => (props.white ? "white" : "#F4F4F4")};
	${(props) => props.last && `border-radius: 0px 0px 4px 4px;`}
	.react-multi-carousel-dot-list {
		bottom: 5px;
		z-index: 1;
	}
	.react-select__menu {
		max-height: 90px;
	}
	.react-select__menu-list {
		max-height: 90px;
	}
`;
export const ProdContainer = styled.div`
	width: calc(100% - 40px);
	display: flex;
	justify-content: flex-end;
	padding-right: 40px;
	${(props) => props.last && `border-radius: 0px 0px 4px 4px;`}
	@media (max-width: 800px) {
		padding-right: 0px;
		padding-left: 20px;
	}
	@media (max-width: 1100px) {
		padding-left: 20px;
		padding-right: 20px;
	}
	background: ${(props) => (props.white ? "white" : "#F4F4F4")};
	.trash-delete {
		width: 30px;
		height: 30px;
		position: absolute;
		margin-top: 20px;
		cursor: pointer;
	}
	.BrainhubCarouselItem {
		min-width: 0px;
	}
	.BrainhubCarouselItem--active {
		min-width: 0px;
	}
`;
export const Produto = styled.div`
	min-width: 240px;
	flex: 1;
	height: 146px;
	@media (max-width: 800px) {
		height: 170px;
		padding-right: 5px;
		justify-content: center;
	}
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	padding: 20px 15px 30px 0px;
	margin-right: 20px;
	border-radius: 4px;
	img {
		background: white;
		padding: 20px 10px;
		width: 100px;
		height: 75px;
		box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.05),
			-2px -2px 6px rgba(0, 0, 0, 0.05);
		border-radius: 4px;
	}
	.precos {
		flex: 1;
		display: ${(props) => (props.ocultaPreco ? "none" : "flex")};
		@media (max-width: 800px) {
			flex-direction: column;
			flex: 0;
			height: calc(100% - 45px);
			margin-left: 10px;
			margin-top: 45px;
		}
		justify-content: space-between;
		label {
			font-size: 10px;
			strong {
				margin-left: 2px;
				font-weight: bold;
				font-size: 14px;
			}
		}
	}
`;
export const Infos = styled.div`
	@media (max-width: 800px) {
		height: 77px;
		min-width: 100px;
	}
	height: 100%;
	max-width: 200px;
	min-width: 180px;
	display: flex;
	flex-direction: column;
	align-items: space-between;
	margin: 0 20px;
	.title {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2; /* number of lines to show */
		-webkit-box-orient: vertical;
		${(props) => props.ocultaPreco && "margin: 30px 0 0 18px"};
		flex: 1;
		font-size: 13px;
		@media (max-width: 800px) {
			font-size: 11px;
		}
	}
`;
export const Grades = styled.div`
	height: 100%;
	max-width: 160px;
	display: flex;
	flex-direction: column;
	@media (max-width: 800px) {
		flex-direction: row;
		max-width: 500px;
		justify-content: space-between;
		margin-top: 10px;
	}
	align-items: space-between;
	.opt {
		.label-grade {
			font-weight: 600;
			font-size: 14px;
			margin-bottom: 6px;
		}
	}
`;
export const Pares = styled.div`
	height: 34px;
	width: 160px;
	@media (max-width: 800px) {
		width: 110px;
	}
	display: flex;
	align-items: space-between;
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1), -1px -1px 3px rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	font-weight: 600;
	background: white;
	* {
		font-size: 15px;
		color: #787878;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		border-radius: 4px;
	}
	label {
		flex: 1;
	}
	button {
		border: none;
		outline: 0;
		width: 35px;
		cursor: pointer;
		font-size: 18px;
		background: white;
		:hover {
			background: ${colors.primary};
			color: white;
		}
	}
`;
export const Tamanhos = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: space-between;
	@media (max-width: 1100px) {
		margin: 0;
	}
	.opt {
		.label-tamanho {
			font-weight: 600;
			font-size: 14px;
		}
		.tamanhos-container {
			height: 33px;
			display: flex;
			align-items: center;
		}
	}
`;
export const Valor = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: space-between;
	max-width: 200px;
	.opt {
		.label-valor,
		.label-par {
			font-weight: 600;
			font-size: 14px;
		}
		.valores-container,
		.pares-container {
			height: 33px;
			display: flex;
			align-items: center;
			color: #767676;
			font-size: 14px;
		}
		.valores-container,
		.label-valor {
			${(props) => props.ocultaPreco && "display: none"}
		}
	}
	.opt2 {
		min-height: 75px;
		margin-bottom: auto;
		display: flex;
		align-items: center;
		font-weight: normal;
		font-size: 12px;
		color: #767676;
		input {
			margin-right: 5px;
		}
	}
`;
export const Opt = styled.div`
	min-width: 22px;
	padding: 1px;
	height: 22px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #ffffff;
	border: 1px solid #b3b3b3;
	box-sizing: border-box;
	box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.07);
	border-radius: 3px;
	font-size: 12px;
	color: #767676;
	margin-right: 8px;
`;
export const Arrow = styled.img`
	${(props) => (props.left ? `margin-left: 10px;` : `margin-right: 10px;`)}
	height: 45px;
	width: 26px;
`;
export const Dot = styled.img``;
export const FalseSelect = styled.button`
	cursor: pointer;
	@media (max-width: 800px) {
		width: 100px;
		font-size: 14px;
		justify-content: center;
		padding: 0;
	}
	width: 160px;
	height: 36px;
	border: none;
	outline: 0;
	background: white;
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1), -1px -1px 3px rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-left: 9px;
	color: #989898;
	font-size: 16px;
	img {
		transform: rotate(90deg);
		width: 15px;
		height: 15px;
		box-shadow: none;
		padding: none;
	}
`;

export const customStyles = {
	container: (provided, state) => ({
		...provided,
		width: 292,
		fontFamily: "Nunito",
		borderRadius: 4,
		boxShadow:
			"1px 1px 3px rgba(0, 0, 0, 0.1), -1px -1px 3px rgba(0, 0, 0, 0.1)",
		color: "#989898",
	}),
	control: (provided, state) => ({
		...provided,
		minHeight: 34,
		border: "none",
		boxShadow: "none",
		backgroundColor: "white",
		color: "#989898",
	}),
	placeholder: (provided, state) => ({
		...provided,
		whiteSpace: "nowrap",
		maxWidth: 292,
		overflow: "hidden",
		textOverflow: "ellipsis",
		color: "#989898",
	}),
	dropdownIndicator: (provided, state) => ({
		...provided,
	}),
	indicatorSeparator: (provided, state) => ({
		...provided,
		display: "none",
	}),
	option: (provided, state) => ({
		...provided,
		color: "#989898",
		minHeight: 34,
		display: "flex",
		alignItems: "center",
	}),
	singleValue: (provided, state) => ({
		...provided,
		color: "#989898",
	}),
};

export const modalStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		zIndex: 99,
		border: "none",
		borderRadius: "none",
		backgroundColor: "rgba(45, 37, 40, 0.9)",
		padding: 0,
	},
	overlay: {
		zIndex: 99,
		backgroundColor: "rgba(45, 37, 40, 0.9)",
	},
};
