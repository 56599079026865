import styled from "styled-components";
//--------------------------------------------------------
//-----------------------combos-tab-----------------------
//--------------------------------------------------------

export const Title = styled.h1`
	font-weight: 600;
	font-size: 14px;
	color: #989898;
	position: absolute;
	margin-top: -25px;
`;
export const Container = styled.div`
	width: 100%;
	display: ${({ visible }) => (visible ? "flex" : "none")};
	flex-wrap: wrap;
	justify-content: center;
	section {
		margin: 15px 10px;
		width: 100%;
		border-radius: 4px;

		&.reduceMargin {
			margin: 10px;
		}

		:first-child {
			width: 460px;
			display: flex;
			flex-direction: column;
		}
		:last-child {
			article {
				margin: 10px;
				display: flex;
				flex-wrap: wrap;
			}
			background: #3f3f3f;
			box-shadow: -10px -10px 30px rgba(0, 0, 0, 0.08),
				10px 10px 30px rgba(0, 0, 0, 0.08);
			max-width: 1000px;
		}
	}
	.bg-white {
		background: white !important;
	}
	.bg-gray {
		background: #f7f7f7 !important;
	}
`;
export const ComboContainer = styled.div`
	margin: 15px;
	display: flex;
	background: #eeeeee;
	border-radius: 4px;
	:hover {
		img {
			display: flex;
			cursor: pointer;
		}
	}
`;
export const Combo = styled.div`
	display: flex;
	width: 100%;
	height: 230px;
	margin: 10px;
	background: #ffffff;
	border-radius: 4px;
`;
export const Legend = styled.div`
	position: absolute;
	width: 75px;
	height: 60px;
	background: #75b6cb;
	border-radius: 4px 0px;
	font-weight: 800;
	font-size: 16px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;
	color: #ffffff;
`;
export const Del = styled.img`
	position: absolute;
	display: none;
	right: 0px;
	margin-right: 20px;
	margin-top: -5px;
`;
export const TimeContainer = styled.div`
	width: 100%;
	max-width: 100px;
	margin: auto 15px 20px 15px;

	label {
		font-weight: 600;
		font-size: 14px;
		color: #989898;
	}
	span {
		width: 65px;
		height: 30px;
		border: 0.1rem solid #e9505e;
		box-sizing: border-box;
		border-radius: 4px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 5px 25px;

		background: ${(p) =>
			p.timerError ? `rgba(233, 80, 94, 0.1)` : `transparent`};

		input {
			width: 40px;
			color: #e9505e;
			font-weight: bold;
			font-size: 14px;
			background: transparent;
		}
		img {
			width: 15px;
			position: absolute;
			margin-right: 100px;
		}
	}
`;
export const CardContainer = styled.div`
	min-width: 160px;
	margin: 20px 15px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	.card {
		width: 100%;
		height: 130px;
		border-radius: 4px;
		img {
			width: 160px;
			height: 130px;
			border-radius: 5px;
		}
	}
`;
export const ProdsContainer = styled.div`
	width: 100%;
	margin: 20px 15px;
`;
export const Prod = styled.div`
	display: flex;
	flex-direction: column;
	:first-child {
		margin-right: 30px;
	}
	:last-child {
		margin-left: 30px;
	}
	.card-container {
		height: 75px;
		border-radius: 4px;
		display: flex;
		img {
			max-width: 180px;
			height: 75px;
			margin: 0 auto;
		}
	}
`;
export const BlueButton = styled.button`
	width: 100%;
	height: 30px;
	background: #75b6cb;
	border-radius: 4px;
	font-weight: 600;
	font-size: 14px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;
	color: white;
	outline: none;
	opacity: 0.8;
	border: none;
	:hover {
		:disabled {
			opacity: 0.6;
		}
		opacity: 1;
	}
	:disabled {
		opacity: 0.6;
		color: white;
		cursor: not-allowed;
	}
`;
//--------------------------------------------------------
//-----------------------combos-modal-----------------------
//--------------------------------------------------------
export const Button = styled.button`
	height: 40px;
	border-radius: 4px;
	border: 2px solid #d0d0d0;
	font-weight: 600;
	font-size: 14px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;
	color: #c5c5c5;
	cursor: pointer;
	background: white;
	outline: none;
	:hover {
		background: #e9505e;
		color: white;
		border: 2px solid #e9505e;
	}
	:disabled {
		background: white;
		color: #c5c5c5;
		border: 2px solid #d0d0d0;
		cursor: not-allowed;
	}
`;
export const Header = styled.span`
	width: 350px;
	height: 50px;
	background: #e9505e;
	border-radius: 4px 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 18px;
	color: #ffffff;
`;
export const Valores = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	label {
		font-weight: 600;
		font-size: 14px;
		color: #989898;
	}
	input {
		color: #e9505e;
		font-weight: bold;
		font-size: 14px;
		width: 105px;
		margin-left: 15px;
		border: none;
		text-align: center;
		background: transparent;
		::placeholder {
			color: #e9505e;
		}
	}
	span {
		width: 65px;
		height: 30px;
		border: 0.1rem solid #e9505e;
		box-sizing: border-box;
		border-radius: 4px;
		font-weight: bold;
		font-size: 14px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #e9505e;

		margin: 5px 0 5px 25px;
		img {
			width: 15px;
			position: absolute;
			margin-right: 100px;
		}
		label {
			position: absolute;
			margin-right: 90px;
		}
	}
`;
export const ModalContent = styled.div`
	margin: 20px;
`;
export const Prods = styled.div`
	display: flex;
	span {
		:first-child {
			margin-right: 50px;
			width: 75px;
			height: 70px;
			background: #75b6cb;
			border-radius: 4px 0px;
			font-weight: 800;
			font-size: 16px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			text-transform: uppercase;
			color: #ffffff;
		}
	}
	label {
		font-size: 14px;
	}
	.prods {
		margin-left: 50px;
		.title-prods {
			position: absolute;
			top: 72px;
		}
		section {
			display: flex;
			height: 123px;
			div {
				margin: 30px 10px 0 10px;
				:first-child {
					margin-left: 0px;
				}
				width: 110px;
				height: 92px;
				box-shadow: -2px -2px 6px rgba(0, 0, 0, 0.05),
					2px 2px 6px rgba(0, 0, 0, 0.05);
				img {
					:first-child {
						width: 110px;
						height: 92px;
					}
				}
				.trash {
					cursor: pointer;
					position: absolute;
					margin-left: -25px;
					margin-top: 2px;
				}
			}
		}
	}
`;

export const InputContainer = styled.form`
	display: flex;
	input {
		width: 240px;
		height: 50px;
		padding: 0 10px;
		background: #f7f7f7;
		border-radius: 4px;
	}
	button {
		border: none;
		outline: none;
		cursor: pointer;
		height: 50px;
		width: 50px;
		background: #e94e5c;
		border-radius: 0px 5px 5px 0px;
		display: flex;
		align-items: center;
		justify-content: center;
		img {
			height: 20px;
			width: 20px;
		}
		:disabled {
			opacity: 0.8;
			cursor: not-allowed;
		}
	}
`;
export const Cards = styled.div`
	display: flex;
	flex-wrap: wrap;
	img {
		${({ selected }) => selected && `opacity: 0.5;`}
		width: 120px;
		height: 120px;
		margin: 10px;
		border-radius: 5px;
		box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.11);
		cursor: pointer;
		:hover {
			box-shadow: 0px 6px 9px rgba(0, 0, 0, 0.11);
		}
	}
	.selected {
		opacity: 1;
	}
`;
export const Card = styled.div`
	width: 150px;
	height: 110px;
	margin: 10px;
	:first-child {
		margin-left: 0px;
	}
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	border: 1px solid ${({ selected }) => (selected ? "#EB5757" : "transparent")};
	border-radius: 5px;
	img {
		width: 130px;
		height: 90px;
	}
	label {
		font-size: 12px;
		color: gray;
		line-height: 15px;
	}
`;

export const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		zIndex: 99,
		border: "none",
		borderRadius: "5px",
		backgroundColor: "white",
		padding: 0,
		width: "70vw",
		maxWidth: "1010px",
	},
	overlay: {
		zIndex: 99,
		backgroundColor: "rgba(45, 37, 40, 0.9)",
	},
};
