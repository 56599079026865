import React, { useCallback, useState, useEffect } from "react";
import * as s from "./styled-cards-tab";
import { useDropzone } from "react-dropzone";
import { Scrollbars } from "react-custom-scrollbars";
import { icons } from "../../../../assets";
import { Button } from "../../../../components";
import { managerService } from "../../../../services";

const CardsTab = ({ liveId, visible, next }) => {
	const [cards, setCards] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		managerService
			.getCards(liveId)
			.then((data) => {
				setCards(data);
				setLoading(false);
			})
			.catch((e) => {
				alert(e);
				setLoading(false);
			});
	}, [liveId]);

	useEffect(() => {
		if (cards && cards.length > 0)
			localStorage.setItem("CARDS", JSON.stringify(cards));
	}, [cards]);

	const deleteCard = (id) => {
		managerService
			.deleteCard(liveId, id)
			.then(() => setCards(cards.filter((card) => card.id !== id)))
			.catch((e) => alert(e));
	};

	const toBase64 = (file) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result.split("base64,")[1]);
			reader.onerror = (error) => reject(error);
		});

	const isFileImage = (files) => {
		return files.every(
			(file) => file && file["type"]?.split("/")[0] === "image",
		);
	};

	const onDrop = (acceptedFiles) => {
		if (!isFileImage(acceptedFiles))
			return alert("Campo aceita apenas arquivos no formato de imagem.");

		setLoading(true);
		Promise.all(
			acceptedFiles.map(async (file) => {
				let card = await toBase64(file);
				return managerService.salvarCard(liveId, { card, nameCard: file.name });
			}),
		)
			.then((data) => {
				setLoading(false);
				setCards([...cards, ...data]);
			})
			.catch(() => {
				alert(
					"Alguns arquivos não foram salvos com sucesso! Atualize a página e tente novamente.",
				);
				setLoading(false);
			});
	};

	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

	return (
		<>
			<s.Container visible={visible} className="fade-in">
				<section>
					<s.Title>Adicione os cards a biblioteca</s.Title>
					<s.Drag {...getRootProps()}>
						<input
							{...getInputProps()}
							id="input-dropzone"
							disabled={loading}
							accept="image/*"
						/>
						{!isDragActive ? (
							<>
								<div>
									<img alt="gray-heart" src={icons.hearthGrayFilled} />
								</div>
								<p>
									Arraste as imagens do seu desktop
									<br />
									aqui e faça o upload instantaneamente
								</p>
							</>
						) : (
							<>
								<div>
									<img alt="gray-heart" src={icons.hearthRed} />
								</div>
								<p>
									Solte para fazer upload <br />
									instantaneamente
								</p>
							</>
						)}
					</s.Drag>
					<s.ButtonContainer>
						<Button
							disabled={loading}
							label={loading ? "Carregando" : "Procurar no desktop"}
							green
							onClick={() => document.getElementById("input-dropzone").click()}
						/>
					</s.ButtonContainer>
				</section>
				<section>
					<s.Title>Biblioteca de cards</s.Title>
					<Scrollbars
						style={{
							height: "460px",
						}}>
						<article>
							{cards &&
								cards.map((card, i) => (
									<s.Card key={`card-${i}`}>
										<img
											src={card.url}
											id={card.nameCard}
											title={card.nameCard}
											alt={card.nameCard}
										/>
										<img
											src={icons.trash}
											className="fade-in"
											onClick={() => deleteCard(card.id)}
										/>
									</s.Card>
								))}
						</article>
					</Scrollbars>
				</section>
			</s.Container>
			<Button
				label={"Próximo"}
				style={{
					width: "200px",
					margin: "40px auto",
					display: visible ? "flex" : "none",
				}}
				onClick={() => next()}
				disabled={loading}
				isLoading={loading}
			/>
		</>
	);
};

export default CardsTab;
