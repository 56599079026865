import styled from "styled-components";

export const Title = styled.h1`
	font-weight: 600;
	font-size: 23px;
	color: #989898;
`;

export const Button = styled.button`
	border-radius: 4px;
	border: 2px solid #d0d0d0;
	font-weight: 600;
	font-size: 14px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;
	color: #c5c5c5;
	cursor: pointer;
	background: white;
	outline: none;
	:hover {
		background: #e9505e;
		color: white;
		border: 2px solid #e9505e;
	}
	:disabled {
		background: white;
		color: #c5c5c5;
		border: 2px solid #d0d0d0;
		cursor: not-allowed;
	}
`;

export const Header = styled.span`
	max-width: 300px;
	padding: 5px;
	background: #e9505e;
	border-radius: 4px 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 14px;
	word-break: break-all;
	color: #ffffff;
`;

export const ModalContent = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.875rem;

	margin: 20px 20px 30px;

	section {
		display: flex;
		flex-direction: row;
		gap: 2rem;
	}
`;

export const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		zIndex: 99,
		border: "none",
		borderRadius: "5px",
		backgroundColor: "white",
		padding: 0,
		overflow: "visible",
		maxWidth: "1010px",
	},
	overlay: {
		zIndex: 99,
		backgroundColor: "rgba(45, 37, 40, 0.9)",
	},
};
