import styled from "styled-components";
import { colors } from "../../assets";
export const Container = styled.div`
  padding: 20px 3% 20px 3%;
`;

export const TitleWrapper = styled.div`
  margin: 20px 0px;
  label {
    font-size: 16px;
    line-height: 14px;
    text-transform: uppercase;
    color: ${colors.white};
  }
  .title {
    margin-right: 10px;
    text-decoration-line: underline;
  }
`;

export const Box = styled.div`
  background: ${colors.white};
  display: flex;
  box-shadow: 2px 2px 6px rgba(255, 255, 255, 0.3),
    -2px -2px 6px rgba(255, 255, 255, 0.3);
  border-radius: 4px;
  @media (max-width: 830px) {
    flex-direction: column;
  }
  .icon-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    font-size: 35px;
    line-height: 48px;
    letter-spacing: 1px;
    color: ${colors.black};
    margin-top: 5px;
  }
  .icon-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 18px;
    color: #484848;
  }
`;
export const Resultados = styled.div`
  background: ${colors.white};
  display: flex;
  justify-content: center;
  box-shadow: 2px 2px 6px rgba(255, 255, 255, 0.3),
    -2px -2px 6px rgba(255, 255, 255, 0.3);
  border-radius: 4px;
  height: 170px;
  margin-bottom: 20px;
  .title {
    position: absolute;
    padding-top: 10px;
  }
  .wrapper {
    flex: 1;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    label {
      width: 200px;
      margin: 15px 0;
    }
  }
  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;
export const Bar = styled.div`
  width: ${(props) => props.width};
  max-width: 85%;
  min-width: 300px;
  max-height: 38px;
  min-height: 38px;
  display: flex;
  align-items: center;
  background: ${(props) => props.color || "gray"};
  font-family: Nunito;
  font-weight: bold;
  font-size: 26px;
  @media (max-width: 830px) {
    font-size: 18px;
    min-width: 40%;
  }
  @media (max-width: 750px) {
    font-size: 13px;
    min-width: 60%;
  }
  @media (max-width: 420px) {
    font-size: 10px;
    min-width: 50%;
  }
  @media (max-width: 400px) {
    font-size: 10px;
    min-width: 60%;
  }
  color: #ffffff;
  margin: 7px 0;
  padding: 0 10px;
`;

export const BoxChildren = styled.div`
  padding: 30px;
  margin: 30px 0;
  @media (max-width: 830px) {
    margin: 15px 30px;
    padding: 30px;
  }
  :first-child {
    border-right: 1px solid #ebedf0;
    @media (max-width: 830px) {
      border-right: none;
      border-bottom: 1px solid #ebedf0;
    }
  }
  :last-child {
    border-left: 1px solid #ebedf0;
    @media (max-width: 830px) {
      border-left: none;
      border-top: 1px solid #ebedf0;
    }
  }
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Icon = styled.img`
  margin-right: 10px;
  ${(props) => props.width && `width: ${props.width}px;`}
  ${(props) => props.height && `height: ${props.height}px;`}
`;

export const Dot = styled.span`
  background: ${(props) => props.color};
  border-radius: 100px;
  min-width: 22px;
  min-height: 22px;
  max-height: 22px;
  margin-right: 15px;
`;
