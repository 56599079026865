import styled from "styled-components";
import { colors } from "../../assets";

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  background: ${colors.gray};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const Login = styled.div`
  width: 100%;
  max-width: 584px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.08),
    -10px -10px 30px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  @media (max-width: 500px) {
    background: transparent;
    box-shadow: none;
  }
  padding-bottom: 37px;
`;

export const FormContainer = styled.form`
  width: 100%;
  max-width: 383px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 20px;
  @media (max-width: 500px) {
    width: calc(100% - 50px);
  }
  .entrar-com {
    font-weight: bold;
    font-size: 14px;
    color: #6a6a6a;
    text-transform: uppercase;
    @media (max-width: 500px) {
      margin-left: 20px;
    }
  }
  button {
    margin-left: auto;
  }
  .footer-login {
    width: 100%;
    @media (max-width: 500px) {
      width: calc(100% - 20px);
    }
    display: flex;
    align-items: center;
    legend {
      font-weight: bold;
      font-size: 12px;
      color: #858585;
      margin-right: auto;
    }
    input {
      margin-right: 5px;
    }
  }
`;
export const Logo = styled.img`
  width: 60vw;
  max-width: 383px;
  margin: 45px auto;
`;
export const Error = styled.label`
  font-size: 16px;
  color: #858585;
  text-align: center;
  padding-top: 20px;
  max-width: 500px;
`;
export const Input = styled.input`
  background: #ffffff;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1), -1px -1px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1),
    -1px -1px 3px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1),
    -1px -1px 3px rgba(0, 0, 0, 0.1);
  -webkit-appearance: none;
  border-radius: 4px;
  height: 40px;
  padding: 5px;
  width: calc(100% - 10px);
  margin: 10px 0 20px 0;
  @media (max-width: 500px) {
    width: calc(100% - 50px);
    margin: 10px 20px 20px 20px;
  }
  font-size: 14px;
  color: #6a6a6a;
`;
