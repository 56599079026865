import React, { useState } from 'react';
import { slide as Menu } from 'react-burger-menu';
import * as s from './styled-burger';
import { icons } from '../../assets';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default ({ logout }) => {
  const [open, setOpen] = useState(false);
  const [hover, setHover] = useState('');
  const history = useHistory();
  const usuario = useSelector(state => state.usuario);
  const manager = usuario.usuario && usuario.usuario.manager;

  return (
    <div>
      <Menu
        isOpen={open}
        onStateChange={state => setOpen(state.isOpen)}
        styles={s.styles}
        customBurgerIcon={false}
        width={'200px'}
      >
        <s.BMitem
          onMouseOver={() => setHover('close')}
          onMouseLeave={() => setHover('')}
          onClick={() => setOpen(false)}
        >
          <s.Icon size='20px' src={hover === 'close' ? icons.closeRed : icons.close} />
          <s.Label>Fechar</s.Label>
        </s.BMitem>
        <s.BMitem
          onMouseOver={() => setHover('gear')}
          onMouseLeave={() => setHover('')}
          active={history.location.pathname === '/gerenciador'}
          onClick={() => {
            history.push('/gerenciador');
            setOpen(false);
          }}
        >
          <s.Icon
            size='20px'
            src={
              history.location.pathname === '/gerenciador'
                ? icons.gearRed
                : hover === 'gear'
                ? icons.gearRed
                : icons.gear
            }
          />
          <s.Label>Gerenciador</s.Label>
        </s.BMitem>
        <s.BMitem
          onMouseOver={() => setHover('dashboard')}
          onMouseLeave={() => setHover('')}
          active={history.location.pathname === '/dashboard'}
          onClick={() => {
            history.push('/dashboard');
            setOpen(false);
          }}
        >
          <s.Icon
            size='20px'
            src={
              history.location.pathname === '/dashboard'
                ? icons.dashboardRed
                : hover === 'dashboard'
                ? icons.dashboardRed
                : icons.dashboard
            }
          />
          <s.Label>Dashboard</s.Label>
        </s.BMitem>
        <s.BMitem
          onMouseOver={() => setHover('relatorio')}
          onMouseLeave={() => setHover('')}
          active={history.location.pathname === '/relatorio'}
          onClick={() => {
            history.push('/relatorio');
            setOpen(false);
          }}
        >
          <s.Icon
            size='20px'
            height='auto'
            src={
              history.location.pathname === '/relatorio'
                ? icons.pdfIconRed
                : hover === 'relatorio'
                ? icons.pdfIconRed
                : icons.pdfIcon
            }
          />
          <s.Label>Relatório</s.Label>
        </s.BMitem>
        <s.BMitem
          onMouseOver={() => setHover('usuarios')}
          onMouseLeave={() => setHover('')}
          active={history.location.pathname === '/usuarios'}
          onClick={() => {
            history.push('/usuarios');
            setOpen(false);
          }}
        >
          <s.Icon
            style={{ width: '18px' }}
            src={
              history.location.pathname === '/usuarios'
                ? icons.user
                : hover === 'usuarios'
                ? icons.user
                : icons.userGray
            }
          />
          <s.Label>Usuários</s.Label>
        </s.BMitem>
        <s.BMitem
          onMouseOver={() => setHover('live')}
          onMouseLeave={() => setHover('')}
          active={history.location.pathname === '/live-studio'}
          onClick={() => {
            history.push('/live-studio');
            setOpen(false);
          }}
        >
          <s.Icon
            size='20px'
            src={
              history.location.pathname === '/live-studio'
                ? icons.liveRed
                : hover === 'live'
                ? icons.liveRed
                : icons.live
            }
          />
          <s.Label>Live Stúdio</s.Label>
        </s.BMitem>
        <s.BMitem
          onClick={() => {
            history.push('/live');
            setOpen(false);
          }}
        >
          <s.Label
            style={{
              marginLeft: 40,
              color: history.location.pathname === '/live' && '#e94e5c'
            }}
          >
            Live
          </s.Label>
        </s.BMitem>
        <s.BMitem onClick={logout}>
          <s.Label
            style={{
              marginLeft: 40,
              textDecoration: 'underline'
            }}
          >
            Sair
          </s.Label>
        </s.BMitem>
      </Menu>
      <s.Icon padding='10px' src={icons.burger} onClick={() => setOpen(!open)} />
    </div>
  );
};
