import { createBrowserHistory } from "history";
export const history = createBrowserHistory();

export const validaCNPJ = (cnpj) => {
	cnpj = cnpj.replace(/[^\d]+/g, "");

	if (cnpj === "") return false;

	if (cnpj.length !== 14) return false;

	// Elimina CNPJs invalidos conhecidos
	if (
		cnpj === "00000000000000" ||
		cnpj === "11111111111111" ||
		cnpj === "22222222222222" ||
		cnpj === "33333333333333" ||
		cnpj === "44444444444444" ||
		cnpj === "55555555555555" ||
		cnpj === "66666666666666" ||
		cnpj === "77777777777777" ||
		cnpj === "88888888888888" ||
		cnpj === "99999999999999"
	)
		return false;

	// Valida DVs
	var tamanho = cnpj.length - 2;
	var numeros = cnpj.substring(0, tamanho);
	var digitos = cnpj.substring(tamanho);
	var soma = 0;
	var pos = tamanho - 7;
	for (let i = tamanho; i >= 1; i--) {
		soma += numeros.charAt(tamanho - i) * pos--;
		if (pos < 2) pos = 9;
	}
	var resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
	if (resultado !== digitos.charAt(0)) return false;

	tamanho = tamanho + 1;
	numeros = cnpj.substring(0, tamanho);
	soma = 0;
	pos = tamanho - 7;
	for (let i = tamanho; i >= 1; i--) {
		soma += numeros.charAt(tamanho - i) * pos--;
		if (pos < 2) pos = 9;
	}
	resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
	if (resultado !== digitos.charAt(1)) return false;

	return true;
};

export function mascaraCNPJ(cnpj) {
	cnpj = cnpj.replace(/\D/g, "");
	cnpj = cnpj.replace(/^(\d{2})(\d)/, "$1.$2");
	cnpj = cnpj.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
	cnpj = cnpj.replace(/\.(\d{3})(\d)/, ".$1/$2");
	cnpj = cnpj.replace(/(\d{4})(\d)/, "$1-$2");
	return cnpj;
}

export const formatValor = (n, noCash) => {
	if (n === 0 || typeof n !== "number") return n;

	if (n) {
		var numero = n.toFixed(2).split(".");
		if (!noCash) {
			numero[0] = "R$ " + numero[0].split(/(?=(?:...)*$)/).join(".");
			return numero.join(",");
		} else {
			numero[0] = numero[0].split(/(?=(?:...)*$)/).join(".");
			return numero.join(",");
		}
	}
};

export const moveToElement = (elementID, targetID) => {
	var top = document.getElementById(targetID).offsetTop;
	var height = document.getElementById(targetID).offsetHeight;
	var width = document.getElementById(targetID).offsetWidth;
	document.getElementById(elementID).style.top = top + "px";
	document.getElementById(elementID).style.minHeight = height + "px";
	document.getElementById(elementID).style.minWidth = width + "px";
	document.getElementById(elementID).style.height = height + "px";
	document.getElementById(elementID).style.width = width + "px";
};

export const INTRO_KEY = localStorage.getItem("KEY") || "LIVE_DESC";

export const DESCONTO = 0.08;

export function convertImgToBase64URL(url, callback) {
	if (url) {
		var img = new Image();
		img.crossOrigin = "Anonymous";
		img.onload = function () {
			var canvas = document.createElement("CANVAS"),
				ctx = canvas.getContext("2d"),
				dataURL;
			canvas.height = img.height;
			canvas.width = img.width;
			ctx.drawImage(img, 0, 0);
			dataURL = canvas.toDataURL(`image/png`);
			callback(dataURL);
			canvas = null;
		};
		img.src = url;
	} else callback(false);
}

export const getStatus = (publicada, inicio, fim) => {
	if (!publicada) return "Não publicada";
	else {
		let start = new Date(inicio).getTime();
		let end = new Date(fim).getTime();
		let now = new Date().getTime();
		if (start >= now) return "Agendada";
		else {
			if (end >= now) return "Transmitindo";
			else return "Finalizada";
		}
	}
};

export const orderLives = (array) => {
	const broadcasting = [];
	const notBroadcasting = [];

	array.forEach((live) => {
		const status = getStatus(live.publicado, live.dataInicio, live.dataFim);
		if (status === "Transmitindo") return broadcasting.push(live);
		return notBroadcasting.push(live);
	});

	const newLives = [...broadcasting, ...notBroadcasting];
	return newLives;
};

export const handleChangeTime = (value, oldValue, changeValue) => {
	if (
		!isNaN(value[value.length - 1]) ||
		value[value.length - 1] === ":" ||
		value === ""
	) {
		if (value === "") changeValue(value);
		else if (value.length < oldValue.length) {
			if (oldValue.length === 3) changeValue(value[0]);
			else changeValue(value);
		} else if (value.length === 1) {
			if (["0", "1", "2", "3", "4", "5"].includes(value[0] + ""))
				changeValue(value);
		} else if (value.length === 2 && oldValue.length < 2) {
			changeValue(value + ":");
		} else if (value.length === 4) {
			if (["0", "1", "2", "3", "4", "5"].includes(value[3] + ""))
				changeValue(value);
		} else if (value.length === 5) changeValue(value);
	}
};
