import styled from "styled-components";
import { colors } from "../../assets";

export const Container = styled.div`
	width: 100%;
	margin-top: 40px;
	position: relative;
	display: flex;
	align-items: center;
	flex-direction: column;

	.normal {
		border: 1px solid transparent;
		padding: 2px;
	}
	.selecionada {
		border: 1px solid ${colors.green};
		border-radius: 4px;
		padding: 2px;
	}
	> div {
		> section {
			position: relative;
			display: flex;
			flex-direction: column;
			margin: 10px;
			width: 330px;
			height: 270px;
			background: #ffffff;
			box-shadow: -1px -1px 3px rgba(0, 0, 0, 0.1),
				1px 1px 3px rgba(0, 0, 0, 0.1);
			border-radius: 4px;
			legend {
				position: absolute;
				background: #e94e5c;
				padding: 5px 15px;
				border-radius: 4px;
				margin: 10px;
				color: white;
				font-size: 14px;
			}
			> span {
				display: none;
			}
			:hover {
				> span {
					display: flex;
					position: absolute;
					background: #e94e5c;
					padding: 5px 15px;
					border-radius: 4px;
					margin: 10px;
					color: white;
					font-size: 14px;
					align-self: flex-end;
					:hover {
						background: #c7323f;
					}
				}
				span.excluirButton {
					bottom: 0;
				}
			}

			.image-card {
				width: 330px;
				height: 130px;
				border-top-left-radius: 4px;
				border-top-right-radius: 4px;
				overflow: hidden;
				background-size: cover;
				background-position: 50% 50%;
				background-repeat: no-repeat;
				background-color: #ffffff;
				position: relative;

				img {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					width: 100%;
					height: fit-content;
				}
			}

			h1 {
				padding: 0 5px;
				font-size: 18px;
				text-align: center;
				word-break: break-all;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				:hover {
					text-decoration: underline;
				}
			}
			h2 {
				font-size: 14px;
				font-weight: 500;
				text-align: justify;
				margin: 12px 10px auto 10px;
				overflow: hidden;
				word-break: break-all;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
			}
			> div {
				display: flex;
				/* margin-top: auto; */
				label {
					width: 100%;
					text-align: center;
					padding: 5px 10px;
					font-weight: 600;
					font-size: 14px;
					p {
						font-size: 13px;
					}
					:first-child {
						color: ${colors.green};
						border-bottom: 4px solid ${colors.green};
						border-radius: 0 0 0 4px;
						p {
							color: ${colors.green};
						}
					}
					:last-child {
						color: ${colors.primary};
						border-radius: 0 0 4px 0;
						border-bottom: 4px solid ${colors.primary};
						p {
							color: ${colors.primary};
						}
					}
				}
			}
		}
	}
`;

export const ContainerItems = styled(Container)`
	display: flex;
	justify-content: center;
	margin-top: 0;
	flex-wrap: wrap;
	flex-direction: row;

	* {
		cursor: pointer;
	}
`;

export const Header = styled.header`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	z-index: 2;
`;

export const Title = styled.h1`
	font-weight: 600;
	font-size: 22px;
	color: #989898;
`;
export const Empty = styled.h1`
	font-weight: 600;
	font-size: 22px;
	color: #989898;
	text-align: center;
	max-width: 400px;
	margin: 40px auto;
	a {
		color: white;
		cursor: pointer;
		:hover {
			text-decoration: underline;
		}
	}
`;
