import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 65px);
  overflow-y: hidden;
`;

export const Label = styled.label`
  font-size: ${({ size }) => size};
  line-height: 44px;
  text-align: center;
  color: ${({ color }) => color || "#fff"};
  letter-spacing: 1px;
`;

export const ModalLabel = styled.label`
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #383838;
`;

export const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 4px solid ${({ color }) => color || "#7cc989"};
  box-sizing: border-box;
  border-radius: 4px;
  min-width: calc(230px + 5vw);
`;

export const Time = styled.span`
  font-weight: bold;
  font-size: calc(20px + 3vw);
  text-align: center;
  color: ${({ color }) => color || "#7cc989"};
`;

export const Icon = styled.img`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  width: 20px;
  height: 20px;
`;
