import React, { useState } from "react";
import Modal from "react-modal";
import { icons } from "../../../assets";
import Button from "../../../components/button/button";

import * as s from "./modals.styles";

const DeleteModal = ({ isOpen, onRequestClose, deleteLive, liveSelected }) => {
	const [deleteSelected, setDeleteSelected] = useState(false);
	const [confirmButtonLoading, setConfirmButtonLoading] = useState(false);
	const { id: liveId, publicado: isPublish, nome } = liveSelected;

	const handleDelete = () => {
		deleteLive({ liveId, isPublish });
		setConfirmButtonLoading(true);
		localStorage.removeItem("URL");
	};

	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={onRequestClose}
			style={s.customStyles}>
			<s.Header>{nome}</s.Header>
			<s.ModalContent>
				<s.Title>Continue para confirmar esta operação.</s.Title>
				<section>
					<Button
						type="button"
						label={
							isPublish
								? "Apagar vídeo cadastrado na live"
								: "Apagar live cadastrada"
						}
						onClick={() => setDeleteSelected((prev) => !prev)}
						style={
							deleteSelected
								? { background: "#8d2d35", padding: "5px 20px" }
								: { padding: "5px 20px" }
						}
					/>
					<Button
						type="button"
						label={
							<img
								src={icons.check}
								style={{ width: "15px" }}
								alt="botão para confirmar delete da live"
							/>
						}
						isLoading={confirmButtonLoading}
						onClick={handleDelete}
						style={{ padding: "5px 15px" }}
						disabled={!deleteSelected}
					/>
				</section>
			</s.ModalContent>
		</Modal>
	);
};

export default DeleteModal;
