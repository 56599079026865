import { createTypes } from "reduxsauce";

export const subscriptionTypes = createTypes(
  `
  INITIALIZE
  RESET
  SUBSCRIBE
  UNSUBSCRIBE
  SET_CURRENT_USERS
`,
  { prefix: "SUBSCRIPTION/" }
);

const setCurrentUsers = (currentUsers) => {
  return (dispatch) => {
    dispatch({ type: subscriptionTypes.SET_CURRENT_USERS, currentUsers });
  };
};

export const subscriptionActions = {
  setCurrentUsers,
};
