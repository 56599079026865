import { axiosAuth, axiosApp, lives, firebase } from "./";
import { store, usuariosActions, liveActions } from "../store";
import axios from "axios";

const login = (user, isCnpj) => {
  return new Promise((resolve, reject) => {
    let codUsuario = user.toUpperCase();
    if (isCnpj === true) codUsuario = codUsuario.replace(/[^\d]+/g, "");
    axiosAuth
      .post("customer/login", {
        codUsuario,
      })
      .then(async (resp) => {
        let data = { ...resp.data, codUsuario };
        await firebase
          .firestore()
          .collection("mocks")
          .doc(`${data.live}`)
          .get()
          .then((snapshot) => {
            if (snapshot.data() && snapshot.data().testUsers) {
              let user_pass = [...snapshot.data().testUsers];
              if (user_pass.includes(codUsuario))
                data.dataInicio = new Date().getTime() - 5 * 60 * 1000;
              store.dispatch(liveActions.setTestUsers(user_pass));
            }
          })
          .catch(() => {});
        if (resp.status === 202)
          resolve({
            ...data,
            dataInicio: data.dataInicio,
            manager: true,
          });
        else resolve({ ...data, dataInicio: data.dataInicio });
      })
      .catch((e) => {
        let error =
          e && e.response && e.response.data && e.response.data.message
            ? e.response.data.message
            : "Ocorreu um erro ao tentar realizar seu login!";
        reject(error);
      });
  });
};

const loginManager = (codUsuario, password, token) => {
  return new Promise((resolve, reject) => {
    axios({
      method: "post",
      url: "https://brlive-prod-server.firebaseapp.com/manager/signin",
      data: {
        codUsuario: codUsuario.toLowerCase(),
        password,
      },
      headers: { authorization: "Bearer " + token },
    })
      .then((resp) => {
        resolve({ ...resp.data, codUsuario, manager: true });
      })
      .catch((e) => {
        let error =
          e && e.response && e.response.data && e.response.data.message
            ? e.response.data.message
            : "Ocorreu um erro ao tentar realizar seu login!";
        reject(error);
      });
  });
};

export const usuarioService = {
  login,
  loginManager,
};
