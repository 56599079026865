import { axiosApp } from "./";
import { store } from "../store";

const sendMessage = (mensagem) => {
  const user = store.getState().usuario.usuario;
  let live = user
    ? user.live
      ? user.live
      : user.lives && user.lives[0]
      ? user.lives[0]
      : null
    : null;
  return new Promise((resolve, reject) => {
    axiosApp
      .post(`/customer/mensagemChat/${live.id}`, {
        mensagem,
      })
      .then(() => resolve())
      .catch((e) => reject(e));
  });
};

export const chatService = {
  sendMessage,
};
