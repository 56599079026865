import { createTypes } from "reduxsauce";
import { usuarioTypes, subscriptionTypes } from ".";
import { usuarioService } from "../../services";
import PubNub from "pubnub";

export const appTypes = createTypes(
  `
  SET_INITIALIZED
`,
  { prefix: "APP/" }
);

const initialize = () => {
  return (dispatch) => {
    if (localStorage.getItem("user")) {
      usuarioService
        .login(localStorage.getItem("user"), localStorage.getItem("isCnpj"))
        .then((data) => {
          dispatch({
            type: subscriptionTypes.INITIALIZE,
            pubnub: new PubNub({
              publishKey: "pub-c-0bdd7403-635b-4ad7-beb9-756d7278ea4c",
              subscribeKey: "sub-c-03e7bebe-5514-11eb-bf6e-f20b4949e6d2",
              uuid: data.codUsuario,
              heartbeatInterval: 30,
            }),
          });
          dispatch({
            type: usuarioTypes.SET_USUARIO,
            usuario: data,
          });
          dispatch({
            type: appTypes.SET_INITIALIZED,
            initialized: true,
          });
        })
        .catch((e) =>
          dispatch({
            type: appTypes.SET_INITIALIZED,
            initialized: true,
          })
        );
    } else
      dispatch({
        type: appTypes.SET_INITIALIZED,
        initialized: true,
      });
  };
};

export const appActions = {
  initialize,
};
