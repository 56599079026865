import React, { useEffect, useState } from "react";
import { managerService } from "../../../../services";
import * as s from "./styled-ao-vivo-tab";

const AoVivoTab = ({ liveId, visible }) => {
  const [rmtp, setRMTP] = useState("");
  const [rmtpKey, setRMTPKey] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    managerService
      .getRMTP(liveId)
      .then(({ streamUrl, streamKey }) => {
        setRMTP(streamUrl);
        setRMTPKey(streamKey);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        alert(e);
      });
  }, []);

  return (
    <s.Container visible={visible}>
      <h1>Rota RMTP: { loading ? "Carregando..." : rmtp }</h1>
      <h1>Key do canal: { loading ? 'Carregando...' : rmtpKey }</h1>
    </s.Container>
  );
};

export default AoVivoTab;
