export const customStyles = {
  container: (provided, state) => ({
    ...provided,
    width: 160,
    fontFamily: "Nunito",
    borderRadius: 4,
    boxShadow:
      "1px 1px 3px rgba(0, 0, 0, 0.1), -1px -1px 3px rgba(0, 0, 0, 0.1)",
    color: "#989898",
  }),
  control: (provided, state) => ({
    ...provided,
    minHeight: 34,
    border: "none",
    boxShadow: "none",
    backgroundColor: "white",
    color: "#989898",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    whiteSpace: "nowrap",
    maxWidth: 160,
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: "#989898",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: "none",
  }),
  option: (provided, state) => ({
    ...provided,
    color: "#989898",
    minHeight: 34,
    display: "flex",
    alignItems: "center",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#989898",
  }),
};
