import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  @media (max-width: 1010px) {
    flex-wrap: wrap;
  }
  .desktop {
    width: 100%;
    display: flex;
    justify-content: center;
    @media (max-width: 1450px) {
      display: none;
    }
  }
  .small-desktop {
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4), -2px -2px 6px rgba(0, 0, 0, 0.4);
    display: none;
    @media (max-width: 1450px) {
      width: 100%;
      display: grid;
      margin: 0 20px;
    }
    @media (max-width: 1010px) {
      margin: 0;
    }
  }
`;
export const Header = styled.div`
  flex: 1;
  display: flex;
  height: 65px;
  button {
    @media (min-width: 1010px) {
      border-radius: 4px;
    }
    height: 100%;
    flex: 1;
    border: none;
    outline: 0;
    font-weight: 900;
    font-size: 16px;
    color: #ffffff;
    cursor: pointer;
  }
`;
