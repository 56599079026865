import Loader from "react-loader-spinner";

import React from "react";
import { colors } from "../../assets";

const LoaderComponent = (props) => {
  return (
    <Loader
      type={props.type || "TailSpin"}
      color={props.color || colors.primary}
      height={props.size || 50}
      width={props.size || 50}
    />
  );
};

export default LoaderComponent;
