import React from "react";
import { Text, Page, View, StyleSheet, Image, Font } from "@react-pdf/renderer";
import SideLogo from "../sideLogo/side-logo";
import { images } from "../../../assets";

const colors = {
	grey: "#e7e3e0",
	darkGrey: "#cccccc",
	yellow: "#f8f530",
	blue: "#dcebf7",
	darkBlue: "#59a4d1",
	salmao: "#fee4d6",
};

Font.register({
	family: "Nunito",
	fontStyle: "normal",
	fonts: [
		{
			src: "https://fonts.gstatic.com/s/nunito/v16/XRXW3I6Li01BKofAksCkZg.ttf",
			fontWeight: 800,
		},
		{
			src: "https://fonts.gstatic.com/s/nunito/v16/XRXW3I6Li01BKofAjsOkZg.ttf",
			fontWeight: 700,
		},
		{
			src: "https://fonts.gstatic.com/s/nunito/v16/XRXV3I6Li01BKof4Mg.ttf",
			fontWeight: 400,
		},
	],
});

// Create styles
const s = StyleSheet.create({
	page: {
		backgroundColor: "#fff",
		width: "100%",
		height: "100%",
	},
	grey: {
		backgroundColor: colors.grey,
	},
	yellow: {
		backgroundColor: colors.yellow,
	},
	MainContent: {
		padding: "2%",
		width: "100%",
		height: "100%",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		flexDirection: "row",
		alignSelf: "center",
	},
	AlignTables: {
		width: "100%",
		display: "flex",
		justifyContent: "space-between",
		flexDirection: "row",
	},
	favoritiesHeader: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexDirection: "row",
		marginBottom: "10px",
		fontSize: "12px",
		fontFamily: "Nunito",
		fontWeight: 800,
	},
	heartImg: {
		marginLeft: "10px",
		width: "30px",
		height: "auto",
	},
	halfContainer: {
		width: "48%",
	},
	tableHeaderText: {
		width: "100%",
		paddingTop: 3,
		fontSize: "10px",
		textAlign: "center",
		display: "flex",
		justifyContent: "center",
		borderLeft: "1px solid #000",
		borderTop: "1px solid #000",
		borderRight: "1px solid #000",
		fontFamily: "Nunito",
		fontWeight: 800,
	},
	table: {},
	tableContainer: {
		display: "table",
		width: "auto",
		borderLeft: "1px solid #000",
		borderTop: "1px solid #000",
	},
	row: {
		display: "flex",
		flexDirection: "row",
	},
	headerCell: {
		width: "100%",
		fontSize: "14px",
		textAlign: "center",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexDirection: "column",
		fontFamily: "Nunito",
		fontWeight: 800,
		borderRight: "1px solid #000",
		borderBottom: "1px solid #000",
	},
	contentCell: {
		width: "100%",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		textAlign: "center",
		borderRight: "1px solid #000",
		borderBottom: "1px solid #000",
		paddingLeft: "5px",
	},
	contentRight: {
		textAlign: "right",
		alignItems: "flex-end",
		paddingRight: "5px",
	},
	imageProduct: {
		width: "100px",
		height: "auto",
	},
});

const ProductsSlide = ({ data, index }) => {
	const transformDataToArray = Object.values(data).splice(index * 2, 2);

	const brandValues = transformDataToArray.sort(
		(a, b) => (b?.quantidadeTotal || 0) - (a?.quantidadeTotal || 0),
	);

	const getBgColor = (brand) => {
		const options = {
			VIZZANO: "#FFF",
			MOLECA: "#FF0",
			MOLEKINHA: "#F899FF",
			MOLEKINHO: "#38B0F0",
			"BR SPORT": "#DDEBF7",
			ACTVITTA: "#7030A0",
			"BEIRA RIO": "#9BC2E6",
			MODARE: "#F8CBAD",
		};
		return options[brand] || Math.floor(Math.random() * 16777215).toString(16); // option color || random color
	};

	return (
		<Page size="A4" orientation="landscape" style={s.page}>
			<SideLogo />

			<View style={s.MainContent}>
				<View style={s.AlignTables}>
					{brandValues?.map((table) => {
						return (
							<View
								style={s.halfContainer}
								key={`productSlide-${table?.marca}`}>
								<View style={s.favoritiesHeader}>
									<Text>+ FAVORITADOS</Text>
									<Image source={images.pdfHeart} style={s.heartImg} />
								</View>
								<Text
									style={[
										s.tableHeaderText,
										{
											padding: "5px 0 3px 0",
											backgroundColor: getBgColor(table?.marca),
										},
									]}>
									{table?.marca}
								</Text>
								<View style={s.tableContainer}>
									<View style={s.row}>
										<View style={[s.headerCell, { width: "30%" }]}></View>
										<View style={[s.headerCell]}>
											<Text>Imagem</Text>
										</View>
										<View style={[s.headerCell]}>
											<Text>Produto</Text>
										</View>
										<View style={[s.headerCell, { width: "70%" }]}>
											<Text>{`Pares\nFavoritados`}</Text>
										</View>
									</View>

									{table?.produtos.map((row, index) => (
										<View style={s.row} key={row.ref}>
											<View style={[s.contentCell, { width: "30%" }]}>
												<Text>{index + 1}°</Text>
											</View>
											<View style={[s.contentCell]}>
												<Image
													style={s.imageProduct}
													src={`${row?.imagem}?noCache=randomText`}
													source={{
														header: {
															"Access-Control-Allow-Origin": "*",
														},
													}}
												/>
											</View>
											<View style={[s.contentCell]}>
												<Text style={{ fontSize: "8px" }}>{row?.ref}</Text>
											</View>
											<View
												style={[
													s.contentCell,
													s.contentRight,
													{ width: "70%" },
												]}>
												<Text>{row?.quantidade}</Text>
											</View>
										</View>
									))}
								</View>
							</View>
						);
					})}
				</View>
			</View>
		</Page>
	);
};

export default ProductsSlide;
