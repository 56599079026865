import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import * as s from "./styled-dashboard.js";
import { icons } from "../../assets";
import { formatValor } from "../../utils";
import { lives } from "../../services/index.js";

export default () => {
	const subscription = useSelector((state) => state.subscription);
	const user = useSelector((state) => state.usuario.usuario);
	const live = user
		? user.live
			? user.live
			: user.lives && user.lives[0]
			? user.lives[0]
			: null
		: null;

	const [favoritos, setFavoritos] = useState({
		totalFavoritos: 0,
		quantFavoritos: 0,
		valorFavoritos: 0,
	});
	const [pedidos, setPedidos] = useState({
		totalPedidos: 0,
		quantPedidos: 0,
		valorPedidos: 0,
	});
	const METRICAS = {
		PARES: 280000,
		VALOR: 13000000,
	};

	useEffect(() => {
		if (live) {
			lives
				.doc(live.id)
				.collection("favoritos")
				.onSnapshot((snapshot) => {
					let totalFavoritos = 0;
					let valorFavoritos = 0;
					let quantFavoritos = 0;
					snapshot.docs.map((doc) => {
						let fav = doc.data();
						if (fav.tipoUsuario === 1 || fav.tipoUsuario === 10) {
							valorFavoritos += fav.quantidade * fav.produto.PRECO_VENDA;
							quantFavoritos += fav.quantidade;
							totalFavoritos++;
						}
					});
					valorFavoritos = parseFloat(valorFavoritos.toFixed(2));
					setFavoritos({
						totalFavoritos,
						quantFavoritos,
						valorFavoritos,
					});
				});
			lives
				.doc(live.id)
				.collection("pedidos")
				.onSnapshot((snapshot) => {
					let totalPedidos = 0;
					let valorPedidos = 0;
					let quantPedidos = 0;
					snapshot.docs.map((doc) => {
						let ped = doc.data();
						if (ped.tipoUsuario === 1 || ped.tipoUsuario === 10) {
							ped.favoritos.map(({ quantidade, produto }) => {
								valorPedidos += quantidade * produto.PRECO_VENDA;
								quantPedidos += quantidade;
							});
							totalPedidos++;
						}
					});
					valorPedidos = parseFloat(valorPedidos.toFixed(2));
					setPedidos({
						totalPedidos,
						quantPedidos,
						valorPedidos,
					});
				});
		}
	}, []);

	return (
		<s.Container>
			<s.TitleWrapper>
				<label className="title">Relatório geral:</label>
				<label>{live.nome}</label>
			</s.TitleWrapper>
			<s.Box>
				<s.BoxChildren>
					<label className="icon-title">Total de visualizações</label>
					<div className="icon-line">
						<s.Icon src={icons.user} />
						{subscription.currentUsers}
					</div>
				</s.BoxChildren>
				<s.BoxChildren>
					<label className="icon-title">Total de pedidos</label>
					<div className="icon-line">
						<s.Icon src={icons.bag} />
						{pedidos.totalPedidos.toLocaleString()}
					</div>
				</s.BoxChildren>
				<s.BoxChildren>
					<label className="icon-title">Total de favoritos</label>
					<div className="icon-line">
						<s.Icon width={32} src={icons.hearthRed} />
						{favoritos.totalFavoritos.toLocaleString()}
					</div>
				</s.BoxChildren>
			</s.Box>
			<s.TitleWrapper>
				<label className="title">Resultados da live:</label>
				<label>{live.nome}</label>
			</s.TitleWrapper>
			<s.Resultados>
				<label className="title">Valor vendido na LIVE SHOW</label>
				<div className="wrapper">
					<label style={{ display: "flex" }}>
						<s.Dot color={"#97C8D8"} />
						No carrinho
					</label>
					<label style={{ display: "flex" }}>
						<s.Dot color={"#7CC989"} />
						Pedido efetivado
					</label>
				</div>
				<div className="content">
					<s.Bar
						color={"#97C8D8"}
						width={`${(favoritos.valorFavoritos / METRICAS.VALOR) * 100}%`}>
						{formatValor(favoritos.valorFavoritos) || "R$0,00"}
					</s.Bar>
					<s.Bar
						color={"#7CC989"}
						width={`${(pedidos.valorPedidos / METRICAS.VALOR) * 100}%`}>
						{formatValor(pedidos.valorPedidos) || "R$0,00"}
					</s.Bar>
				</div>
			</s.Resultados>
			<s.Resultados>
				<label className="title">Pares vendidos na LIVE SHOW</label>
				<div className="wrapper">
					<label style={{ display: "flex" }}>
						<s.Dot color={"#97C8D8"} />
						No carrinho
					</label>
					<label style={{ display: "flex" }}>
						<s.Dot color={"#7CC989"} />
						Pedido efetivado
					</label>
				</div>
				<div className="content">
					<s.Bar
						color={"#97C8D8"}
						width={`${(favoritos.quantFavoritos / METRICAS.PARES) * 100}%`}>
						{favoritos.quantFavoritos
							? favoritos.quantFavoritos.toLocaleString()
							: "Nenhum"}
					</s.Bar>
					<s.Bar
						color={"#7CC989"}
						width={`${(pedidos.quantPedidos / METRICAS.PARES) * 100}%`}>
						{pedidos.quantPedidos
							? pedidos.quantPedidos.toLocaleString()
							: "Nenhum"}
					</s.Bar>
				</div>
			</s.Resultados>
		</s.Container>
	);
};
