import React, { useEffect, useState } from 'react';
import { PlayerFakeLiveIntro, Chat, Favoritos, Produtos } from '..';
import * as s from './styled-home';
import { produtosService, usuarioService } from '../../services';
import { colors, icons } from '../../assets';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { usuariosActions } from '../../store';

const Home = ({ history }) => {
  const dispatch = useDispatch();
  const [sacola, setSacola] = useState(true);
  const [loading, setLoading] = useState(true);
  const usuario = useSelector(state => state.usuario.usuario);

  const beforeunloadSaveLiveData = event => {
    event.preventDefault();
    if (!usuario || !usuario.live) return;
    localStorage.setItem('@TempLiveData', JSON.stringify(usuario.live));
  };

  useEffect(() => {
    window.addEventListener('beforeunload', beforeunloadSaveLiveData);

    const storageLive = JSON.parse(localStorage.getItem('@TempLiveData'));

    if ((!usuario || !usuario.live) && !storageLive) history.push('/lives');
    else {
      if (storageLive) {
        localStorage.removeItem('@TempLiveData');
        dispatch(usuariosActions.setLiveUsuario(storageLive));
      }
    }

    return () => {
      window.removeEventListener('beforeunload', beforeunloadSaveLiveData);
    };
  }, []);

  useEffect(() => {
    if (usuario.live) {
      produtosService.initProdutos();
      produtosService.initGrades();
      produtosService.initFavoritos();
      setLoading(false);
    }
  }, [usuario]);

  if (loading) return <div />;
  return (
    <div>
      <s.Container>
        <PlayerFakeLiveIntro />
        <Chat setLoadingHome={setLoading} />
      </s.Container>
      <s.Container>
        <div className='desktop'>
          <Favoritos show />
          <Produtos show />
        </div>
        <div className='small-desktop'>
          <s.Header>
            <button style={{ background: colors.primary }} onClick={() => setSacola(true)}>
              <img alt='icone-favoritos-small-screen' src={icons.hearthWhite} id='hearth-favoritos-small' />
            </button>
            <button style={{ background: colors.secondary }} onClick={() => setSacola(false)}>
              PRODUTOS DA LIVE
            </button>
          </s.Header>
          <Favoritos noHeader show={sacola} />
          <Produtos noHeader show={!sacola} />
        </div>
      </s.Container>
      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          color: 'white',
          fontSize: '13px',
          opacity: '0.8',
          margin: '20px 0'
        }}
      >
        COPYRIGHT © 2020 CALÇADOS BEIRA RIO S.A. Todos os direitos reservados.
        <p>
          Desenvolvido por{' '}
          <a href='http://paipe.co/' target='_blank' style={{ color: 'white' }}>
            Paipe Tecnologia e Inovação
          </a>
        </p>
      </div>
    </div>
  );
};

export default withRouter(Home);
