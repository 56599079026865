import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './store';
import { Router } from 'react-router-dom';
import { history } from './utils';

import './index.css';

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <App />
      {/* <div
        style={{
          width: "100%",
          height: "60px",
          background: "#f4f4f4",
          marginBottom: "50px",
        }}
      ></div>
      <LiveTab visible /> */}
    </Router>
  </Provider>,
  document.getElementById('root')
);
