import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import { app, usuario, live, subscription, subscribe } from "./reducers/";

const { REACT_APP_REDUX_LOGGER: logger } = process.env;

const loggerMiddleware = createLogger();

const reducers = combineReducers({
  app,
  usuario,
  live,
  subscription,
  subscribe,
});

const middlewares =
  logger === "nao"
    ? applyMiddleware(thunkMiddleware)
    : applyMiddleware(thunkMiddleware, loggerMiddleware);

export const store = createStore(
  reducers,
  compose(
    middlewares,
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : (f) => f
  )
);

export * from "./actions";
