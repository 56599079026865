import styled from "styled-components";

export const CheckContainer = styled.div`
	cursor: default;
	display: flex;

	:active {
		animation: fadeIn ease 1s;
		-webkit-animation: fadeIn ease 1s;
		-moz-animation: fadeIn ease 1s;
		-o-animation: fadeIn ease 1s;
		-ms-animation: fadeIn ease 1s;
	}

	${({ disabled }) =>
		disabled &&
		`opacity: 0.8;
    cursor: not-allowed !important;`}
`;

export const Check = styled.span`
	display: flex;
	min-width: 18px;
	min-height: 18px;
	max-width: 18px;
	max-height: 18px;
	margin-right: 10px;
	cursor: pointer;
	justify-content: center;
	align-items: center;
	background: ${({ all }) => (all ? `#e94e5c` : "white")};
	border-radius: 4px;
	border: 1px solid #989898;
	img {
		width: 18px;
		height: 10px;
	}
	:active {
		animation: fadeIn ease 1s;
		-webkit-animation: fadeIn ease 1s;
		-moz-animation: fadeIn ease 1s;
		-o-animation: fadeIn ease 1s;
		-ms-animation: fadeIn ease 1s;
	}
	/* ${({ disabled }) =>
		disabled &&
		`opacity: 0.8;
    cursor: not-allowed;`} */
`;
