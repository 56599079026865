import styled from "styled-components";
import { colors } from "../../assets";

export const Container = styled.div`
  flex: 1;
  padding: 20px 3%;
`;

export const Title = styled.div`
  margin: 20px 0px;
  label {
    font-size: 16px;
    line-height: 14px;
    text-transform: uppercase;
    color: ${colors.white};
  }
  .title {
    margin-right: 10px;
    text-decoration-line: underline;
  }
`;

export const Box = styled.div`
  flex: 1;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  box-shadow: 2px 2px 6px rgba(255, 255, 255, 0.3),
    -2px -2px 6px rgba(255, 255, 255, 0.3);
  border-radius: 4px;
  section {
    min-height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    label {
      font-size: 20px;
    }
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      input {
        margin-top: -30px;
      }
    }
    span {
      display: flex;
      align-items: center;
      font-size: 40px;
      img {
        margin-right: 10px;
        width: 30px;
        height: 30px;
      }
    }
  }
`;

export const Input = styled.input`
  font-size: 40px;
  border: none;
  outline: none;
  text-align: center;
  min-width: 120px;
  max-width: 170px;
  min-height: 50px;
`;
export const ListItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-height: 100px;
  span {
    margin: 5px;
    padding: 0 5px;
    height: 40px;
    background: ${colors.gray};
    border-radius: 4px;
    display: flex;
    align-items: center;
    img {
      cursor: pointer;
    }
    div {
      margin-right: 20px;
    }
  }
`;
