import axios from "axios";
import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/analytics";
import "firebase/storage";
import { store } from "../store";

const { REACT_APP_API, REACT_APP_PROD: PROD } = process.env;

var firebaseConfigProd = {
	apiKey: "AIzaSyAkkSkFtLJWPu1D5JcyTzT__hroO5mIUBU",
	authDomain: "brlive-prod.firebaseapp.com",
	databaseURL: "https://brlive-prod.firebaseio.com",
	projectId: "brlive-prod",
	storageBucket: "brlive-prod.appspot.com",
	messagingSenderId: "510885244926",
	appId: "1:510885244926:web:a3adc0409ec2d94ad51aaf",
	measurementId: "G-FT94SFWRSZ",
};
const firebaseConfigHml = {
	apiKey: "AIzaSyAdK0VSebozpOOxn3Z8m6J2t6AFXRdmZw8",
	authDomain: "brlive-hml.firebaseapp.com",
	databaseURL: "https://brlive-hml.firebaseio.com",
	projectId: "brlive-hml",
	storageBucket: "brlive-hml.appspot.com",
	messagingSenderId: "358965842060",
	appId: "1:358965842060:web:34fb2d74395a4ffa986ba9",
	measurementId: "G-BELSY134S6",
};
// Initialize Firebase
firebase.initializeApp(PROD === "sim" ? firebaseConfigProd : firebaseConfigHml);
firebase.analytics();

const requestHandler = async (request) => {
	const user = store.getState().usuario.usuario;
	request.headers["Access-Control-Allow-Methods"] = "*";
	request.headers["Content-Type"] = "application/json";
	request.headers["Accept"] = "application/json";
	request.headers["Access-Control-Expose-Headers"] = "Authorization,Location";
	request.headers["Authorization"] = "Bearer " + user.token;
	request.headers["X-Requested-With"] = "XMLHttpRequest";

	return request;
};
const requestHandlerExcel = async (request) => {
	const user = store.getState().usuario.usuario;
	request.headers["Authorization"] = "Bearer " + user.token;

	return request;
};

const requestHandlerFixedToken = async (request) => {
	request.headers["Access-Control-Allow-Methods"] = "*";
	request.headers["Access-Control-Expose-Headers"] = "Authorization,Location";
	request.headers["Content-Type"] = "application/json; charset=UTF-8";
	request.headers["Accept"] = "application/json; charset=UTF-8";
	request.headers["X-Requested-With"] = "XMLHttpRequest";
	request.headers[
		"Authorization"
	] = `Bearer eyJhbGciOiJIUzI1NiJ9.MTU5MjIyNDM1NA.efxVEHkZqjheOk5G4SqUkru7J5Ebp7Pw_ckmdASAaAs`;
	return request;
};

const errorHandler = (error) => error;

const axiosApp = axios.create({
	baseURL: REACT_APP_API,
});

const axiosAuth = axios.create({
	baseURL: REACT_APP_API,
});

const axiosExcel = axios.create({
	baseURL: `${REACT_APP_API}report/gerarExcel/`,
	responseType: "blob",
	timeout: 900000, // 15min,
});

axiosExcel.interceptors.request.use(requestHandlerExcel, errorHandler);

[axiosApp].forEach((item) => {
	item.interceptors.request.use(requestHandler, errorHandler);
});
axiosAuth.interceptors.request.use(requestHandlerFixedToken, errorHandler);

export { axiosExcel, axiosApp, axiosAuth, firebase };
export const lives = firebase.firestore().collection("lives");
export const firestore = firebase.firestore();
export const storageRef = firebase.storage().ref();
export * from "./usuario.service";
export * from "./chat.service";
export * from "./produtos.service";
export * from "./manager.service";
