import React, { useEffect, useState } from "react";
import * as s from "./styled-salas-tab";
import { Select, Button, Check } from "../../../../components";
import { icons } from "../../../../assets";
import { storageRef } from "../../../../services";

const CardSala = ({
	i,
	setSalas,
	salas,
	setDeleting,
	sala,
	options,
	liveId,
	forceUpdate,
	saving,
	aoVivo,
}) => {
	//Nesse componente de card os dados são alterados através do setSalas mas também são alterados aqui
	const [introP, setIntroP] = useState();
	const [videoP, setVideoP] = useState();
	const [regional, setRegional] = useState(
		sala.regional || { label: "Selecione" },
	);
	const [chat, setChat] = useState(sala.chat);

	const [hasVideo, setHasVideo] = useState(false);

	const checkExists = (videoUrl) => {
		var v = document.createElement("video");
		v.src = videoUrl;

		v.onerror = () => {
			setHasVideo(false);
		};

		v.addEventListener("loadedmetadata", () => {
			setHasVideo(true);
		});
	};

	useEffect(() => {
		if (regional !== sala.regional) setRegional(sala.regional);
		checkExists(sala?.live);

		return () => setHasVideo(false);
	}, [regional, sala]);

	const uploadVideo = (file, i, intro) => {
		const uploadTask = storageRef
			.child(
				`lives/${liveId}/${intro ? "intro" : "live"}-sala-${i + 1}-${
					file.name
				}`,
			)
			.put(file);
		let newSalas = salas;
		uploadTask.on(
			"state_changed",
			(snapshot) => {
				let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
				if (intro) setIntroP(progress);
				else setVideoP(progress);
			},
			() => {
				alert("Ocorreu um erro ao tentar realizar seu upload :(");
				if (intro) setIntroP();
				else setVideoP();
			},
			() => {
				uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
					if (intro) {
						newSalas[i].intro = downloadURL;
						setIntroP();
					} else {
						newSalas[i].live = downloadURL;
						setVideoP();
					}
					checkExists(downloadURL);
					setSalas(newSalas);
				});
			},
		);
	};

	return (
		<section key={i} className="fade-in">
			{i === 0 && <s.Title>Criar salas</s.Title>}
			<div className="header-card-sala">
				<div className="sala">Sala {i + 1}</div>
				<div
					className="duplicar animated-linear"
					style={{
						cursor: videoP || introP || saving ? "not-allowed" : "pointer",
					}}
					onClick={
						videoP || introP || saving
							? () => {}
							: () => {
									let { live, intro, chat } = sala;
									setSalas([...salas, { live, intro, chat }]);
							  }
					}>
					Duplicar sala
				</div>
				{salas.length > 1 && (
					<img
						alt={`icone-deletar-sala-${i + 1}`}
						src={icons.trash}
						className="trash animated-linear"
						style={{
							cursor: videoP || introP || saving ? "not-allowed" : "pointer",
						}}
						onClick={
							videoP || introP || saving ? () => {} : () => setDeleting(i)
						}
					/>
				)}
			</div>
			<label>Regional</label>
			<Select
				customStyles={s.selectStyles}
				options={options}
				value={regional}
				onChange={
					saving
						? () => {}
						: (v) => {
								let newSalas = salas;
								newSalas[i].regional = v;
								setSalas(newSalas);
								setRegional(v);
								forceUpdate();
						  }
				}
			/>
			<input
				type="file"
				id={`video-sala-${i + 1}`}
				style={{ display: "none" }}
				disabled={aoVivo}
				accept="video/*"
				onChange={(e) => e.target.files[0] && uploadVideo(e.target.files[0], i)}
			/>

			<Button
				disabled={videoP || saving || aoVivo}
				green={hasVideo}
				label={
					aoVivo
						? "Live ao vivo!"
						: videoP
						? `${parseInt(videoP)}%`
						: hasVideo
						? "Alterar video"
						: "Carregar video"
				}
				style={{ margin: "20px 22px" }}
				onClick={() => document.getElementById(`video-sala-${i + 1}`).click()}
			/>
			<input
				type="file"
				id={`video-intro-${i + 1}`}
				style={{ display: "none" }}
				accept="video/*"
				onChange={(e) =>
					e.target.files[0] && uploadVideo(e.target.files[0], i, true)
				}
			/>
			<Button
				disabled={saving}
				green={sala.intro}
				label={
					introP
						? `${parseInt(introP)}%`
						: sala.intro
						? "Alterar intro regional"
						: "Adicionar intro regional"
				}
				style={{ margin: "0 22px" }}
				outline={!sala.intro}
				onClick={() => document.getElementById(`video-intro-${i + 1}`).click()}
			/>
			<s.Chat active={chat}>
				<Check
					disabled={saving}
					active={chat}
					onClick={() => {
						let newSalas = salas;
						const actualSala = newSalas[i];
						actualSala.chat = !actualSala.chat;
						setSalas(newSalas);
						setChat((prev) => !prev);
					}}>
					Habilitar live chat
				</Check>
			</s.Chat>
		</section>
	);
};

export default CardSala;
