import { axiosApp, axiosExcel, firebase } from "./";

const getError = (e, msg) =>
	e && e.response && e.response.data && e.response.data.message
		? e.response.data.message
		: msg;

const getLiveStatus = (idLive) => {
	return new Promise((resolve, reject) => {
		axiosApp
			.get(`manager/live/${idLive}`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch((e) =>
				reject(
					getError(e, "Ocorreu um erro ao tentar buscar o status dessa live!"),
				),
			);
	});
};
const postLiveEnd = (idLive) => {
	return new Promise((resolve, reject) => {
		axiosApp
			.post(`manager/live/finalizar`, { idLive })
			.then(({ data }) => {
				resolve(data);
			})
			.catch((e) =>
				reject(getError(e, "Ocorreu um erro ao tentar finalizar essa live!")),
			);
	});
};
const criarLive = (body) => {
	return new Promise((resolve, reject) => {
		axiosApp
			.post(`/manager/criarLive`, body)
			.then(({ data }) => resolve(data))
			.catch((e) =>
				reject(getError(e, "Ocorreu um erro ao tentar salvar criar sua live!")),
			);
	});
};
const editarLive = (id, body) => {
	return new Promise((resolve, reject) => {
		axiosApp
			.put(`/manager/editarLive/${id}`, body)
			.then(({ data }) => resolve(data))
			.catch((e) =>
				reject(getError(e, "Ocorreu um erro ao tentar salvar as salas!")),
			);
	});
};

const excluirLive = (id) => {
	return new Promise((resolve, reject) => {
		axiosApp
			.delete(`/manager/deletarLive/${id}`)
			.then((res) => resolve(res))
			.catch((e) => reject(getError(e, "Ocorreu um erro ao tentar excluir.")));
	});
};

const editarLiveThumbnail = (id, body) => {
	return new Promise((resolve, reject) => {
		axiosApp
			.put(`/manager/editarThumbail/${id}`, body)
			.then(({ data }) => resolve(data))
			.catch((e) =>
				reject(getError(e, "Ocorreu um erro ao tentar alterar a thumbnail!")),
			);
	});
};
const getFormasPagamento = () => {
	return new Promise((resolve, reject) => {
		firebase
			.firestore()
			.collection(`formasPagamento`)
			.get()
			.then((snapshot) => resolve(snapshot.docs.map((doc) => doc.data())))
			.catch(() => reject());
	});
};
const getLiveInfo = (id) => {
	return new Promise((resolve, reject) => {
		axiosApp
			.get(`/customer/viewLive/${id}`)
			.then((resp) => resolve(resp.data))
			.catch((e) =>
				reject(getError(e, "Ocorreu um erro ao tentar selecionar essa live!")),
			);
	});
};
const getLive = (id) => {
	return new Promise((resolve, reject) => {
		axiosApp
			.get(`/manager/getLive/${id}`)
			.then((resp) => resolve(resp.data))
			.catch((e) =>
				reject(getError(e, "Ocorreu um erro ao buscar essa live!")),
			);
	});
};
const getLiveSalas = (id) => {
	return new Promise((resolve, reject) => {
		axiosApp
			.get(`/manager/buscarSalas/${id}`)
			.then((resp) => resolve(resp.data))
			.catch((e) =>
				reject(getError(e, "Ocorreu um erro ao buscar as salas dessa live!")),
			);
	});
};
const getRegionais = () => {
	return new Promise((resolve, reject) => {
		axiosApp
			.get(`/manager/regionais`)
			.then((resp) => resolve(resp.data))
			.catch((e) =>
				reject(getError(e, "Ocorreu um erro ao buscar as regionais!")),
			);
	});
};
const saveSalas = (id, body, geral) => {
	return new Promise((resolve, reject) => {
		axiosApp
			.post(`/manager/cadastrarSalas/${id}`, body, { params: { geral } })
			.then((resp) => resolve(resp.data))
			.catch((e) =>
				reject(getError(e, "Ocorreu um erro ao tentar salvar as salas!")),
			);
	});
};
const editarSalas = (id, body, geral) => {
	return new Promise((resolve, reject) => {
		axiosApp
			.put(`/manager/editarSalas/${id}`, body, { params: { geral } })
			.then((resp) => resolve(resp.data))
			.catch((e) =>
				reject(getError(e, "Ocorreu um erro ao tentar editar sua live!")),
			);
	});
};
const deleteSala = (idLive, regional) => {
	return new Promise((resolve, reject) => {
		axiosApp
			.delete(`/manager/deletarSala/${idLive}/${regional}`)
			.then((resp) => resolve(resp.data))
			.catch((e) =>
				reject(getError(e, "Ocorreu um erro ao tentar deletar essa sala!")),
			);
	});
};
const salvarCard = (idLive, card) => {
	return new Promise((resolve, reject) => {
		axiosApp
			.post(`/manager/cadastrarCard/${idLive}`, card)
			.then((resp) => resolve(resp.data))
			.catch((e) =>
				reject(getError(e, "Ocorreu um erro ao tentar salvar seu card!")),
			);
	});
};
const publicarLive = (idLive, publicar) => {
	return new Promise((resolve, reject) => {
		axiosApp
			.put(`/manager/publicarLive/${idLive}`, {
				publicar,
			})
			.then((resp) => resolve(resp.data))
			.catch((e) =>
				reject(
					getError(
						e,
						`Ocorreu um erro ao tentar ${
							publicar ? "publicar" : "inativar"
						} sua live!`,
					),
				),
			);
	});
};
const getCards = (idLive) => {
	return new Promise((resolve, reject) => {
		axiosApp
			.get(`/manager/cards/${idLive}`)
			.then((resp) => resolve(resp.data))
			.catch((e) =>
				reject(getError(e, `Ocorreu um erro ao tentar buscar seus cards!`)),
			);
	});
};
const deleteCard = (idLive, id) => {
	return new Promise((resolve, reject) => {
		axiosApp
			.delete(`/manager/deletarCard/${idLive}/${id}`)
			.then((resp) => resolve(resp.data))
			.catch((e) =>
				reject(getError(e, `Ocorreu um erro ao tentar excluir seu card!`)),
			);
	});
};
const pesquisarProduto = (idLive, codigo) => {
	return new Promise((resolve, reject) => {
		axiosApp
			.get(`/manager/apiProduto/${idLive}/${codigo}`)
			.then((resp) => resolve(resp.data))
			.catch((e) =>
				reject(getError(e, `Ocorreu um erro ao tentar buscar esse produto!`)),
			);
	});
};
const cadastrarProdutos = (idLive, body) => {
	return new Promise((resolve, reject) => {
		axiosApp
			.post(`/manager/cadastrarProdutos/${idLive}`, body)
			.then((resp) => resolve(resp.data))
			.catch((e) =>
				reject(
					getError(e, `Ocorreu um erro ao tentar cadastrar seus produtos!`),
				),
			);
	});
};
const alterarProdutos = (idLive, body) => {
	return new Promise((resolve, reject) => {
		axiosApp
			.put(`/manager/alterarProdutos/${idLive}`, body)
			.then((resp) => resolve(resp.data))
			.catch((e) =>
				reject(getError(e, `Ocorreu um erro ao tentar alterar seus produtos!`)),
			);
	});
};
const deletarProdutos = (idLive, body) => {
	return new Promise((resolve, reject) => {
		axiosApp
			.delete(`/manager/deletarProdutos/${idLive}`, { data: body })
			.then((resp) => resolve(resp.data))
			.catch((e) =>
				reject(getError(e, `Ocorreu um erro ao tentar deletar seus produtos!`)),
			);
	});
};
const buscarProdutos = (idLive) => {
	return new Promise((resolve, reject) => {
		axiosApp
			.get(`/manager/produtos/${idLive}`)
			.then((resp) => resolve(resp.data))
			.catch((e) =>
				reject(getError(e, `Ocorreu um erro ao tentar buscar seus produtos!`)),
			);
	});
};
const buscarProdutosPrincipais = (idLive) => {
	return new Promise((resolve, reject) => {
		axiosApp
			.get(`/manager/produtosPrincipais/${idLive}`)
			.then((resp) => resolve(resp.data))
			.catch((e) =>
				reject(getError(e, `Ocorreu um erro ao tentar buscar seus produtos!`)),
			);
	});
};
const buscarCombos = (idLive) => {
	return new Promise((resolve, reject) => {
		axiosApp
			.get(`/manager/buscarCombos/${idLive}`)
			.then((resp) => resolve(resp.data))
			.catch((e) =>
				reject(getError(e, `Ocorreu um erro ao tentar buscar seus combos!`)),
			);
	});
};
const cadastrarCombos = (idLive, body) => {
	return new Promise((resolve, reject) => {
		axiosApp
			.post(`/manager/cadastrarCombos/${idLive}`, body)
			.then((resp) => resolve(resp.data))
			.catch((e) =>
				reject(getError(e, `Ocorreu um erro ao tentar cadastrar seus combos!`)),
			);
	});
};
const alterarCombos = (idLive, body) => {
	return new Promise((resolve, reject) => {
		axiosApp
			.put(`/manager/alterarCombos/${idLive}`, body)
			.then((resp) => resolve(resp.data))
			.catch((e) =>
				reject(getError(e, `Ocorreu um erro ao tentar alterar seus combos!`)),
			);
	});
};
const deletarCombo = (idLive, idCombo) => {
	return new Promise((resolve, reject) => {
		axiosApp
			.delete(`/manager/deletarCombo/${idLive}/${idCombo}`)
			.then((resp) => resolve(resp.data))
			.catch((e) =>
				reject(getError(e, `Ocorreu um erro ao tentar deletar seu combo!`)),
			);
	});
};
const getRMTP = (idLive) => {
	return new Promise((resolve, reject) => {
		axiosApp
			.get(`/manager/endpointRMTP/${idLive}`)
			.then((resp) => resolve(resp.data))
			.catch((e) =>
				reject(
					getError(e, `Ocorreu um erro ao tentar buscar o endpoint RMTP!`),
				),
			);
	});
};
const getPdfData = (idLive, body) => {
	const query = {
		params: body,
	};

	return new Promise((resolve, reject) => {
		axiosApp
			.get(`/report/pdf/${idLive}`, query)
			.then((resp) => resolve(resp.data))
			.catch((e) =>
				reject(
					getError(e, `Ocorreu um erro ao tentar buscar os dados da live...`),
				),
			);
	});
};

const getExcelData = async ({ order, liveId }) => {
	const types = order;

	const promiseExcel = (path) => {
		return new Promise((resolve, reject) => {
			axiosExcel
				.post(path, { liveId })
				.then((resp) => {
					resolve(resp.data);
				})
				.catch((e) => {
					reject(e);
				});
		});
	};

	const promises = types.map((type) => promiseExcel(type).catch((e) => e));
	return Promise.all(promises);
};

const getClientes = (id) => {
	return new Promise((resolve, reject) => {
		axiosApp
			.get(`/manager/usuarios?live=${id}`)
			.then((resp) => resolve(resp.data))
			.catch((e) =>
				reject(
					getError(
						e,
						`Ocorreu um erro ao tentar buscar os clientes da live...`,
					),
				),
			);
	});
};

export const managerService = {
	// Salas
	getLiveSalas,
	saveSalas,
	editarLive,
	excluirLive,
	editarLiveThumbnail,
	editarSalas,
	deleteSala,
	// Canal
	getRegionais,
	//Card
	salvarCard,
	getCards,
	deleteCard,
	// Live
	criarLive,
	getLive,
	publicarLive,
	getLiveStatus,
	postLiveEnd,
	getLiveInfo,
	// Produtos
	buscarProdutosPrincipais,
	cadastrarProdutos,
	buscarProdutos,
	alterarProdutos,
	deletarProdutos,
	pesquisarProduto,
	// Combos
	buscarCombos,
	cadastrarCombos,
	alterarCombos,
	deletarCombo,

	getClientes,
	getFormasPagamento,
	getRMTP,
	getPdfData,
	getExcelData,
};
