import React, { useState, useEffect, useRef, useCallback } from "react";
import Modal from "react-modal";
import { Button, Loader, Check } from "../../components";
import Scrollbars from "react-custom-scrollbars";
import * as s from "./styled-relatorio-modal";
import { icons } from "../../assets";
import { managerService } from "../../services";
import { Link, useHistory } from "react-router-dom";

const RelatorioModal = ({ isOpen, onRequestClose, liveData }) => {
	const [generateFileLoading, setGenerateFileLoading] = useState(false);
	const [generateFileLoadingExcel, setGenerateFileLoadingExcel] =
		useState(false);

	const [selectedRoom, setSelectedRoom] = useState({});
	const [rooms, setRooms] = useState([]);

	const [selectedClient, setSelectedClient] = useState({});
	const [clients, setClients] = useState([]);

	const [selectedBrand, setSelectedBrand] = useState({});
	const [brands, setBrands] = useState([]);

	const [loading, setLoading] = useState({
		brands: true,
		clients: true,
		rooms: true,
	});

	const [selectedCheckbox, setSelectedCheckbox] = useState({
		user: true,
		carrinho: true,
		pedidos: true,
	});

	const { id: liveId, nome: liveName } = liveData;
	const history = useHistory();
	const fetchRooms = useCallback(() => {
		managerService
			.getLiveSalas(liveId)
			.then((data) => {
				if (!Array.isArray(data)) {
					setRooms([data]);
					return;
				}
				setRooms(data);

				setLoading((prev) => ({ ...prev, rooms: false }));
			})
			.catch((e) => {
				alert(e);
			});
	}, [liveId]);
	const fetchClients = useCallback(() => {
		managerService
			.getClientes(liveId)
			.then((data) => {
				setClients(data);
				setLoading((prev) => ({ ...prev, clients: false }));
			})
			.catch((e) => {
				alert(e);
			});
	}, [liveId]);
	const fetchProducts = useCallback(() => {
		managerService
			.buscarProdutos(liveId)
			.then((data) => {
				const filteredData = data.filter(
					(brand, i, arr) =>
						arr.findIndex(
							(brand2) => brand2.DESCRICAO_MARCA === brand.DESCRICAO_MARCA,
						) === i,
				);
				setBrands(filteredData);
				setLoading((prev) => ({ ...prev, brands: false }));
			})
			.catch((e) => alert(e));
	}, [liveId]);
	useEffect(() => {
		if (liveId) {
			fetchProducts();
			fetchClients();
			fetchRooms();
		}
	}, [fetchClients, fetchProducts, fetchRooms, liveId]);
	const isEmptyObject = (obj) => {
		return Object.keys(obj)?.length < 1;
	};
	const isButtonDisable = () => {
		if (rooms?.length > 1 && isEmptyObject(selectedRoom)) return true;
		if (clients?.length > 1 && isEmptyObject(selectedClient)) return true;
		if (brands?.length > 1 && isEmptyObject(selectedBrand)) return true;
		return false;
	};
	const clientsChange = (opt) => {
		const allOptions = { id: "all", nomeRegional: "Todas opções" };
		if (rooms?.length > 0) {
			if (opt?.id === "all") {
				return setSelectedRoom(allOptions);
			}
			if (!isEmptyObject(selectedRoom)) {
				const matchRoom = selectedRoom.regional === opt.siglaRegional;
				if (matchRoom) return;
			}
			const clientRoom = rooms.filter(
				(room) => room.regional === opt.siglaRegional,
			)[0];
			setSelectedRoom(clientRoom);
			alert(
				"O campo sala foi alterado para a sala referente a do cliente selecionado...",
			);
		}
	};
	const roomsChange = (opt) => {
		if (isEmptyObject(selectedClient)) return;
		const allOptions = { id: "all", nome: "Todas opções" };
		if (clients?.length > 0) {
			if (!isEmptyObject(selectedClient)) {
				const matchRoom = selectedClient.siglaRegional === opt.regional;
				if (matchRoom) return;
			}
			setSelectedClient(allOptions);
			alert("O cliente selecionado não é referente a esta sala...");
		}
	};
	const genereatePdf = () => {
		setGenerateFileLoading(true);
		const clientFilter = selectedClient.id === "all" ? [] : selectedClient;
		const brandFilter = selectedBrand.id === "all" ? [] : selectedBrand;
		const roomsFilter = selectedRoom.id === "all" ? [] : selectedRoom;
		managerService
			.getLiveInfo(liveData.id)
			.then((data) => {
				const simplifyLiveData = {
					condicoes: data.condicoesPagamento,
					dataInicio: liveData.dataInicio,
					id: liveData.id,
					nome: liveData.nome,
					thumbnail: liveData.thumbnail,
				};
				history.push(`/pdf/${liveId}`, {
					liveData: simplifyLiveData,
					filters: {
						client: clientFilter,
						brand: brandFilter,
						room: roomsFilter,
					},
				});
			})
			.catch((e) => alert(e))
			.finally(() => setGenerateFileLoading(false));
	};
	const generateExcel = () => {
		setGenerateFileLoadingExcel(true);
		const orderToExecute = Object.keys(selectedCheckbox).filter(
			(item) => selectedCheckbox[item],
		);

		managerService
			.getExcelData({ order: orderToExecute, liveId: liveData.id })
			.then((data) => {
				const typeOfAlerts = {
					user: "Relatório de cliente não possui dados!", // user
					carrinho: "Relatório de carrinho, não possui dados disponíveis.", // carrinho
					pedidos: "Relatório de pedidos, não possui dados disponíveis.", // pedidos
				};

				const nameOfFiles = {
					user: "Relatório_Cliente",
					carrinho: "Relatório_Carrinho",
					pedidos: "Relatório_Pedidos",
				};

				data.forEach((blob, index) => {
					console.log(orderToExecute);
					console.log(orderToExecute[index]);
					console.log(nameOfFiles[orderToExecute[index]]);

					if (blob.size <= 2) {
						return alert(typeOfAlerts[orderToExecute[index]]);
					}
					const url = window.URL.createObjectURL(new Blob([blob]));
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute(
						"download",
						`${nameOfFiles[orderToExecute[index]]}__${liveData.nome}.xls`,
					);
					document.body.appendChild(link);
					link.click();
					link.parentNode.removeChild(link);
				});
				setGenerateFileLoadingExcel(false);
			})
			.catch((e) => alert(e));
	};
	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={onRequestClose}
			style={s.customStyles}>
			<s.Header>{liveName}</s.Header>
			<s.ModalContent>
				<s.SectionPDF>
					<div className="inputs-section">
						<div>
							<label>Cliente</label>
							<SearchInput
								placeholder="Selecione um cliente"
								icon={icons.play}
								options={clients}
								selectedOption={selectedClient}
								setSelected={setSelectedClient}
								loading={loading.clients}
								onChange={clientsChange}
								optionToShow={`nome`}
								allOptions={false}
							/>
						</div>
						<div>
							<label>Sala Regional</label>
							<SearchInput
								placeholder="Selecione uma sala"
								icon={icons.play}
								options={rooms}
								selectedOption={selectedRoom}
								setSelected={setSelectedRoom}
								loading={loading.rooms}
								optionToShow={`nomeRegional`}
								onChange={roomsChange}
							/>
						</div>
						<div>
							<label>Marca</label>
							<SearchInput
								placeholder="Selecione uma marca"
								icon={icons.play}
								options={brands}
								selectedOption={selectedBrand}
								setSelected={setSelectedBrand}
								onChange={() => {}}
								loading={loading.brands}
								optionToShow={`DESCRICAO_MARCA`}
							/>
						</div>
					</div>
					<Button
						label="Gerar PDF"
						isLoading={generateFileLoading}
						disabled={isButtonDisable()}
						onClick={genereatePdf}
						style={{ width: "220px", height: "40px", alignSelf: "center" }}
					/>
				</s.SectionPDF>
				<div className="middle-line"></div>
				<s.SectionExcel>
					<div className="checkbox-section">
						<div>
							<Check
								active={selectedCheckbox.user}
								id="comboFor"
								onClick={(v) => {
									setSelectedCheckbox((prev) => ({
										...prev,
										user: !prev.user,
									}));
								}}>
								Excel de Cliente
							</Check>
						</div>
						<div>
							<Check
								active={selectedCheckbox.carrinho}
								id="comboFor"
								onClick={(v) => {
									setSelectedCheckbox((prev) => ({
										...prev,
										carrinho: !prev.carrinho,
									}));
								}}>
								Excel de Carrinho
							</Check>
						</div>
						<div>
							<Check
								active={selectedCheckbox.pedidos}
								id="comboFor"
								onClick={(v) => {
									setSelectedCheckbox((prev) => ({
										...prev,
										pedidos: !prev.pedidos,
									}));
								}}>
								Excel de Pedidos
							</Check>
						</div>
					</div>
					<Button
						title="Para fazer o download de múltiplos arquivos deve ter essa configuração configurada no navegador"
						label="Gerar Excel"
						disabled={Object.values(selectedCheckbox).every((item) => !item)}
						isLoading={generateFileLoadingExcel}
						onClick={generateExcel}
						download
						style={{ width: "220px", height: "40px", alignSelf: "center" }}>
						<Link to="_" target="_blank" download></Link>
					</Button>
					<span>
						Seu navegador deve estar habilitador para fazer downloads desta url
					</span>
				</s.SectionExcel>
			</s.ModalContent>
		</Modal>
	);
};
const SearchInput = React.memo(
	({
		placeholder,
		icon,
		options,
		selectedOption,
		setSelected,
		optionToShow,
		loading,
		onChange,
		...rest
	}) => {
		const [drop, setDrop] = useState(false);
		const searchRef = useRef(null);
		const [value, setValue] = useState("");
		const [filteredOptions, setFilteredOptions] = useState([]);
		const readOnly =
			Object?.keys(options)?.length < 2 || !Array.isArray(options);
		const hasOption = () => {
			if (options[0]) {
				if (options[0][optionToShow]) return options[0][optionToShow];
				else return "Valor único";
			}
			return "Valor único";
		};
		const readOnlyInputValue = readOnly ? hasOption() : "Valor único";

		useEffect(() => {
			if (setDrop) {
				document.addEventListener("click", handleClickOutside, true);
				return () => {
					document.removeEventListener("click", handleClickOutside, true);
				};
			}
		}, [setDrop]);

		useEffect(() => {
			setFilteredOptions(options || []);
		}, [options]);
		useEffect(() => {
			if (Object.keys(selectedOption)?.length > 0) {
				setValue(selectedOption[optionToShow]);
			}
		}, [optionToShow, selectedOption]);
		const handleClickOutside = ({ target }) => {
			if (setDrop) if (!searchRef.current.contains(target)) setDrop(false);
		};
		const handleInputValue = (opt) => {
			setSelected(opt);
			setDrop(false);
			onChange(opt);
		};
		const search = (value) => {
			setFilteredOptions(
				options.filter((info) =>
					info[optionToShow].toLowerCase().match(value.toLowerCase()),
				),
			);
		};
		return (
			<s.InputContainer
				onSubmit={(e) => {
					e.preventDefault();
					search(value);
				}}
				ref={searchRef}
				{...rest}>
				<input
					required
					value={readOnly ? readOnlyInputValue || "" : value || ""}
					disabled={false}
					placeholder={placeholder}
					onClick={() => setDrop(true)}
					readOnly={readOnly}
					onChange={(e) => {
						if (!drop) {
							setDrop(true);
						}
						if (e.target.value?.length <= 0) {
							setDrop(false);
							search(value);
						}
						setValue(e.target.value);
					}}
				/>
				<button
					type="submit"
					style={readOnly || loading ? { background: "#d0d0d0" } : {}}>
					{loading ? <Loader size={20} /> : <img alt="input-icon" src={icon} />}
				</button>
				{drop && filteredOptions?.length > 0 && (
					<s.Drop elements={filteredOptions?.length}>
						<Scrollbars
							renderTrackHorizontal={() => <div />}
							style={{ padding: 0 }}>
							<div
								className="item"
								onClick={() =>
									handleInputValue({
										id: "all",
										[optionToShow]: "Todas opções",
									})
								}>
								Todas opções
							</div>
							{filteredOptions.map((opt) => (
								<div
									key={opt.id || opt.codigoRegional || opt.ID || opt.docId}
									className="item"
									onClick={() => handleInputValue(opt)}>
									{opt[optionToShow]}
								</div>
							))}
						</Scrollbars>
					</s.Drop>
				)}
			</s.InputContainer>
		);
	},
);
export default RelatorioModal;
