import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: ${({ visible }) => (visible ? "flex" : "none")};
  flex-wrap: wrap;
  justify-content: center;
  section {
    margin: 15px 10px;
    width: 100%;
    height: 460px;
    background: #3f3f3f;
    box-shadow: -10px -10px 30px rgba(0, 0, 0, 0.08),
      10px 10px 30px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    :first-child {
      width: 460px;
      display: flex;
      flex-direction: column;
    }
    :last-child {
      article {
        margin: 10px;
        display: flex;
        flex-wrap: wrap;
      }
      max-width: 1000px;
    }
  }
`;

export const Drag = styled.div`
  background: #ffffff;
  border-radius: 4px;
  width: 390px;
  height: 345px;
  margin: auto auto 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  p {
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    color: #989898;
  }
  div {
    border: 2px dashed #b6b6b6;
    border-radius: 4px;
    width: 170px;
    height: 145px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    img {
      width: 60px;
      height: 55px;
    }
    h1 {
      display: absolute;
      color: white;
    }
  }
`;
export const ButtonContainer = styled.div`
  background: #ffffff;
  border-radius: 4px;
  width: 390px;
  height: 60px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Title = styled.h1`
  font-weight: 600;
  font-size: 14px;
  color: #989898;
  position: absolute;
  margin-top: -25px;
`;
export const Card = styled.div`
  margin: 10px;
  display: flex;
  justify-content: flex-end;
  width: 120px;
  height: 115px;
  :hover {
    img {
      :last-child {
        display: flex;
        position: absolute;
        cursor: pointer;
      }
    }
  }
  img {
    :first-child {
      width: 120px;
      height: 115px;
      background: white;
      border-radius: 4px;
    }
    :last-child {
      display: none;
    }
  }
`;
