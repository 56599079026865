import React, { useState, useEffect, useRef } from "react";
import Modal from "react-modal";
import { Button, Loader } from "../../../../components";
import * as s from "./styled-produtos-tab";
import { managerService, produtosService } from "../../../../services";
import { useSelector } from "react-redux";
import { icons } from "../../../../assets";
import Scrollbars from "react-custom-scrollbars";

const ProdutoModal = ({ isOpen, onRequestClose, next, liveId, prod }) => {
	const live = useSelector((state) => state.live);
	const [grades, setGrades] = useState([]);
	const [searching, setSearching] = useState(false);
	const [searching2, setSearching2] = useState(false); //grades
	const [produto, setProduto] = useState(prod);
	const [options, setOptions] = useState();
	const [drop, setDrop] = useState(false);

	useEffect(() => {
		if ((live && !live.grades) || (live.grades && live.grades.length === 0))
			produtosService.initGrades();
	}, []);

	useEffect(() => {
		if (
			live &&
			live.grades &&
			live.grades.length > 0 &&
			prod &&
			prod.GRADE_DISPONIVEL
		) {
			let prodGrades = [];
			live.grades.forEach((opt) => {
				prod.GRADE_DISPONIVEL.split(",").forEach((id) => {
					if (opt.id === id) {
						prodGrades.push(opt);
					}
				});
			});
			setGrades(prodGrades);
		}
	}, [live, prod]);

	const search = (v) => {
		if (v.split("-").length === 4) {
			setSearching(true);
			managerService
				.pesquisarProduto(liveId, v)
				.then((data) => {
					if (data) setProduto({ ...produto, ...data });
					else {
						alert("Não foi encontrado produto com esse código!");
						setProduto();
					}
					setSearching(false);
				})
				.catch((e) => {
					setSearching(false);
					alert(e);
				});
		}
	};

	const searchGrade = (value) => {
		if (value) {
			setSearching2(true);
			let v = value.toLowerCase();
			const filteredData = live.grades.filter(
				(el) =>
					v.includes(el.id.toLowerCase()) ||
					el.id.toLowerCase().includes(v) ||
					(v.split(" ")[1] && el.id.toLowerCase().includes(v.split(" ")[1])),
			);
			setOptions(filteredData);
			setDrop(true);
			setSearching2(false);
		}
	};

	return (
		<Modal
			isOpen={isOpen ? true : false}
			onRequestClose={onRequestClose}
			style={s.customStyles}>
			<s.Header>
				{isOpen === 1 ? `Produto principal` : `Variações do produto`}
			</s.Header>
			<s.ModalContent>
				<section>
					<label>Digite ou cole o código do produto</label>
					<SearchInput
						icon={icons.play}
						placeholder={"Linha-Referência-Cabedal-Cor"}
						search={search}
						searching={searching}
					/>
					<label style={{ marginTop: "50px" }}>
						Você pode adicionar um novo produto
					</label>
					<s.Button className="animated-linear" disabled>
						Adicionar lançamento
					</s.Button>
				</section>
				<section>
					<label>Produto</label>
					<div
						className={`img-container-produto${
							produto && produto.IMAGEM_GRANDE ? ` bg-white` : ` bg-gray`
						}`}>
						{produto && produto.IMAGEM_PEQUENA && (
							<img alt="produto" src={produto.IMAGEM_GRANDE} />
						)}
					</div>
				</section>
				<section>
					<label>Descrição do produto</label>
					<textarea disabled value={produto ? produto.DESCRICAO_PRODUTO : ""} />
					<s.Valores style={{ marginTop: "30px" }} disabled>
						<div>
							<label>Vitrine</label>
							<span>
								<label>R$</label>
								<input
									type="number"
									placeholder={"00,00"}
									value={produto ? produto.PRECO_VITRINE : ""}
									onChange={({ target: t }) => {
										if (t.value.length <= 7) {
											setProduto({ ...produto, PRECO_VITRINE: t.value });
										}
									}}
								/>
							</span>
						</div>
						<div>
							<label>Venda</label>
							<span>
								<label>R$</label>
								<input
									type="number"
									placeholder={"00,00"}
									value={produto ? produto.PRECO_VENDA : ""}
									onChange={({ target: t }) => {
										if (t.value.length <= 7) {
											setProduto({ ...produto, PRECO_VENDA: t.value });
										}
									}}
								/>
							</span>
						</div>
					</s.Valores>
				</section>
				<s.Footer>
					<section>
						<label>Grades disponíveis</label>
						<SearchInput
							icon={icons.search}
							drop={drop}
							setDrop={setDrop}
							options={options && options.filter((el) => !grades.includes(el))}
							search={searchGrade}
							searching={searching2}
							addGrade={(v) => setGrades([...grades, v])}
						/>
					</section>
					<section>
						<label>Grade(s) selecionada(s) para este produto</label>
						<Scrollbars
							style={{
								height: "172px",
								border: "1px solid #e94e5c",
								borderRadius: "5px",
							}}>
							<s.Grades>
								{grades.map((n) => (
									<span key={n.id}>
										GRADE {n.id}
										<a onClick={() => setGrades(grades.filter((e) => e !== n))}>
											x
										</a>
									</span>
								))}
							</s.Grades>
						</Scrollbars>
					</section>
				</s.Footer>
			</s.ModalContent>
			<Button
				label="Salvar"
				disabled={
					(isOpen === 2 && !produto?.DESCRICAO_PRODUTO) ||
					(isOpen === 1 && !produto) ||
					grades.length === 0
				}
				onClick={() =>
					next({
						...produto,
						GRADE_DISPONIVEL: grades.map((g) => g.id).join(","),
					})
				}
				style={{ width: "270px", height: "50px", margin: "0 auto 30px auto" }}
			/>
		</Modal>
	);
};

const SearchInput = ({
	placeholder,
	search,
	icon,
	searching,
	drop,
	setDrop,
	options,
	addGrade,
}) => {
	const [changing, setChanging] = useState();
	const [value, setValue] = useState("");
	const searchRef = useRef("searchRef");

	useEffect(() => {
		if (setDrop) {
			document.addEventListener("click", handleClickOutside, true);
			return () => {
				document.removeEventListener("click", handleClickOutside, true);
			};
		}
	}, [setDrop]);

	const handleClickOutside = ({ target }) => {
		if (setDrop) if (!searchRef.current.contains(target)) setDrop(false);
	};

	return (
		<s.InputContainer
			onSubmit={(e) => {
				e.preventDefault();
				if (changing) clearInterval(changing);
				search(value);
			}}
			ref={searchRef}>
			<input
				required
				disabled={searching}
				placeholder={placeholder}
				value={value}
				onChange={({ target: t }) => {
					if (changing) clearInterval(changing);
					if (drop) setDrop(false);
					setChanging(
						setTimeout(() => {
							search(t.value);
						}, 2000),
					);
					setValue(t.value);
				}}
			/>
			{icon && (
				<button type="submit" disabled={searching}>
					{searching ? (
						<Loader size={20} color="white" />
					) : (
						<img alt="input-icon" src={icon} />
					)}
				</button>
			)}
			{drop && options && (
				<s.Drop elements={options.length || 2}>
					<Scrollbars
						renderTrackHorizontal={() => <div />}
						style={{ padding: 0 }}>
						{options && options.length > 0 ? (
							options.map((opt) => (
								<div
									key={opt.id}
									className="item"
									onClick={() => addGrade(opt)}>
									Grade {opt.id}
								</div>
							))
						) : (
							<legend>Nenhum resultado encontrado</legend>
						)}
					</Scrollbars>
				</s.Drop>
			)}
		</s.InputContainer>
	);
};

export default ProdutoModal;
