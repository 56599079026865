import React, { useState, useRef, useEffect } from "react";
import Modal from "react-modal";
import { Button, Loader } from "../../../../components";
import * as s from "./styled-combos-tab";
import { managerService } from "../../../../services";
import { icons, images } from "../../../../assets";
import Scrollbars from "react-custom-scrollbars";

const CombosModal = ({
  isOpen,
  onRequestClose,
  next,
  i,
  liveId,
  produtosCombo,
}) => {
  const [searching, setSearching] = useState(false);
  const [combo, setCombo] = useState(
    produtosCombo && produtosCombo.PRODUTOS && produtosCombo.PRODUTOS.length > 0
      ? produtosCombo.PRODUTOS
      : []
  );
  const [prods, setProds] = useState([]);
  const [originalProds, setOriginalProds] = useState([]);
  const [cards, setCards] = useState([]);
  const [card, setCard] = useState();

  useEffect(() => {
    if (!cards || cards.length === 0)
      setCards(JSON.parse(localStorage.getItem("CARDS")));
  });

  useEffect(() => {
    managerService
      .buscarProdutosPrincipais(liveId)
      .then((data) => {
        if (data) {
          setProds(data);
          setOriginalProds(data);
        } else {
          alert("Não foram encontrados produtos!");
          setProds([]);
        }
      })
      .catch((e) => alert(e));
  }, []);

  const search = (v) => {
    if (v.split("-").length === 4) {
      setSearching(true);
      setProds(
        prods.filter(
          (p) =>
            v.includes(p.LINHA) ||
            v.includes(p.REFERENCIA) ||
            v.includes(p.CABEDAL) ||
            v.includes(p.COR)
        )
      );
      setSearching(false);
    } else {
      setProds(originalProds);
      setSearching(false);
    }
  };
  return (
    <Modal
      isOpen={isOpen ? true : false}
      onRequestClose={onRequestClose}
      style={s.customStyles}
    >
      <s.Header>
        {isOpen === 1 ? `Biblioteca de cards` : `Produtos cadastrados`}
      </s.Header>
      <s.ModalContent>
        {isOpen === 1 ? (
          <Scrollbars
            style={{
              height: "500px",
            }}
          >
            <s.Cards selected={card}>
              {cards &&
                cards.map((c, n) => (
                  <img
                    key={c.id}
                    alt={c.nameCard}
                    className={`animated-linear ${
                      card === c ? `selected` : ``
                    }`}
                    src={c.url || images.default}
                    onClick={() => setCard(card === c ? false : c)}
                  />
                ))}
            </s.Cards>
          </Scrollbars>
        ) : (
          <>
            <s.Prods>
              <span>
                00{i + 1} <p>Combo</p>
              </span>
              <div>
                <label>Digite ou cole o código do produto</label>
                <SearchInput
                  icon={icons.play}
                  placeholder={"Linha-Referência-Cabedal-Cor"}
                  search={search}
                  searching={searching}
                />
              </div>
              <div className="prods">
                <label className="title-prods">
                  Produtos do combo selecionados
                </label>
                <section>
                  {combo &&
                    combo.map((c) => (
                      <div key={c.id_doc}>
                        <img
                          alt="prod-selected"
                          src={c.IMAGEM_GRANDE || images.default}
                        />
                        <img
                          alt="prod-delete"
                          className="trash"
                          src={icons.trash}
                          onClick={() =>
                            setCombo(combo.filter((co) => co !== c))
                          }
                        />
                      </div>
                    ))}
                </section>
              </div>
            </s.Prods>
            <Scrollbars
              style={{
                height: "400px",
              }}
            >
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {prods &&
                  prods.map((prod, i) => (
                    <s.Card
                      key={"list-" + prod.id_doc}
                      onClick={() => {
                        if (combo.includes(prod))
                          setCombo(combo.filter((c) => c !== prod));
                        else if (combo.length < 3) setCombo([...combo, prod]);
                      }}
                      selected={combo && combo.includes(prod) ? true : false}
                    >
                      <img
                        alt="card-prod-list"
                        src={prod.IMAGEM_GRANDE || images.default}
                      />
                      <label>{`${prod.LINHA}-${prod.REFERENCIA}-${prod.CABEDAL}-${prod.COR}`}</label>
                    </s.Card>
                  ))}
              </div>
            </Scrollbars>
          </>
        )}
      </s.ModalContent>
      <Button
        label="Salvar"
        disabled={
          isOpen === 1 ? (card ? false : true) : !combo || combo.length < 2
        }
        onClick={() => {
          if (isOpen === 1) next(card.url);
          else next(combo);
        }}
        style={{ width: "270px", height: "50px", margin: "0 auto 30px auto" }}
      />
    </Modal>
  );
};

const SearchInput = ({ placeholder, search, icon, searching }) => {
  const [changing, setChanging] = useState();
  const [value, setValue] = useState("");
  const searchRef = useRef("searchRef");

  return (
    <s.InputContainer
      onSubmit={(e) => {
        e.preventDefault();
        if (changing) clearInterval(changing);
        search(value);
      }}
      ref={searchRef}
    >
      <input
        required
        disabled={searching}
        placeholder={placeholder}
        value={value}
        onChange={({ target: t }) => {
          if (changing) clearInterval(changing);
          setChanging(
            setTimeout(() => {
              search(t.value);
            }, 2000)
          );
          setValue(t.value);
        }}
      />
      {icon && (
        <button type="submit" disabled={searching}>
          {searching ? (
            <Loader size={20} color="white" />
          ) : (
            <img alt="input-icon" src={icon} />
          )}
        </button>
      )}
    </s.InputContainer>
  );
};

export default CombosModal;
