import React, { useState } from "react";
import * as s from "./styled-lives";
import { images } from "../../assets";
import { withRouter } from "react-router-dom";
import { getStatus, orderLives } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import { usuariosActions, liveActions } from "../../store";
import { managerService } from "../../services";

const Lives = ({ history }) => {
	const dispatch = useDispatch();
	const usuario = useSelector((state) => state.usuario.usuario);
	const lives = usuario && usuario.lives ? usuario.lives : [];
	const [render, setRender] = useState(); //Intervalo de 1s para atualizar o timer das lives

	const getTimeLive = (dataInicio) => {
		var now = new Date().getTime();
		var countDownDate = new Date(dataInicio).getTime();
		if (countDownDate <= now) {
			return false;
		} else {
			setInterval(() => setRender(render + "1"), 1000);
			var distance = countDownDate - now;
			var days = Math.floor(distance / (1000 * 60 * 60 * 24));
			var hours = Math.floor(
				(distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
			);
			var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
			var seconds = Math.floor((distance % (1000 * 60)) / 1000);
			return days + "d " + hours + "h " + minutes + "m " + seconds + "s ";
		}
	};

	const selectLive = (id, status) => {
		localStorage.setItem("KEY", id);
		managerService
			.getLiveInfo(id)
			.then((data) => {
				dispatch(liveActions.setFormasPagamento(data.condicoesPagamento));
				dispatch(usuariosActions.setLiveUsuario(data));
				if (status === "Agendada") history.push("/timer");
				else history.push("/");
			})
			.catch((e) => alert(e));
	};

	return (
		<s.Container className="fade-in">
			<s.Lives>
				<s.Logo src={images.logo} />
				<div
					style={{
						display: "flex",
						flexWrap: "wrap",
						justifyContent: "center",
						rowGap: "25px",
						columnGap: "20px",
					}}>
					{orderLives(lives).map(
						({ dataInicio, dataFim, id, thumbnail, nome, publicado }) => (
							<s.LiveContainer
								finalizada={
									getStatus(publicado, dataInicio, dataFim) === "Finalizada"
								}
								display={getTimeLive(dataInicio)}
								onClick={() => {
									if (
										getStatus(publicado, dataInicio, dataFim) !== "Finalizada"
									)
										selectLive(id, getStatus(publicado, dataInicio, dataFim));
								}}>
								<h1>{getStatus(publicado, dataInicio, dataFim)}</h1>
								<s.Live key={id}>
									<div
										className="image-card"
										id={`live-${id}-thumbnail`}
										// style={
										//   thumbnail
										//     ? { backgroundImage: `url(${thumbnail})` }
										//     : { backgroundImage: `url(${images.default})` }
										// }
									>
										<img
											src={thumbnail}
											alt="thumbnail"
											onError={() => {
												const thumb = document.querySelector(
													`#live-${id}-thumbnail img`,
												);
												thumb.src = images.default;
												thumb.style.width = "50%";
											}}
										/>
									</div>
									<label title={nome}>{nome}</label>
								</s.Live>
								<h2>{getTimeLive(dataInicio)}</h2>
							</s.LiveContainer>
						),
					)}
				</div>
			</s.Lives>
		</s.Container>
	);
};

export default withRouter(Lives);
