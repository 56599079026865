import styled from "styled-components";

export const Input = styled.input`
  width: calc(100% - 18px);
  height: 34px;
  background: white;
  box-shadow: -1px -1px 3px rgba(0, 0, 0, 0.1), 1px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 0 9px;
  color: #383838;
  font-weight: 600;
  ::placeholder {
    color: #989898;
  }
  :-ms-input-placeholder {
    color: #989898;
  }
  ::-ms-input-placeholder {
    color: #989898;
  }
  font-size: 14px;
  :disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }
`;
export const TextArea = styled.textarea`
  width: calc(100% - 18px);
  flex: 1;
  background: white;
  box-shadow: -1px -1px 3px rgba(0, 0, 0, 0.1), 1px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 0 9px;
  color: #383838;
  font-weight: 600;
  font-size: 14px;
  :disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }
  ::placeholder {
    color: #989898;
  }
  :-ms-input-placeholder {
    color: #989898;
  }
  ::-ms-input-placeholder {
    color: #989898;
  }
`;
