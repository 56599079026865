import styled from "styled-components";

export const Button = styled.button`
	height: 40px;
	border-radius: 4px;
	border: 2px solid #d0d0d0;
	font-weight: 600;
	font-size: 14px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;
	color: #c5c5c5;
	cursor: pointer;
	background: white;
	outline: none;
	:hover {
		background: #e9505e;
		color: white;
		border: 2px solid #e9505e;
	}
	:disabled {
		background: white;
		color: #c5c5c5;
		border: 2px solid #d0d0d0;
		cursor: not-allowed;
	}
`;

export const Header = styled.span`
	max-width: 300px;
	padding: 5px;
	background: #e9505e;
	border-radius: 4px 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 14px;
	word-break: break-all;
	color: #ffffff;
`;

export const ModalContent = styled.div`
	position: relative;
	display: flex;
	gap: 30px;
	margin: 20px;
	min-width: 500px;

	.middle-line {
		width: 0px;
		height: 270px;
		border: 3px dashed #d0d0d0;
	}
`;

export const SectionPDF = styled.section`
	position: relative;
	width: 50%;
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	gap: 2rem;

	label {
		font-weight: 600;
		font-size: 14px;
		color: #434343;
	}

	.inputs-section {
		display: flex;
		flex-direction: column;
		gap: 10px;

		> div {
			height: 61px;
		}

		div:nth-child(1) {
			z-index: 10;
		}
		div:nth-child(2) {
			z-index: 9;
		}
		div:nth-child(3) {
			z-index: 8;
		}
	}
`;

export const SectionExcel = styled.section`
	position: relative;
	width: 50%;
	display: flex;
	align-items: flex-start;
	flex-direction: column;

	h2 {
		font-weight: 600;
		font-size: 18px;
		color: #434343;
	}

	> span {
		font-weight: 600;
		font-size: 10px;
		color: #434343;
		margin-top: 10px;
	}

	.checkbox-section {
		display: flex;
		flex-direction: column;
		gap: 10px;
		margin-bottom: 2rem;

		> div {
			display: flex;
			align-items: center;
			height: 61px;
		}
	}
`;

export const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		zIndex: 99,
		border: "none",
		borderRadius: "5px",
		backgroundColor: "white",
		padding: 0,
		overflow: "visible",
		maxWidth: "1010px",
	},
	overlay: {
		zIndex: 99,
		backgroundColor: "rgba(45, 37, 40, 0.9)",
	},
};

// INPUT
export const InputContainer = styled.form`
	display: flex;
	input {
		width: calc(100% - 40px);
		height: 40px;
		padding: 0 10px;
		background: #f7f7f7;
		border-radius: 4px;
	}
	button {
		position: relative;
		border: none;
		outline: none;
		cursor: pointer;
		height: 40px;
		width: 40px;
		background: #e94e5c;
		border-radius: 0px 5px 5px 0px;
		display: flex;
		align-items: center;
		justify-content: center;
		img {
			height: 15px;
			width: 15px;
		}
		:disabled {
			opacity: 0.8;
			cursor: not-allowed;
		}
	}
`;

export const Drop = styled.div`
	width: 230px;
	max-height: 140px;
	/* height: 140px; */
	height: ${({ elements }) => (elements + 1) * 35}px;
	position: absolute;
	box-shadow: -1px -1px 3px rgba(0, 0, 0, 0.1), 1px 1px 3px rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	margin: 53px 0 0 1px;
	background: white;
	.item {
		cursor: pointer;
		font-size: 14px;
		line-height: 25px;
		padding: 5px 10px;
		color: gray;

		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 1; /* number of lines to show */
		-webkit-box-orient: vertical;

		:hover {
			background: #f4f4f4;
		}
	}
	legend {
		color: gray;
		text-align: center;
		margin-top: 20px;
	}
`;
