import React from "react";
import { Font, Text, Page, View, StyleSheet } from "@react-pdf/renderer";
import SideLogo from "../sideLogo/side-logo";
import { formatValor } from "../../../utils";

Font.register({
	family: "Nunito",
	fontStyle: "normal",
	fonts: [
		{
			src: "https://fonts.gstatic.com/s/nunito/v16/XRXW3I6Li01BKofAksCkZg.ttf",
			fontWeight: 800,
		},
		{
			src: "https://fonts.gstatic.com/s/nunito/v16/XRXW3I6Li01BKofAjsOkZg.ttf",
			fontWeight: 700,
		},
		{
			src: "https://fonts.gstatic.com/s/nunito/v16/XRXV3I6Li01BKof4Mg.ttf",
			fontWeight: 400,
		},
	],
});

const colors = {
	grey: "#fefefe",
	darkGrey: "#d0cece",
	yellow: "#f8f530",
	blue: "#dcebf7",
	darkBlue: "#59a4d1",
	salmao: "#fee4d6",
};

// Create styles
const s = StyleSheet.create({
	page: {
		backgroundColor: "#fff",
		width: "100%",
		height: "100%",
	},
	SummaryMainContent: {
		padding: "2%",
		width: "100%",
		height: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
		alignSelf: "center",
	},
	headerContainer: {
		width: "100%",
		backgroundColor: colors.yellow,
	},
	headerText: {
		fontSize: 12,
		textAlign: "center",
		color: "#000",
		lineHeight: 1.2,
		fontFamily: "Nunito",
		fontWeight: 800,
	},
	tablesContainer: {
		width: "100%",
		alignSelf: "center",
		display: "flex",
		alignItems: "center",
		flexDirection: "row",
		justifyContent: "space-between",
	},
	// TABLE
	table: {
		width: "45%",
	},
	secondTable: {
		width: "50%",
	},
	headerTableText: {
		textAlign: "center",
		fontSize: "15px",
		fontFamily: "Nunito",
		fontWeight: 800,
	},
	// content table
	tableContainer: {
		display: "table",
		width: "auto",
		borderLeft: "1px solid #000",
		borderTop: "1px solid #000",
	},
	row: {
		display: "flex",
		flexDirection: "row",
	},
	// content table end
	rightAlign: {
		textAlign: "right",
	},
	headerCell: {
		width: "100%",
		height: "33px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexDirection: "column",
		fontSize: "12px",
		borderRight: "1px solid #000",
		borderBottom: "1px solid #000",
	},
	halfHeader: {
		width: "100%",
		// height: '100%',
		borderTop: "1px solid #000",
		display: "flex",
		flexDirection: "row",
		alignSelf: "center",
		alignItems: "center",
		justifyContent: "center",
	},
	halfCell: {
		width: "100%",
		textAlign: "center",
	},
	contentCell: {
		width: "100%",
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		fontSize: "12px",
		borderRight: "1px solid #000",
		borderBottom: "1px solid #000",
	},
	contentRight: {
		justifyContent: "flex-end",
	},
	contentLeft: {
		justifyContent: "flex-start",
	},
	darkGrey: {
		backgroundColor: colors.darkGrey,
	},
	grey: {
		backgroundColor: colors.grey,
	},
	blue: {
		backgroundColor: colors.blue,
	},
	salmao: {
		backgroundColor: colors.salmao,
	},
	boldTexts: {
		color: "#000",
		fontFamily: "Nunito",
		fontWeight: 800,
	},
});

const PageHome = ({ data, liveDate, totalData }) => {
	return (
		<Page size="A4" orientation="landscape" style={s.page}>
			<SideLogo />

			<View style={s.SummaryMainContent}>
				<View style={{ width: "100%" }}>
					<View style={s.headerContainer}>
						<Text style={s.headerText}>Resumo Live Show {liveDate}</Text>
					</View>
					<View style={s.tablesContainer}>
						{/* TABLE 1 */}
						<View style={s.table}>
							<Text style={s.headerTableText}>Pares</Text>
							{/* TABLE CONTENT */}
							<View style={s.tableContainer}>
								<View style={[s.row, s.boldTexts]}>
									<View style={[s.headerCell, s.darkGrey, { width: "75%" }]}>
										<Text>Regional</Text>
									</View>
									<View style={[s.headerCell, s.darkGrey, { width: "75%" }]}>
										<Text>Acessos Live</Text>
									</View>
									<View style={[s.headerCell, s.blue]}>
										<Text>Favoritos</Text>
										<View style={s.halfHeader}>
											<Text
												style={[{ borderRight: "1px solid #000" }, s.halfCell]}>
												Clientes
											</Text>
											<Text style={[s.halfCell]}>Pares</Text>
										</View>
									</View>
									<View style={[s.headerCell, s.salmao]}>
										<Text>Pedidos</Text>
										<View style={s.halfHeader}>
											<Text
												style={[{ borderRight: "1px solid #000" }, s.halfCell]}>
												Clientes
											</Text>
											<Text style={s.halfCell}>Pares</Text>
										</View>
									</View>
								</View>
								{data?.map((row) => (
									<View style={s.row} key={`summary-${row?.regional}`}>
										<View
											style={[
												s.contentCell,
												s.grey,
												s.contentLeft,
												{ width: "75%", paddingLeft: "8px" },
											]}>
											<Text>{row?.regional || `-`}</Text>
										</View>
										<View
											style={[
												s.contentCell,
												s.grey,
												s.contentRight,
												{ width: "75%", paddingRight: "8px" },
											]}>
											<Text>{row?.viewersAccess || `-`}</Text>
										</View>
										<View style={[s.contentCell, s.blue]}>
											<View
												style={[s.halfCell, { borderRight: "1px solid #000" }]}>
												<Text
													style={{
														alignSelf: "flex-end",
														paddingRight: "8px",
													}}>
													{row?.favoritosClientes?.length || `-`}
												</Text>
											</View>
											<View style={[s.halfCell]}>
												<Text
													style={{
														alignSelf: "flex-end",
														paddingRight: "8px",
													}}>
													{row?.favoritosPares || `-`}
												</Text>
											</View>
										</View>
										<View style={[s.contentCell, s.salmao]}>
											<View
												style={[
													s.halfCell,
													s.contentRight,
													{ borderRight: "1px solid #000" },
												]}>
												<Text
													style={{
														alignSelf: "flex-end",
														paddingRight: "8px",
													}}>
													{row?.vendidoClientes?.length || `-`}
												</Text>
											</View>
											<View style={[s.halfCell, s.contentRight]}>
												<Text
													style={{
														alignSelf: "flex-end",
														paddingRight: "8px",
													}}>
													{row?.vendidoQuantidade || `-`}
												</Text>
											</View>
										</View>
									</View>
								))}
								{/* RESULTS */}
								<View style={[s.row, s.boldTexts]}>
									<View style={[s.contentCell, s.darkGrey, { width: "75%" }]}>
										<Text>Total Geral</Text>
									</View>
									<View
										style={[
											s.contentCell,
											s.darkGrey,
											s.contentRight,
											{ width: "75%", paddingRight: "8px" },
										]}>
										<Text>{totalData?.totalAcessos || "-"}</Text>
									</View>
									<View style={[s.contentCell, s.darkGrey]}>
										<View
											style={[s.halfCell, { borderRight: "1px solid #000" }]}>
											<Text
												style={{ alignSelf: "flex-end", paddingRight: "8px" }}>
												{totalData?.totalClientesFavoritos || "-"}
											</Text>
										</View>
										<View style={s.halfCell}>
											<Text
												style={{ alignSelf: "flex-end", paddingRight: "8px" }}>
												{totalData?.totalQuantidadeFavoritos || "-"}
											</Text>
										</View>
									</View>
									<View style={[s.contentCell, s.darkGrey]}>
										<View
											style={[s.halfCell, { borderRight: "1px solid #000" }]}>
											<Text
												style={{ alignSelf: "flex-end", paddingRight: "8px" }}>
												{totalData?.totalClientesPedidos || "-"}
											</Text>
										</View>
										<View style={s.halfCell}>
											<Text
												style={{ alignSelf: "flex-end", paddingRight: "8px" }}>
												{totalData?.totalQuantidadePedidos || "-"}
											</Text>
										</View>
									</View>
								</View>
							</View>
							{/* TABLE CONTENT */}
						</View>
						{/* TABLE 2 */}
						<View style={s.secondTable}>
							<Text style={s.headerTableText}>Valores</Text>
							{/* TABLE CONTENT */}
							<View style={s.tableContainer}>
								<View style={[s.row, s.boldTexts]}>
									<View style={[s.headerCell, s.darkGrey, { width: "50%" }]}>
										<Text>Regional</Text>
									</View>
									<View style={[s.headerCell, s.darkGrey, { width: "60%" }]}>
										<Text>Acessos Live</Text>
									</View>
									<View style={[s.headerCell, s.blue]}>
										<Text>Favoritos</Text>
										<View style={s.halfHeader}>
											<Text
												style={[{ borderRight: "1px solid #000" }, s.halfCell]}>
												Clientes
											</Text>
											<Text style={s.halfCell}>Valores</Text>
										</View>
									</View>
									<View style={[s.headerCell, s.salmao]}>
										<Text>Pedidos</Text>
										<View style={s.halfHeader}>
											<Text
												style={[{ borderRight: "1px solid #000" }, s.halfCell]}>
												Clientes
											</Text>
											<Text style={s.halfCell}>Valores</Text>
										</View>
									</View>
								</View>
								{data?.map((row) => (
									<View style={s.row} key={`summary2-${row?.regional}`}>
										<View
											style={[
												s.contentCell,
												s.grey,
												s.contentLeft,
												{ width: "50%", paddingLeft: "8px" },
											]}>
											<Text>{row?.regional}</Text>
										</View>
										<View
											style={[
												s.contentCell,
												s.grey,
												s.contentRight,
												{ width: "60%", paddingRight: "8px" },
											]}>
											<Text>{row?.viewersAccess || `-`}</Text>
										</View>
										<View style={[s.contentCell, s.blue]}>
											<View
												style={[s.halfCell, { borderRight: "1px solid #000" }]}>
												<Text
													style={{
														alignSelf: "flex-end",
														paddingRight: "8px",
													}}>
													{row?.favoritosClientes?.length || `-`}
												</Text>
											</View>
											<View style={[s.halfCell]}>
												<Text
													style={{
														alignSelf: "flex-end",
														paddingRight: "8px",
													}}>
													{formatValor(row?.favoritosValorTotal || 0, true)}
												</Text>
											</View>
										</View>
										<View style={[s.contentCell, s.salmao]}>
											<View
												style={[
													s.halfCell,
													s.contentRight,
													{ borderRight: "1px solid #000" },
												]}>
												<Text
													style={{
														alignSelf: "flex-end",
														paddingRight: "8px",
													}}>
													{row?.vendidoClientes?.length || `-`}
												</Text>
											</View>
											<View style={[s.halfCell, s.contentRight]}>
												<Text
													style={{
														alignSelf: "flex-end",
														paddingRight: "8px",
													}}>
													{formatValor(row?.vendidoValores || 0, true)}
												</Text>
											</View>
										</View>
									</View>
								))}
								{/* RESULTS */}
								<View style={[s.row, s.boldTexts]}>
									<View style={[s.contentCell, s.darkGrey, { width: "50%" }]}>
										<Text>Total Geral</Text>
									</View>
									<View
										style={[
											s.contentCell,
											s.darkGrey,
											s.contentRight,
											{ width: "60%", paddingRight: "8px" },
										]}>
										<Text>{totalData?.totalAcessos}</Text>
									</View>
									<View style={[s.contentCell, s.darkGrey]}>
										<View
											style={[s.halfCell, { borderRight: "1px solid #000" }]}>
											<Text
												style={{ alignSelf: "flex-end", paddingRight: "8px" }}>
												{totalData?.totalClientesFavoritos}
											</Text>
										</View>
										<View style={s.halfCell}>
											<Text
												style={{ alignSelf: "flex-end", paddingRight: "8px" }}>
												{formatValor(totalData?.totalValoresFavoritos, true)}
											</Text>
										</View>
									</View>
									<View style={[s.contentCell, s.darkGrey]}>
										<View
											style={[s.halfCell, { borderRight: "1px solid #000" }]}>
											<Text
												style={{ alignSelf: "flex-end", paddingRight: "8px" }}>
												{totalData?.totalClientesPedidos}
											</Text>
										</View>
										<View style={s.halfCell}>
											<Text
												style={{ alignSelf: "flex-end", paddingRight: "8px" }}>
												{formatValor(totalData?.totalValoresPedidos, true)}
											</Text>
										</View>
									</View>
								</View>
							</View>

							{/* TABLE CONTENT */}
						</View>
					</View>
				</View>
			</View>
		</Page>
	);
};

export default PageHome;
