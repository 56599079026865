import styled from "styled-components";

export const Container = styled.div`
  background: white;
  @media (min-width: 1450px) {
    margin: 0 20px 20px 0px;
    max-width: 400px;
    min-width: 400px;
    border-radius: 4px;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4), -2px -2px 6px rgba(0, 0, 0, 0.4);
  }
  @media (max-width: 1450px) {
    flex: 1;
    min-height: 400px;
    min-width: 300px;
  }
`;
export const Header = styled.div`
  width: 100%;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #edcfcb;
  border-radius: 4px;
  font-weight: 900;
  font-size: 16px;
  color: #ffffff;
`;
export const Status = styled.div`
  margin: 0 10px;
`;
export const List = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(170px, 2fr));
`;
export const Produto = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 15px;
  margin: 20px;
  cursor: pointer;
  background: #ffffff;
  img {
    width: 110px;
    height: 70px;
    margin: auto;
  }
  .ref-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .ref {
      font-size: 12px;
      font-weight: bold;
      cursor: pointer;
    }
  }
  :hover {
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.05),
      -2px -2px 6px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
  }
  .hearth {
    position: absolute;
    width: 18px;
    height: 16px;
  }
  .pulse-heart-prod {
    animation: pulse-heart-prod 1s 1;
    @keyframes pulse-heart-prod {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(2);
      }
      100% {
        transform: scale(1);
      }
    }
  }
`;
