import { createTypes } from 'reduxsauce';
import { subscriptionTypes } from '.';
import PubNub from 'pubnub';

export const usuarioTypes = createTypes(
  `
  SET_USUARIO
  SET_LIVE_USUARIO
  CHANGE_DATA_LIVE_ARRAY
`,
  { prefix: 'USUARIOS/' }
);

const setUsuario = usuario => {
  return dispatch => {
    if (usuario) {
      dispatch({
        type: subscriptionTypes.INITIALIZE,
        pubnub: new PubNub({
          publishKey: 'pub-c-5db2eefa-1ed6-4346-8706-160b727c6567',
          subscribeKey: 'sub-c-9e56610e-dcd7-11ea-9cea-12703a486eb8',
          uuid: usuario.codUsuario,
          heartbeatInterval: 30
        })
      });
    } else {
      dispatch({
        type: subscriptionTypes.INITIALIZE,
        pubnub: null
      });
    }
    dispatch({ type: usuarioTypes.SET_USUARIO, usuario });
  };
};

const setLiveUsuario = live => {
  return dispatch => {
    dispatch({ type: usuarioTypes.SET_LIVE_USUARIO, live });
  };
};

const changeDataLiveArray = payload => {
  return dispatch => {
    dispatch({ type: usuarioTypes.CHANGE_DATA_LIVE_ARRAY, payload });
  };
};

export const usuariosActions = {
  setUsuario,
  setLiveUsuario,
  changeDataLiveArray
};
