/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import * as s from "./styled-live-tab";
import { Input, Button, Check } from "../../../../components";
import { icons, images } from "../../../../assets";
import Calendar from "react-calendar";
import "./calendar.css";
import { Scrollbars } from "react-custom-scrollbars";
import { managerService } from "../../../../services";
import { convertImgToBase64URL } from "../../../../utils";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { usuariosActions } from "../../../../store";

const LiveTab = ({
	next,
	liveId,
	visible,
	old,
	setSteps,
	setRealtime: ocultPlayer,
	setAoVivo: showTrasnmissionTab,
}) => {
	let history = useHistory();
	const dispatch = useDispatch();
	let local = JSON.parse(localStorage.getItem("condicoes")) || [];

	const [value, onChange] = useState([new Date(), new Date()]);
	const [calendarShowData, setCalendarShowData] = useState([
		new Date(),
		new Date(),
	]);
	const [ladoCard, setLadoCard] = useState("direito"); //direito/esquerdo/centro
	const [thumb, setThumb] = useState();
	const [nameThumbnail, setNameThumbnail] = useState();
	const INITIAL_FORMA = {
		livroCodigo: "",
		condicaoCodigo: "",
		condicaoDescricao: "",
		condicaoDesconto: "",
	};
	const [novaForma, setNovaForma] = useState(INITIAL_FORMA);
	const [all, setAll] = useState(!!history.location.state?.id);
	const [payamentTypes, setPayamentTypes] = useState([]);
	const [condicoes, setCondicoes] = useState([]);
	const [saving, setSaving] = useState(false);
	const [nome, setNome] = useState("");
	const [descricao, setDesc] = useState("");
	const [changes, setChanges] = useState(false);
	const [realtime, setRealtime] = useState(false);
	const [transmissaoAoVivo, setAoVivo] = useState(false);
	const [liveGravada, setLiveGravada] = useState(true);

	useEffect(() => {}, [calendarShowData]);

	useEffect(() => {
		const handler =
			calendarShowData !== undefined
				? setTimeout(() => {
						if (
							calendarShowData[0].getTime() <= calendarShowData[1].getTime()
						) {
							onChange(calendarShowData);
							setChanges(true);
						} else {
							setCalendarShowData(value);
							alert("A data inicial deve ser menor que a data final.");
						}
				  }, 1500)
				: setTimeout(() => {}, 10);
		return () => clearTimeout(handler);
	}, [calendarShowData]);

	useEffect(() => {
		if (liveId) {
			setSaving(true);
			managerService.getLive(liveId).then((data) => {
				setNome(data.nome);
				setDesc(data.descricao);
				onChange([new Date(data.dataInicio), new Date(data.dataFim)]);
				setLadoCard(data.ladoCard);
				setPayamentTypes(data.condicoes);

				const removeDuplicates = [...data.condicoes, ...local].filter(
					(cond, i, arr) =>
						i ===
						arr.findIndex(
							(t) => t.condicaoDescricao === cond.condicaoDescricao,
						),
				);

				setAll(removeDuplicates <= data.condicoes);

				setCondicoes(removeDuplicates);
				setThumb(data.thumbnail);
				setRealtime(data.realtime);
				setAoVivo(data.transmissaoAoVivo);
				setLiveGravada(data.realtime || data.transmissaoAoVivo ? false : true);
				showTrasnmissionTab(data.transmissaoAoVivo);
				setSaving(false);
				if (
					data.nome &&
					data.dataInicio &&
					data.dataFim &&
					data.thumbnail &&
					data.condicoes
				)
					localStorage.setItem("live_preenchida", true);
				localStorage.setItem("live_publicada", data.publicado);
			});
		} else if (local) {
			setPayamentTypes(local);
			setCondicoes(local);
			setAll(true);
		}
		return;
	}, [liveId]);

	useEffect(() => {
		if (!localStorage.getItem("condicoes")) {
			managerService.getFormasPagamento().then((data) => {
				localStorage.setItem("condicoes", JSON.stringify(data));
				setPayamentTypes(data);
				setCondicoes(data);
				setAll(true);
			});
		}
	}, []);

	useEffect(() => {
		localStorage.setItem("LADO_CARD", ladoCard);
	}, [ladoCard]);

	useEffect(() => {
		setSteps(realtime);
		ocultPlayer(realtime);
	}, [realtime]);

	useEffect(() => {
		if (!transmissaoAoVivo && !realtime && !liveGravada) {
			setChanges(false);
		}
	}, [realtime, transmissaoAoVivo, liveGravada]);

	const formatDataTime = (data) =>
		data.toLocaleDateString().split("/").reverse().join("-") +
		"T" +
		data.toLocaleTimeString().split(":")[0] +
		":" +
		data.toLocaleTimeString().split(":")[1];

	const nextStep = async () => {
		setSaving(true);
		let data = {
			nome,
			descricao,
			dataInicio: value[0].getTime(),
			dataFim: value[1].getTime(),
			condicoes: all
				? condicoes
				: payamentTypes.map((p) => ({
						...p,
						condicaoCodigo: parseInt(p.condicaoCodigo),
						livroCodigo: parseInt(p.livroCodigo),
				  })),
			ladoCard,
			realtime,
		};

		await convertImgToBase64URL(thumb, (base64) => {
			if (liveId) {
				managerService
					.editarLive(liveId, {
						...data,
						thumbnail: base64 ? base64.split("base64,")[1] : "",
						transmissaoAoVivo,
					})
					.then(() => {
						dispatch(
							usuariosActions.changeDataLiveArray({
								...data,
								id: liveId,
								thumbnail: base64,
							}),
						);
						setChanges(false);
						setSaving(false);
					})
					.catch((e) => {
						setSaving(false);
						alert(e);
					});
				if (nameThumbnail) {
					managerService
						.editarLiveThumbnail(liveId, {
							thumbnail: base64
								? base64.split("base64,")[1]
									? base64.split("base64,")[1]
									: base64
								: "",
							nameThumbnail,
						})
						.catch((e) => alert(e));
				}
			} else {
				managerService
					.criarLive({
						...data,
						thumbnail: base64 ? base64.split("base64,")[1] : "",
						nameThumbnail: nameThumbnail ? nameThumbnail : "",
						transmissaoAoVivo,
					})
					.then(({ id }) => {
						let { state } = history.location;
						const newState = { ...state, editar: true, id };
						history.replace({ ...history.location, state: newState });

						setChanges(false);
						setSaving(false);
						// if (realtime) setSteps(realtime);
						if (transmissaoAoVivo) showTrasnmissionTab(transmissaoAoVivo);
						next(id);
					})
					.catch((e) => {
						setSaving(false);
						alert(e);
					});
			}
		});
	};

	const handleSetAll = () => {
		setChanges(true);

		if (all) {
			setPayamentTypes([]);
			setAll(false);
			return;
		}

		setPayamentTypes(condicoes);
		setAll(true);
	};

	return (
		<>
			<s.Container className="fade-in" visible={visible}>
				<input
					style={{ display: "none" }}
					required
					type="file"
					accept="image/*"
					id="thumbnail-live"
					onChange={({ target }) => {
						setThumb(URL.createObjectURL(target.files[0]));
						setNameThumbnail(target.files[0].name);
						setChanges(true);
					}}
					disabled={saving}
				/>
				<s.Content>
					<section>
						<s.Title>Nome da live</s.Title>
						<Input
							disabled={saving}
							value={nome}
							onChange={({ target: t }) => {
								if (t.value.length < 100) {
									setNome(t.value);
									if (!changes) setChanges(true);
								}
							}}
							placeholder={"Ex.: Novidades Verão 2021"}
							style={{ marginBottom: "8px" }}
						/>
						<s.Title>Adicine uma thumbnail</s.Title>
						<s.Thumbnail image={images.logo} src={thumb}>
							{thumb && (
								<img
									id="thumbnail-live-img"
									src={thumb}
									style={{ maxWidth: "100%", maxHeight: "100%" }}
									alt=""
								/>
							)}
							<div className="thumbnail-shape"></div>
							<Button
								disabled={saving}
								label={!thumb ? "Adicionar thumbnail" : "Trocar thumbnail"}
								style={{
									position: "absolute",
									marginTop: "50px",
								}}
								onClick={() =>
									document.getElementById("thumbnail-live").click()
								}
							/>
							<p>Imagens preferencialmente no tamanho 330 x 130</p>
						</s.Thumbnail>
						<s.Title>Descrição da live</s.Title>
						<Input
							disabled={saving}
							value={descricao}
							onChange={({ target: t }) => {
								setDesc(t.value);
								setChanges(true);
							}}
							style={{ padding: "10px" }}
							placeholder={`Ex.:\nSuper coleção com descontos especiais!\n\nTodos que comprarem nessa live vão ter acesso à melhor coleção deste verão e com preços imperdíveis!\n\nVocê não pode perder!`}
							textarea
						/>
					</section>
					<section style={{ width: "440px" }}>
						<div style={{ display: "flex", marginBottom: "10px" }}>
							<div>
								<s.Title>Início da live</s.Title>
								<Input
									disabled={saving}
									style={{ marginTop: "3px" }}
									type="datetime-local"
									onChange={({ target }) => {
										setCalendarShowData([new Date(target.value), value[1]]);
									}}
									value={formatDataTime(calendarShowData[0])}
								/>
							</div>
							<div style={{ marginLeft: "11px" }}>
								<s.Title>Fim da live</s.Title>
								<Input
									disabled={saving}
									style={{ marginTop: "3px" }}
									type="datetime-local"
									onChange={({ target }) => {
										setCalendarShowData([value[0], new Date(target.value)]);
									}}
									value={formatDataTime(calendarShowData[1])}
								/>
							</div>
						</div>
						<s.Title
							style={{
								marginBottom: 3,
							}}>
							Agendamento
						</s.Title>
						<Calendar
							onChange={saving ? () => {} : (v) => setCalendarShowData(v)}
							value={calendarShowData}
							selectRange={!saving}
							calendarType={"Hebrew"}
							minDetail={"month"}
							maxDetail={"month"}
							navigationLabel={({ label }) => (
								<label style={{ color: "#828282" }}>
									{label.split(" ")[0]}
								</label>
							)}
							nextLabel={<img alt="" src={icons.arrowRightRed} />}
							prevLabel={<img alt="" src={icons.arrowLeftRed} />}
						/>
						<s.Title>Localização dos cards na live</s.Title>
						<s.PlayerOffset>
							{[
								{
									label: "Lado esquerdo",
									value: "esquerdo",
								},
								{
									label: "Lado direito",
									value: "direito",
								},
								{
									label: "Alinhar ao centro",
									value: "centro",
								},
							].map((opt, index) => (
								<s.Title
									key={`opt-${opt.value}-${index}`}
									style={{
										display: "flex",
										color: opt.value === ladoCard ? "white" : "",
									}}>
									<Check
										active={opt.value === ladoCard}
										onClick={() => {
											setLadoCard(opt.value);
											setChanges(true);
										}}
										disabled={saving}>
										{opt.label}
									</Check>
								</s.Title>
							))}
						</s.PlayerOffset>
					</section>
					<section>
						<s.Title>Condições de pagamento</s.Title>
						<div
							style={{
								width: "100%",
								flex: 1,
								background: "white",
								borderRadius: "4px",
								display: "grid",
							}}>
							<s.Title
								style={{
									display: "flex",
									margin: "20px 20px 0px 20px",
									paddingBottom: "10px",
									borderBottom: "1px solid #d0d0d0",
									color: all ? "#383838" : "",
								}}>
								<Check active={all} onClick={handleSetAll} disabled={saving}>
									Todas
								</Check>
							</s.Title>
							<Scrollbars style={{ height: 180 }}>
								{condicoes.map((c, index) => (
									<div
										style={{ flex: 1, margin: "15px 0 15px 30px" }}
										key={`${c.condicaoDescricao}-${index}`}>
										<s.Title
											style={{
												display: "flex",
												color: payamentTypes.includes(c) ? "#383838" : "",
											}}>
											<Check
												disabled={saving}
												active={payamentTypes.includes(c)}
												onClick={() => {
													let newPayments = payamentTypes;

													if (newPayments.includes(c)) {
														newPayments = newPayments.filter(
															(payament) => payament !== c,
														);
													} else newPayments = [...payamentTypes, c];

													if (newPayments.length === condicoes.length)
														setAll(true);
													if (newPayments.length < condicoes.length)
														setAll(false);
													setPayamentTypes(newPayments);
													setChanges(true);
												}}>
												{c.condicaoDescricao}
											</Check>
										</s.Title>
									</div>
								))}
							</Scrollbars>
						</div>
						<s.Title
							style={{
								paddingTop: "10px",
							}}>
							Adicionar outra condição
						</s.Title>
						<div
							style={{
								width: "100%",
								flex: 1,
								background: "white",
								borderRadius: "4px",
								display: "grid",
								paddingTop: "10px",
							}}>
							<form
								style={{ margin: "0 20px", display: "grid" }}
								disabled={saving}
								onSubmit={(e) => {
									e.preventDefault();
									if (!condicoes.includes(novaForma)) {
										setPayamentTypes([novaForma, ...payamentTypes]);
										setCondicoes([novaForma, ...condicoes]);
										setNovaForma(INITIAL_FORMA);
										setChanges(true);
									}
								}}>
								<div style={{ display: "flex" }}>
									<div>
										<s.Title>Código</s.Title>
										<Input
											palceholder="Digite aqui"
											type="number"
											value={novaForma.condicaoCodigo}
											required
											min="0"
											disabled={saving}
											onChange={({ target }) => {
												if (target.value >= 0)
													setNovaForma({
														...novaForma,
														condicaoCodigo: parseInt(target.value),
													});
											}}
										/>
									</div>
									<div style={{ marginLeft: "11px" }}>
										<s.Title>Livro</s.Title>
										<Input
											required
											disabled={saving}
											palceholder="Digite aqui"
											type="number"
											min="0"
											value={novaForma.livroCodigo}
											onChange={({ target }) => {
												if (target.value >= 0)
													setNovaForma({
														...novaForma,
														livroCodigo: parseInt(target.value),
													});
											}}
										/>
									</div>
								</div>
								<div>
									<s.Title>Descrição</s.Title>
									<Input
										required
										disabled={saving}
										palceholder="Digite aqui"
										value={novaForma.condicaoDescricao}
										onChange={({ target }) => {
											const emojiRegex =
												/(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g;
											if (!target.value.match(emojiRegex)) {
												setNovaForma({
													...novaForma,
													condicaoDescricao: target.value,
												});
											}
										}}
									/>
								</div>
								<div>
									<s.Title>Desconto (%)</s.Title>
									<Input
										required
										disabled={saving}
										palceholder="Digite aqui"
										type="number"
										min="0"
										value={novaForma.condicaoDesconto}
										onChange={({ target }) => {
											if (target.value >= 0)
												setNovaForma({
													...novaForma,
													condicaoDesconto: target.value,
												});
										}}
									/>
								</div>

								<Button
									label="Adicionar"
									style={{ margin: "20px 0" }}
									type="submit"
									disabled={
										!novaForma.livroCodigo ||
										!novaForma.condicaoCodigo ||
										!novaForma.condicaoDescricao ||
										!novaForma.condicaoDesconto ||
										saving
									}
								/>
							</form>
						</div>
						{/*BOTÂO PARA SINCONIZAÇÂO DE DADOS CM O BACK*/}
						{/* <Button
							label="Sincronizar Dados"
							style={{
								width: "180px",
								height: "30px",
								marginLeft: "200px",
								marginTop: "5px",
								display: "flex",
							}}></Button> */}
					</section>
				</s.Content>
			</s.Container>
			<Button
				label={liveId ? "Salvar" : old ? "Salvar" : "Próximo"}
				style={{
					width: "200px",
					margin: "0 auto 20px auto",
					display: visible ? "flex" : "none",
				}}
				onClick={nextStep}
				disabled={saving || !changes}
				isLoading={saving}
			/>

			<div style={{ margin: "0 auto 0px auto" }}>
				<div
					style={{
						width: "215px",
						display: visible ? "flex" : "none",
					}}
					title="Habilita o controle em tempo real dos produtos que aparecem durante a live">
					<s.Title
						style={{
							display: "flex",
							color: realtime ? "white" : "",
						}}>
						<Check
							// disabled={saving}
							active={realtime}
							onClick={() => {
								if (liveGravada) {
									setLiveGravada(false);
								}

								setRealtime(!realtime);
								setChanges(true);
							}}>
							Live com produtos real-time
						</Check>
					</s.Title>
				</div>

				<div
					style={{
						width: "220px",
						marginTop: "5px",
						display: visible ? "flex" : "none",
					}}
					title="Habilita a funcionalidade de fazer uma transmissão ao vivo da live">
					<s.Title
						style={{
							display: "flex",
							color: transmissaoAoVivo ? "white" : "",
						}}>
						<Check
							// disabled={old}
							active={transmissaoAoVivo}
							onClick={() => {
								if (liveGravada) {
									setLiveGravada(false);
								}

								setAoVivo(!transmissaoAoVivo);
								setChanges(true);
							}}>
							Live com transmissão ao vivo
						</Check>
					</s.Title>
				</div>

				<div
					style={{
						width: "220px",
						marginTop: "5px",
						display: visible ? "flex" : "none",
					}}
					title="Opção default de live com produtos e transmissão agendada (pré-cadastrada)">
					<s.Title
						style={{
							display: "flex",
							color: liveGravada ? "white" : "",
						}}>
						<Check
							// disabled={old}
							active={liveGravada}
							onClick={() => {
								if (realtime || transmissaoAoVivo) {
									setRealtime(false);
									setAoVivo(false);
								}

								setLiveGravada(!liveGravada);
								setChanges(true);
							}}>
							Live gravada
						</Check>
					</s.Title>
				</div>
			</div>
		</>
	);
};

export default LiveTab;
