import { liveTypes } from '../actions';

const INITIAL_STATE = {
  started: false,
  chat: [],
  loadingChat: true,
  produtos: [],
  loadingProdutos: true,
  favoritos: [],
  grades: [],
  formasPagamento: [],
  testUsers: []
};

export function live(state = INITIAL_STATE, action) {
  if (action.type === liveTypes.SET_CHAT) {
    return { ...state, chat: action.chat, loadingChat: false };
  } else if (action.type === liveTypes.SET_FAVORITOS) {
    return { ...state, favoritos: action.favoritos };
  } else if (action.type === liveTypes.SET_PRODUTOS) {
    return { ...state, produtos: action.produtos, loadingProdutos: false };
  } else if (action.type === liveTypes.SET_GRADES) {
    return { ...state, grades: action.grades };
  } else if (action.type === liveTypes.SET_FORMAS_PAGAMENTO) {
    return { ...state, formasPagamento: action.formasPagamento };
  } else if (action.type === liveTypes.RESET_STATE) {
    return { ...state, ...INITIAL_STATE };
  } else if (action.type === liveTypes.SET_STARTED) {
    return { ...state, started: true };
  } else if (action.type === liveTypes.SET_TEST_USERS) {
    return { ...state, testUsers: action.testUsers };
  } else {
    return state;
  }
}
