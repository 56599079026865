import styled from "styled-components";

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  min-height: 34px;
  font-family: Nunito;
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  border-radius: 4px;
  padding: 0 20px;
  @media (max-width: 800px) {
    padding: 0 10px;
  }
  text-transform: uppercase;
  cursor: pointer;
  outline: 0;
  border: 0;
  background: ${(props) => (props.green ? "#8BC986" : "#E94E5C")};
  color: white;
  :active {
    box-shadow: none;
  }
  :hover {
    background: ${(props) => (props.green ? "#60AF5A" : "#C7323F")};
  }
  :disabled {
    cursor: not-allowed;
    background: #d0d0d0;
    :hover {
      background: #d0d0d0;
    }
  }
`;

export const ButtonOutline = styled(Button)`
  background: white;
  color: #c7323f;
  border: 1px solid #e94e5c;
  :active {
    box-shadow: none;
  }
  :hover {
    background: white;
  }
  :disabled {
    cursor: not-allowed;
    background: #d0d0d0;
    :hover {
      background: #d0d0d0;
    }
  }
`;
