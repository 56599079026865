import styled from "styled-components";
import { colors } from "../../assets";

export const Container = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	padding-bottom: 84px;
	background: white;
	margin-top: 20px;
	border-radius: 4px;
	box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4), -2px -2px 6px rgba(0, 0, 0, 0.4);
	-moz-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4),
		-2px -2px 6px rgba(0, 0, 0, 0.4);
	-webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4),
		-2px -2px 6px rgba(0, 0, 0, 0.4);
`;
export const Title = styled.h1`
	font-weight: bold;
	font-size: 20px;
	text-decoration-line: underline;
	min-height: 70px;
	display: flex;
	align-items: center;
`;

export const Infos = styled.div`
	width: 80%;
	max-width: 1235px;
	margin: auto auto 50px auto;
	display: flex;
	justify-content: space-between;
	border-top: 1px solid #989898;
	padding-top: 20px;
	h2,
	h3 {
		position: relative;
		font-weight: 600;
		font-size: 16px;
		p {
			color: ${colors.primary};
			text-decoration: line-through;
		}
		@media (max-width: 800px) {
			margin-top: 15px;
		}
		span {
			position: absolute;
			top: 50%;
			left: 0;
			transform: translate(-130%, -79%);
			font-size: 20px;
			font-weight: 800;
			color: ${colors.primary};
		}
	}
	h2 {
		${(props) => props.ocultaPreco && "display: none"};
	}
	@media (max-width: 800px) {
		flex-direction: column;
	}
`;

export const Footer = styled.div`
	${(props) => props.ocultaPreco && "margin-top: 10px"};
	width: 80%;
	max-width: 1235px;
	margin: auto;
	display: flex;
	input {
		width: calc(100% - 30px);
		height: 100%;
		min-height: 30px;
		margin-right: 20px;
		border-bottom: 1px solid #989898;
		display: flex;
		align-items: center;
		background: transparent;
		font-size: 16px;
		padding: 10px;
		@media (max-width: 950px) {
			margin: 0 0 20px 0;
		}
	}
	.buttons {
		display: flex;
		justify-content: flex-end;
		@media (max-width: 800px) {
			flex-direction: column;
			button {
				width: 90%;
				margin: 5px 0;
			}
		}
	}
	@media (max-width: 950px) {
		flex-direction: column;
	}
`;
export const ModalContainer = styled.div`
	width: 370px;
	display: flex;
	padding: 25px 0;
	flex-direction: column;
	align-items: center;
	background: #f4f4f4;
	border-radius: 4px;
	h1 {
		font-weight: 600;
		font-size: 18px;
	}
	input {
		width: 248px;
		height: 25px;
		border-bottom: 1px solid #989898;
		padding: 0 10px;
		font-weight: 600;
		font-size: 16px;
		color: #989898;
		background: transparent;
		margin: 20px 0;
	}
	legend {
		margin-top: 15px 0;
		font-size: 12px;
	}
	label {
		margin: 10px auto;
		max-width: 300px;
		font-size: 12px;
		text-align: center;
		color: #989898;
	}
`;

export const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		zIndex: 99,
		border: "none",
		borderRadius: "none",
		backgroundColor: "rgba(45, 37, 40, 0.9)",
		padding: 0,
		width: "370px",
	},
	overlay: {
		zIndex: 99,
		backgroundColor: "rgba(45, 37, 40, 0.9)",
	},
};
export const selectStyles = {
	container: (provided, state) => ({
		...provided,
		width: 200,
		fontFamily: "Nunito",
		borderRadius: 4,
		boxShadow:
			"1px 1px 3px rgba(0, 0, 0, 0.1), -1px -1px 3px rgba(0, 0, 0, 0.1)",
		color: "#989898",
	}),
	control: (provided, state) => ({
		...provided,
		minHeight: 34,
		border: "none",
		boxShadow: "none",
		backgroundColor: "white",
		color: "#989898",
	}),
	placeholder: (provided, state) => ({
		...provided,
		whiteSpace: "nowrap",
		maxWidth: 200,
		overflow: "hidden",
		textOverflow: "ellipsis",
		color: "#989898",
	}),
	dropdownIndicator: (provided, state) => ({
		...provided,
	}),
	indicatorSeparator: (provided, state) => ({
		...provided,
		display: "none",
	}),
	option: (provided, state) => ({
		...provided,
		color: "#989898",
		minHeight: 34,
		display: "flex",
		alignItems: "center",
	}),
	singleValue: (provided, state) => ({
		...provided,
		color: "#989898",
	}),
};
