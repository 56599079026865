import styled from 'styled-components';

export const styles = {
  bmBurgerBars: {
    background: '#373a47'
  },
  bmBurgerBarsHover: {
    background: '#a90000'
  },
  bmCrossButton: {
    display: 'none'
  },
  bmCross: {
    display: 'none'
  },
  bmMenuWrap: {
    position: 'fixed'
  },
  bmMenu: {
    background: '#fff',
    fontSize: '1.15em'
  },
  bmMorphShape: {
    fill: '#373a47'
  },
  bmItemList: {
    boxShadow: '7px 0px 20px rgba(0, 0, 0, 0.15)',
    display: 'flex',
    flexDirection: 'column'
  },
  bmItem: {
    display: 'flex',
    alignItems: 'center',
    padding: 10,
    cursor: 'pointer',
    outline: 'none'
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)'
  }
};

export const MenuItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const BMitem = styled.div`
  display: flex;
  align-items: center;
  padding: 0px;
  height: 40px;
  cursor: pointer;
  color: ${({ active }) => (active ? '#e94e5c' : '#909090')};
  background: ${({ active }) => (active ? 'rgba(144, 144, 144, 0.1)' : '#FFF')};
  transition: 270ms all;
  :hover {
    color: #e94e5c;
    background: rgba(144, 144, 144, 0.1);
  }
`;

export const Label = styled.label`
  font-size: 21px;
  line-height: 20px;
  font-weight: bold;
  color: inherit;
  cursor: pointer;
`;

export const Icon = styled.img`
  cursor: pointer;
  transition: 270ms all;
  margin-right: 20px;
  ${({ size, height }) => size && `width: ${size};height: ${height ? height : size};`}
  ${({ padding }) => padding && `padding: ${padding};`}
  ${({ margin }) => margin && `margin: ${margin};`}
`;
