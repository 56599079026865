import { usuarioTypes } from '../actions';

const INITIAL_STATE = {
  usuario: null
};

export function usuario(state = INITIAL_STATE, action) {
  if (action.type === usuarioTypes.SET_USUARIO) {
    return { ...state, usuario: action.usuario };
  } else if (action.type === usuarioTypes.SET_LIVE_USUARIO) {
    return {
      ...state,
      usuario: { ...state.usuario, live: action.live }
    };
  } else if (action.type === usuarioTypes.CHANGE_DATA_LIVE_ARRAY) {
    const { payload } = action;

    const newLives = state.usuario.lives.map(live => {
      if (live.id === payload.id) {
        return {
          ...live,
          thumbnail: payload.thumbnail,
          dataFim: new Date(payload.dataFim).toISOString(),
          dataInicio: new Date(payload.dataInicio).toISOString(),
          descricao: payload.descricao,
          nome: payload.nome
        };
      }
      return live;
    });

    return {
      ...state,
      usuario: { ...state.usuario, lives: newLives }
    };
  } else {
    return state;
  }
}
