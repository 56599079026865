import React from "react";
import { Text, Page, View, StyleSheet, Font } from "@react-pdf/renderer";
import SideLogo from "../sideLogo/side-logo";

const colors = {
	grey: "#e7e3e0",
	darkGrey: "#cccccc",
	yellow: "#f8f530",
	blue: "#dcebf7",
	darkBlue: "#59a4d1",
	salmao: "#fee4d6",
};

Font.register({
	family: "Nunito",
	fontStyle: "normal",
	fonts: [
		{
			src: "https://fonts.gstatic.com/s/nunito/v16/XRXW3I6Li01BKofAksCkZg.ttf",
			fontWeight: 800,
		},
		{
			src: "https://fonts.gstatic.com/s/nunito/v16/XRXW3I6Li01BKofAjsOkZg.ttf",
			fontWeight: 700,
		},
		{
			src: "https://fonts.gstatic.com/s/nunito/v16/XRXV3I6Li01BKof4Mg.ttf",
			fontWeight: 400,
		},
	],
});

// Create styles
const s = StyleSheet.create({
	page: {
		backgroundColor: "#fff",
		width: "100%",
		height: "100%",
	},
	grey: {
		backgroundColor: colors.darkGrey,
	},
	yellow: {
		backgroundColor: colors.yellow,
	},
	MainContent: {
		padding: "2%",
		width: "100%",
		height: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
		alignSelf: "center",
	},
	table: {
		marginTop: "20px",
		width: "35%",
	},
	tableContainer: {
		display: "table",
		width: "auto",
		borderLeft: "1px solid #000",
		borderTop: "1px solid #000",
	},
	row: {
		display: "flex",
		flexDirection: "row",
	},
	headerCell: {
		width: "100%",
		height: "25px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexDirection: "column",
		fontSize: "17px",
		borderRight: "1px solid #000",
		borderBottom: "1px solid #000",
	},
	contentCell: {
		width: "100%",
		height: "25px",
		display: "flex",
		alignItems: "start",
		justifyContent: "center",
		fontSize: "17px",
		borderRight: "1px solid #000",
		borderBottom: "1px solid #000",
		paddingLeft: "5px",
	},
	contentRight: {
		textAlign: "right",
		justifyContent: "center",
		paddingRight: "15px",
	},
	boldTexts: {
		fontFamily: "Nunito",
		fontWeight: 700,
	},
});

const FavoriteBrands = ({ data }) => {
	const { totalPares, ...brand } = data;
	const brandValues = Object.values(brand).sort(
		(a, b) => (b?.quantidadeTotal || 0) - (a?.quantidadeTotal || 0),
	);
	return (
		<Page size="A4" orientation="landscape" style={s.page}>
			<SideLogo />

			<View style={s.MainContent}>
				<Text style={[s.boldTexts, { fontWeight: 800 }]}>
					Pares Favoritados por Marca
				</Text>
				<View style={s.table}>
					<View style={s.tableContainer}>
						<View style={[s.row, s.boldTexts]}>
							<View style={[s.headerCell, s.yellow, { width: "60%" }]}>
								<Text>MARCA</Text>
							</View>
							<View style={[s.headerCell, s.yellow, { width: "40%" }]}>
								<Text>PARES</Text>
							</View>
						</View>
						{/* CONTENT */}
						{brandValues?.map((row) => (
							<View style={s.row} key={`favoriteBrands-${row?.marca}`}>
								<View style={[s.contentCell, { width: "60%" }]}>
									<Text>{row?.marca}</Text>
								</View>
								<View style={[s.contentCell, s.contentRight, { width: "40%" }]}>
									<Text>{row?.quantidadeTotal}</Text>
								</View>
							</View>
						))}

						<View style={s.row}>
							<View style={[s.contentCell, s.grey, { width: "60%" }]}>
								<Text>Total Geral</Text>
							</View>
							<View
								style={[
									s.contentCell,
									s.contentRight,
									s.grey,
									{ width: "40%" },
								]}>
								<Text>{totalPares}</Text>
							</View>
						</View>
					</View>
				</View>
			</View>
		</Page>
	);
};

export default FavoriteBrands;
