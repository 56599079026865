import React, { useEffect, useState } from "react";
import { Document, StyleSheet, PDFViewer } from "@react-pdf/renderer";
import PageHome from "./pages/home";
import Summary from "./pages/summary";
import FavoriteBrands from "./pages/favoriteBrands";
import ProductsSlide from "./pages/ProductsSlide";
import { managerService } from "../../services";
import { useLocation } from "react-router-dom";
import { Loader } from "../index";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import * as s from "./styled-pdf-model";

// Create styles
const styles = StyleSheet.create({
	document: {
		border: "none",
		width: "100%",
		overflowX: "hidden",
		fontFamily: "Nunito",
	},
});

// Create Document Component
const PdfOverview = ({ data }) => {
	const { liveData } = data;
	const liveDate = format(new Date(liveData.dataInicio), "dd-MM-yyyy", {
		locale: ptBR,
	});
	const { totalPares, ...brandValues } = data.favoriteProducts;

	const numberOfFavoritePages = Math.ceil(
		Object.values(brandValues).length / 2,
	);

	return (
		<>
			<Document
				styles={styles.document}
				title={`Relatório LiveShow - ${liveData.nome}`}
				subject={`Live com início no dia ${liveDate}`}
				keywords={"Live, Beira Rio, Calçados, Relatório, Faturamento, Análise"}
				author="beira-rio"
				producer="beira-rio"
				language="PT-BR"
				creator="beira-rio">
				<PageHome
					liveDate={liveDate}
					liveName={liveData.nome}
					thumb={liveData.thumbnail}
				/>

				<Summary
					liveDate={liveDate}
					data={data?.mergeArrays}
					totalData={data?.sumTotal}
				/>

				{Object.keys(data?.favoriteProducts)?.length > 0 && (
					<FavoriteBrands data={data?.favoriteProducts} />
				)}

				{[...Array(numberOfFavoritePages).keys()].map((i) => (
					<React.Fragment key={`favoritePages${i}`}>
						<ProductsSlide data={brandValues} index={i} />
					</React.Fragment>
				))}
			</Document>
		</>
	);
};

const PDFwrapper = () => {
	const [data, setData] = useState({});
	const { state } = useLocation();

	useEffect(() => {
		const params = {
			regional: state.filters.room.nomeRegional,
			cliente: state.filters.client.codUsuario,
			marca: state.filters.brand.MARCA,
		};
		const liveId = state.liveData.id;

		const updateFavoriteProductsObject = (
			item,
			uniqueProductBrand,
			productBrand,
			uniqueProductRef,
		) => {
			const isPresentInSetProdutos = uniqueProductBrand.has(
				item.produto.DESCRICAO_MARCA,
			);
			uniqueProductBrand.add(item.produto.DESCRICAO_MARCA);
			const isRefPresent = uniqueProductRef.has(item.idProduto);
			uniqueProductRef.add(item.idProduto);

			const brandName = item.produto.DESCRICAO_MARCA;

			if (!isPresentInSetProdutos) {
				productBrand[brandName] = {
					marca: brandName,
					produtos: [],
					quantidadeTotal: 0,
				};
			}

			productBrand[brandName].quantidadeTotal += item.quantidade;

			if (isRefPresent) {
				productBrand[brandName].produtos.filter((actual) => {
					if (actual.ref === item.idProduto) {
						actual.quantidade += item.quantidade;
					}
					return actual;
				});
			} else if (productBrand[brandName].produtos?.length < 5) {
				productBrand[brandName].produtos.push({
					descricao: item.produto.DESCRICAO_PRODUTO,
					cor: item.produto.DESCRICAO_COR,
					imagem: item.produto.IMAGEM_PEQUENA,
					quantidade: item.quantidade,
					ref: item.idProduto,
				});
			}

			productBrand.totalPares =
				item.quantidade + (productBrand.totalPares || 0);
		};

		managerService
			.getPdfData(liveId, params)
			.then((data) => {
				const uniqueValuesSet = new Set();
				const uniqueValuesFavorites = new Set();
				const uniqueValuesSold = new Set();
				const uniqueProductBrand = new Set();
				const uniqueProductRef = new Set();

				const productBrand = {};

				const filteredArrUsers = data.logins.filter((obj) => {
					const isPresentInSet = uniqueValuesSet.has(obj.nome);
					uniqueValuesSet.add(obj.nome);
					return !isPresentInSet;
				});

				const viewersPerRoom = filteredArrUsers.reduce((acc, user) => {
					const baseObj = { regional: user.siglaRegional, viewersAccess: 0 };

					const actual = acc[user.siglaRegional] || baseObj;
					actual.viewersAccess++;

					return { [user.siglaRegional]: actual, ...acc };
				}, {});

				let soldProducts = {};
				if (data?.pedidos?.length > 0) {
					soldProducts = data.pedidos.reduce((acc, item) => {
						const baseObj = {
							regional: item.siglaRegional,
							vendidoClientes: [],
							vendidoProdutos: [],
							vendidoValores: 0,
							vendidoQuantidade: 0,
						};
						let actual = acc[item.siglaRegional] || baseObj;

						const isPresentInSet = uniqueValuesSold.has(item.nome);
						uniqueValuesSold.add(item.nome);
						if (!isPresentInSet) {
							actual.vendidoClientes.push({
								codUsuario: item.codUsuario,
								nome: item.nome,
							});
						}

						const descontoPedido =
							state.liveData.condicoes.find(
								(cond) => cond.condicaoCodigo === item.condicaoCodigo,
							).condicaoDesconto || 0;

						let valorTotal;
						let valorComDesconto;
						item.favoritos.forEach((favorite, index) => {
							valorTotal = favorite.quantidade * favorite.produto.PRECO_VENDA;

							valorComDesconto = Number(
								valorTotal - valorTotal * (descontoPedido / 100),
							);

							actual.vendidoProdutos.push({
								condicaoCodigo: item.condicaoCodigo,
								quantidade: favorite.quantidade,
								marca: favorite.produto.DESCRICAO_MARCA,
								imagem: favorite.produto.IMAGEM_PEQUENA,
								preco: favorite.produto.PRECO_VENDA,
								desconto: descontoPedido,
								totalPedido: valorComDesconto,
							});

							actual.vendidoValores += valorComDesconto;
							actual.vendidoQuantidade += favorite.quantidade;
						});

						return { [item.siglaRegional]: actual, ...acc };
					}, {});
				} else {
					[...Object.values(viewersPerRoom)].forEach((item) => {
						soldProducts[item.regional] = {
							regional: item.regional,
							vendidoClientes: [],
							vendidoProdutos: [],
							vendidoValores: 0,
							vendidoQuantidade: 0,
						};
					});
				}

				let favorites = {};
				if (data?.favoritos?.length > 0) {
					favorites = data.favoritos.reduce((acc, item) => {
						const baseObj = {
							regional: item.siglaRegional,
							favoritosClientes: [],
							favoritosProdutos: [],
							favoritosValorTotal: 0,
							favoritosPares: 0,
						};

						updateFavoriteProductsObject(
							item,
							uniqueProductBrand,
							productBrand,
							uniqueProductRef,
						);

						const isPresentInSet = uniqueValuesFavorites.has(item.nome);
						uniqueValuesFavorites.add(item.nome);
						let actual = acc[item.siglaRegional] || baseObj;

						if (!isPresentInSet) {
							actual.favoritosClientes.push({
								codUsuario: item.codUsuario,
								nome: item.nome,
							});
						}

						const favoritoTotal = item.quantidade * item.produto.PRECO_VENDA;

						actual.favoritosProdutos.push({
							quantidade: item.quantidade,
							marca: item.produto.DESCRICAO_MARCA,
							imagem: item.produto.IMAGEM_PEQUENA,
							preco: item.produto.PRECO_VENDA,
							totalPedido: favoritoTotal,
						});

						actual.favoritosValorTotal += favoritoTotal;
						actual.favoritosPares += item.quantidade;

						return { ...acc, [item.siglaRegional]: actual };
					}, {});
				} else {
					[...Object.values(viewersPerRoom)].forEach((item) => {
						favorites[item.regional] = {
							regional: item.regional,
							favoritosClientes: [],
							favoritosProdutos: [],
							favoritosValorTotal: 0,
							favoritosPares: 0,
						};
					});
				}

				const keys = Object.keys({
					...viewersPerRoom,
					...soldProducts,
					...favorites,
				});

				const mergeArrays = keys.map((item) => {
					return {
						...viewersPerRoom[item],
						...soldProducts[item],
						...favorites[item],
					};
				});

				const sumValues = mergeArrays.reduce((acc, item) => {
					acc = {
						totalAcessos: (item?.viewersAccess || 0) + (acc?.totalAcessos || 0),

						totalValoresFavoritos:
							(item?.favoritosValorTotal || 0) +
							(acc?.totalValoresFavoritos || 0),
						totalClientesFavoritos:
							(item?.favoritosClientes?.length || 0) +
							(acc?.totalClientesFavoritos || 0),
						totalQuantidadeFavoritos:
							(item?.favoritosPares || 0) +
							(acc?.totalQuantidadeFavoritos || 0),

						totalClientesPedidos:
							(item?.vendidoClientes?.length || 0) +
							(acc?.totalClientesPedidos || 0),
						totalValoresPedidos:
							(item?.vendidoValores || 0) + (acc?.totalValoresPedidos || 0),
						totalQuantidadePedidos:
							(item?.vendidoQuantidade || 0) +
							(acc?.totalQuantidadePedidos || 0),
					};

					return acc;
				}, {});

				// console.log("summmmmm", sumValues);
				// console.log("state", {
				// 	liveData: state?.liveData,
				// 	mergeArrays,
				// 	sumTotal: sumValues,
				// 	favoriteProducts: productBrand,
				// });

				setData({
					liveData: state?.liveData,
					mergeArrays,
					sumTotal: sumValues,
					favoriteProducts: productBrand,
				});
			})
			.catch((e) => alert(e));
	}, [state]);

	if (Object.keys(data).length <= 0)
		return (
			<div
				className="fade-in"
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					flex: 1,
					height: "100vh",
				}}>
				<Loader />
			</div>
		);

	return (
		<s.Container>
			<PDFViewer
				className={"pdf-viewer"}
				filename="relatorio_live_show.pdf"
				style={{
					flex: 1,
					width: "100%",
					height: "100%",
					overflowX: "hidden",
					border: "none",
				}}
				children={<PdfOverview data={data} />}
			/>
		</s.Container>
	);
};

export default PDFwrapper;
