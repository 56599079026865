import React from "react";
import "./radio-button.css";

const RadioButton = (props) => {
  const { label, onChange, value, disabled, style } = props;
  return (
    <div
      className="container-radio-button-component"
      disabled={disabled}
      style={{ ...style }}
      onClick={onChange}
    >
      <label className="label-radio-button">
        {label || "Opção 1"}
        <input
          type="radio"
          checked={value}
          name="radio"
          disabled={disabled}
          onChange={() => {}}
        />
        <span className="checkmark" disabled={disabled}></span>
      </label>
    </div>
  );
};
export default RadioButton;
