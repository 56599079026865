import React from 'react';
import * as s from './styled-check';
import { icons } from '../../assets';

const Check = ({ active, onClick, style, disabled, children }) => {
  return (
    <s.CheckContainer disabled={disabled} onClick={disabled ? () => {} : () => onClick(!active)}>
      <s.Check all={active} style={style}>
        {active && <img src={icons.check} />}
      </s.Check>
      {children}
    </s.CheckContainer>
  );
};

export default Check;
