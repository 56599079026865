import imagesdefault from './images/default.svg';
import pdfHeart from './images/pdfHeart.png';
import playerLeft from './images/playerLeft.svg';
import playerRight from './images/playerRight.svg';
import logo from './images/logo.svg';
import logo_png from './images/logoImage.png';
import logotipo from './images/logotipo.png';
import empty from './images/empty.png';
import addThumbnail from './icons/add-thumbnail.svg';
import timeCard from './icons/time-card.svg';
import hearthRed from './icons/redHearth.svg';
import hearthGray from './icons/grayHearth.svg';
import hearthGrayFilled from './icons/grayHearthFilled.svg';
import hearthWhite from './icons/whiteHearth.svg';
import clock from './icons/clock.svg';
import pause from './icons/pause.svg';
import exit from './icons/exit.svg';
import play from './icons/play.svg';
import check from './icons/check.svg';
import send from './icons/send.svg';
import trash from './icons/trash.svg';
import arrowLeft from './icons/arrowLeft.svg';
import arrowRight from './icons/arrowRight.svg';
import arrowLeftRed from './icons/arrowLeftRed.svg';
import arrowRightRed from './icons/arrowRightRed.svg';
import bag from './icons/bag.svg';
import user from './icons/user.svg';
import userGray from './icons/userGray.svg';
import burger from './icons/burger.svg';
import close from './icons/close.svg';
import search from './icons/search.svg';
import closeRed from './icons/closeRed.svg';
import dashboard from './icons/dashboard.svg';
import dashboardRed from './icons/dashboardRed.svg';
import gear from './icons/gear.svg';
import gearRed from './icons/gearRed.svg';
import live from './icons/live.svg';
import liveRed from './icons/liveRed.svg';
import mute from './icons/mute.svg';
import EmojiClap from './icons/emojiClap.svg';
import EmojiShoe from './icons/emojiShoe.svg';
import EmojiHeart from './icons/emojiHeart.svg';
import EmojiLove from './icons/emojiLove.svg';
import ReloadIcon from './icons/reload.svg';
import pdfIcon from './icons/pdfIcon.svg';
import pdfIconRed from './icons/pdfIconRed.svg';
import plusIcon from './icons/plus.svg';

export const images = {
  default: imagesdefault,
  playerLeft,
  playerRight,
  logo,
  logotipo,
  empty,
  logo_png,
  pdfHeart
};

export const icons = {
  addThumbnail,
  timeCard,
  hearthRed,
  hearthGray,
  hearthGrayFilled,
  hearthWhite,
  clock,
  pause,
  exit,
  play,
  check,
  send,
  trash,
  arrowLeft,
  arrowRight,
  arrowLeftRed,
  arrowRightRed,
  bag,
  user,
  userGray,
  burger,
  close,
  search,
  closeRed,
  dashboard,
  dashboardRed,
  gear,
  gearRed,
  live,
  liveRed,
  mute,
  EmojiClap,
  EmojiShoe,
  EmojiHeart,
  EmojiLove,
  ReloadIcon,
  pdfIcon,
  pdfIconRed,
  plusIcon
};

export const colors = {
  primary: '#E94E5C',
  green: '#8BC986',
  secondary: '#EDCFCB',
  gray: '#F2F2F2',
  grayDark: '#989898',
  black: '#3D3D3D',
  white: '#FFFFFF',
  subtitle_blue: '#97C8D8',
  subtitle_green: '#7CC989'
};
