import React, { useEffect, useState } from 'react';
import * as s from './styled-mini-player.js';
import { images } from '../../assets/index.js';

const MiniPLayer = ({ produtos, url, ladoCard, style, combo }) => {
  const [mostrar, setMostrar] = useState([]);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [timeVideo, setTimeVideo] = useState(0);
  const identifierField = combo ? `COMBO_ID` : `id_doc`; //campo único para comparação

  useEffect(() => {
    let principais = [];
    if (combo) principais = produtos;
    else
      produtos.forEach(prod => {
        if (prod.PRINCIPAL === 'S') {
          principais.push(prod);
        }
      });
    principais.sort((a, b) => a.TEMPO_INICIAL - b.TEMPO_INICIAL);
    setMostrar(principais);
  }, [produtos]);

  function duas_casas(numero) {
    if (numero <= 9) numero = '0' + numero;
    return numero + '';
  }

  useEffect(() => {
    var s = timeVideo;
    if (s > 0) {
      let hora = duas_casas(Math.trunc(s / 3600));
      let minuto = duas_casas(Math.trunc((s % 3600) / 60));
      let segundo = duas_casas((s % 3600) % 60).substring(0, 2);
      if (segundo === '9.') segundo = '09';
      let formatado = hora + ':' + minuto + ':' + segundo;
      mostrar.forEach(p => {
        var timeFinal = p.TEMPO_FINAL;
        if (formatado === timeFinal && show[identifierField] === p[identifierField]) {
          if (show2) {
            setShow(show2);
            setShow2(false);
          } else setShow(false);
        }
        if (formatado === timeFinal && show2[identifierField] === p[identifierField]) setShow2(false);
        if (!show || !show2) {
          var time = p.TEMPO_INICIAL;
          if (formatado === time) {
            if (!show) setShow(p);
            if (show && show[identifierField] !== p[identifierField] && !show2) setShow2(p);
          }
        }
      });
    }
  }, [timeVideo]);

  return (
    <s.Container id='player-container' ladoCard={ladoCard}>
      {show && show.IMAGEM_VIDEO_LIVE && (
        <s.Poppup
          ladoCard={ladoCard}
          src={show.IMAGEM_VIDEO_LIVE}
          onClick={() => setShow(false)}
          className={`animated-linear fade-in`}
          id={`poppup-live-${show[identifierField]}`}
          onError={() =>
            (document.getElementById(`poppup-live-${show[identifierField]}`).src = images.default)
          }
        />
      )}
      {show2 && show2.IMAGEM_VIDEO_LIVE && (
        <s.Poppup
          ladoCard={ladoCard}
          src={show2.IMAGEM_VIDEO_LIVE}
          onClick={() => setShow2(false)}
          className={`animated-linear fade-in`}
          bottom
          id={`poppup-live-${show2[identifierField]}`}
          onError={() =>
            (document.getElementById(`poppup-live-${show2[identifierField]}`).src = images.default)
          }
        />
      )}
      <video
        style={style}
        src={url}
        controls
        id={combo ? 'video-combo' : 'video'}
        onTimeUpdate={({ currentTarget: c }) => {
          if (
            (c.currentTime > timeVideo && c.currentTime > timeVideo + 2) ||
            (c.currentTime < timeVideo && c.currentTime < timeVideo - 2)
          ) {
            setShow(false);
            setShow2(false);
          }
          setTimeVideo(c.currentTime);
        }}
      />
    </s.Container>
  );
};

export default MiniPLayer;
