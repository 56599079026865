import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { images } from "../../assets";
import RelatorioModal from "./relatorio-modal";
import { getStatus, orderLives } from "../../utils";
import SearchInput from "./search-input/search-input";
import * as s from "./styled-relatorio";

const Relatorio = ({ history }) => {
	const [liveData, setLiveData] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [filterOption, setFilterOption] = useState();

	const [livesToShow, setLivesToShow] = useState([]);
	const [filters, setFilters] = useState(["Finalizada", "Transmitindo"]);

	const usuario = useSelector((state) => state.usuario.usuario);
	const lives = usuario && usuario.lives ? usuario.lives : [];
	var { live } = usuario;

	const headerContainerRef = useRef(null);

	useEffect(() => {
		if (lives.length > 0 && filters.length > 0) {
			handleLiveFilters(filters);
		}
	}, [lives]);

	useEffect(() => {
		const resizeHeaderWidth = () => {
			const cardsInLine = window.innerWidth / 356;
			const floor = Math.floor(cardsInLine);
			const sidesRest = window.innerWidth - 356 * floor;
			headerContainerRef.current.style.width = `${
				window.innerWidth - sidesRest - 10 * 2 // 10 * 2: side css margins
			}px`;
		};

		resizeHeaderWidth();

		window.addEventListener("resize", resizeHeaderWidth);
		return () => window.removeEventListener("resize", resizeHeaderWidth);
	}, []);

	const getDate = (date) => {
		let resp = `${new Date(date).toLocaleDateString()} às ${new Date(date)
			.toLocaleTimeString()
			.substr(0, 5)}`;
		return resp;
	};

	const openLiveModal = (liveSelected) => {
		setLiveData(liveSelected);
		setOpenModal(true);
	};

	const handleLiveFilters = (params) => {
		if (params.length >= 0) {
			const filterByStatus = lives.filter((live) =>
				params.includes(
					getStatus(live.publicado, live.dataInicio, live.dataFim),
				),
			);
			setFilters(params);

			setLivesToShow(orderLives(filterByStatus));
			return;
		}

		const showIt = lives.filter((live) =>
			live.nome.toLowerCase().match(params.nome.toLowerCase()),
		);

		setLivesToShow(orderLives(showIt));
		setFilterOption(params.nome);
	};

	return (
		<s.Container className="fade-in">
			{openModal && (
				<RelatorioModal
					liveData={liveData}
					isOpen={openModal}
					onRequestClose={() => setOpenModal(false)}
				/>
			)}

			<s.Header ref={headerContainerRef}>
				<s.Title>Selecione uma live que deseja gerar o relatório</s.Title>
				<SearchInput
					placeholder="Filtrar lives"
					selectedOption={filterOption}
					setSelected={handleLiveFilters}
					statusActive={filters}
				/>
			</s.Header>

			<s.ContainerItems>
				{livesToShow && livesToShow.length > 0 ? (
					livesToShow.map(
						(
							{
								nome,
								descricao,
								dataFim,
								dataInicio,
								id,
								publicado,
								thumbnail,
							},
							i,
						) => (
							<div
								key={id}
								className={`fade-in ${
									live && live.id === id ? "selecionada" : "normal"
								}`}>
								<section key={id} onClick={() => openLiveModal(livesToShow[i])}>
									<div
										className="image-card"
										id={`live-${id}-thumbnail`}
										// style={
										//   thumbnail
										//     ? { backgroundImage: `url(${thumbnail}), url(${images.default})` }
										//     : { backgroundImage: `url(${images.default})` }
										// }
									>
										<img
											alt="thumbnail"
											src={thumbnail}
											onError={() => {
												const thumb = document.querySelector(
													`#live-${id}-thumbnail img`,
												);
												thumb.src = images.default;
												thumb.style.width = "50%";
											}}
										/>
									</div>
									<h1 title={nome}>{nome}</h1>
									<h2 title={descricao}>{descricao}</h2>
									<div>
										<label>
											Início: <p>{getDate(dataInicio)}</p>
										</label>
										<label>
											Fim: <p>{getDate(dataFim)}</p>
										</label>
									</div>
								</section>
							</div>
						),
					)
				) : (
					<s.Empty>
						Você ainda não possui lives criadas. Vá para o{" "}
						<a onClick={() => history.push("/live-studio")}>Live Stúdio</a> e
						crie uma para aparecer aqui!
					</s.Empty>
				)}
			</s.ContainerItems>
		</s.Container>
	);
};

export default Relatorio;
