import React from "react";
import Select from "react-select";
import * as s from "./styled-select";
const SelectComponent = ({
  options,
  onChange,
  value,
  disabled,
  customStyles,
  menuPlacement,
}) => {
  return (
    <Select
      id="select-itens-component"
      menuPlacement={menuPlacement || "bottom"}
      isDisabled={disabled}
      styles={
        customStyles ? { ...s.customStyles, ...customStyles } : s.customStyles
      }
      value={value || { label: "Selecione" }}
      placeholder={value}
      noOptionsMessage={() => (
        <label style={{ opacity: 0.6 }}>Não há opções</label>
      )}
      onChange={onChange}
      options={options}
      classNamePrefix="react-select"
      theme={(defaultTheme) => ({
        ...defaultTheme,
        colors: {
          ...defaultTheme.colors,
          primary: "#E1E1E1",
          primary75: "#F1F1F1",
          primary50: "#F1F1F1",
          primary25: "#F1F1F1",
        },
      })}
    />
  );
};

export default SelectComponent;
