import React, { useEffect, useState } from 'react';
import { Button } from '../../../../components';
import { managerService } from '../../../../services';
import * as s from './styled-publicar-tab';
const Publicar = ({ liveId, visible }) => {
  const [publicada, setPublicada] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let test = localStorage.getItem('live_publicada');
    if (test === 'true') setPublicada(true);
  }, []);

  const changeLive = () => {
    setLoading(true);
    managerService
      .publicarLive(liveId, !publicada)
      .then(() => {
        setLoading(false);
        setPublicada(!publicada);
        localStorage.setItem('live_publicada', !publicada);
      })
      .catch(e => {
        alert(e);
        setLoading(false);
      });
  };

  return (
    <s.Container visible={visible}>
      <h1>Sua live {!publicada && 'não '}está publicada!</h1>
      <Button isLoading={loading} label={publicada ? 'Inativar' : 'Publicar'} onClick={changeLive} />
    </s.Container>
  );
};

export default Publicar;
