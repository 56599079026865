/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, useRef } from "react";
import * as s from "./styled-produtos-tab";
import { Scrollbars } from "react-custom-scrollbars";
import { icons } from "../../../../assets";
import { Button, MiniPlayer, Check } from "../../../../components";
import ProdutoModal from "./produto-modal";
import { managerService } from "../../../../services";
import { handleChangeTime } from "../../../../utils";

const ProdutosTab = ({ visible, liveId, next, old, realtime }) => {
	const [produtos, setProdutos] = useState([]);
	const [variacoes, setVariacoes] = useState();
	const [saving, setSaving] = useState(false);
	const [loading, setLoading] = useState(true);
	const [openModal, setOpenModal] = useState(false); //1=produto modal / 2=variação modal
	const [indexProd, setIndexProd] = useState(0); //qual produto abriu a modal
	const [changes, setChanges] = useState(false);
	const [syncingProducts, setSyncingProducts] = useState(false);
	const [, updateState] = useState();
	const forceUpdate = useCallback(() => updateState({}), []);

	const scrollerRef = useRef(null);

	const formatData = (data) => {
		let newVariacoes = {};
		let newProdutos = [];

		data = data.map((p) => ({
			...p,
			PRECO_VITRINE: parseFloat(p.PRECO_VITRINE).toFixed(2),
			PRECO_VENDA: parseFloat(p.PRECO_VENDA).toFixed(2),
		}));

		newProdutos = data.filter((p) => p.PRINCIPAL === "S");

		newProdutos = newProdutos.map((p) => ({
			...p,
			TEMPO_INICIAL: p.TEMPO_INICIAL.substr(3, 5),
			TEMPO_FINAL: p.TEMPO_FINAL.substr(3, 5),
		}));

		data.forEach((p) => {
			if (p.PRINCIPAL !== "S") {
				newVariacoes[p.ID] = newVariacoes[p.ID]
					? [...newVariacoes[p.ID], { ...p }]
					: [{ ...p }];
			}
		});

		if (produtos.length > 0) {
			produtos.forEach((p, i) => {
				let inicial = p.TEMPO_INICIAL;
				let final = p.TEMPO_FINAL;

				document.getElementById(`inicial-prod-${p.ID}`).value = inicial;
				document.getElementById(`final-prod-${p.ID}`).value = final;
			});
		}

		setProdutos(newProdutos);
		setVariacoes(newVariacoes);
		setLoading(false);
		setSyncingProducts(false);
		setSaving(false);

		// console.log('formatData output', newProdutos);
	};

	useEffect(() => {
		managerService
			.buscarProdutos(liveId)
			.then((data) => {
				formatData(data);
			})
			.catch((e) => alert(e));
	}, []);

	const groupData = () => {
		let data;
		if (produtos) {
			data = produtos.map((p, i) => {
				// console.log(p);
				return {
					...p,
					PRINCIPAL: "S",
					COMBO: p.COMBO ? p.COMBO : false,
					SEM_CARD: p.SEM_CARD ? p.SEM_CARD : false,
					TEMPO_INICIAL: `00:${
						document.getElementById(`inicial-prod-${p.ID}`)
							? document.getElementById(`inicial-prod-${p.ID}`).value
							: "00:00"
					}`,
					TEMPO_FINAL: `00:${
						document.getElementById(`final-prod-${p.ID}`)
							? document.getElementById(`final-prod-${p.ID}`).value
							: "00:00"
					}`,
				};
			});
		}

		if (variacoes) {
			data = [].concat(data, ...Object.values(variacoes));
		}

		return data;
	};

	// console.log(liveId);

	const saveData = () => {
		setSaving(true);

		let data;
		data = groupData();

		// verify timer's error
		const videoDuration = document.getElementById("video")?.duration || 0;
		const convertedVideoDUration = `00:${new Date(videoDuration * 1000)
			.toISOString()
			.substr(14, 5)}`;

		const hasIndexError = data.findIndex(
			(item, index) =>
				item.TEMPO_FINAL < item.TEMPO_INICIAL ||
				convertedVideoDUration < item.TEMPO_FINAL,
		);

		if (hasIndexError >= 0) {
			setSaving(false);

			scrollerRef.current.scrollTop(265 * hasIndexError);
			return alert("O tempo do produto contém erros!");
		}

		data = data.map((p) => ({
			...p,
			PRECO_VENDA: parseFloat(p.PRECO_VENDA),
			PRECO_VITRINE: parseFloat(p.PRECO_VITRINE),
		}));
		let post = data.filter((p) => !p.id_doc);
		let put = data.filter((p) => p.id_doc);
		data.filter((p) => !post.includes(p));

		console.log(data);

		if (put.length > 0)
			managerService
				.alterarProdutos(
					liveId,
					put.map((p) => ({ ...p })),
				)
				.then(() => {
					setSaving(false);
					setChanges(false);
				})
				.catch((e) => {
					alert(e);
					setSaving(false);
				});
		if (post.length > 0)
			managerService
				.cadastrarProdutos(
					liveId,
					post.map((p) => ({ ...p })),
				)
				.then((res) => {
					managerService
						.buscarProdutos(liveId)
						.then((data) => formatData(data));
					if (!old) next();
					setSaving(false);
					setChanges(false);
				})
				.catch((e) => {
					alert(e);
					setSaving(false);
				});
		if (put.length === 0 && post.length === 0) {
			setSaving(false);
		}
	};

	const deleteProd = (i, savedProd) => {
		const id = i;
		let data = groupData();

		// console.log('id', id);
		// console.log('data', savedProd);
		// console.log('bolleano', produtos[i].ID === savedProd.ID);

		if (savedProd) {
			let deletar = data
				.filter((p) => p.id_doc)
				.filter((p) => p.ID === produtos[i].ID)
				.map((p) => p.id_doc);

			// console.log(
			//   'envio de delete',
			//   deletar.map(id_doc => ({ id_doc }))
			// );

			managerService
				.deletarProdutos(
					liveId,
					deletar.map((id_doc) => ({ id_doc })),
				)
				.then(() => {
					managerService
						.buscarProdutos(liveId)
						.then((data) => formatData(data));
					setChanges(false);
				})
				.catch((e) => alert(e));
			setLoading(true);
		} else {
			setChanges(false);
			setProdutos(data.filter((p) => p.ID !== produtos[id].ID));
			setChanges(true);
		}
	};

	const deleteVariacoes = ({ prodId, indexV }) => {
		const targetVariation = variacoes[prodId][indexV];

		// console.log(prodId);
		// console.log(indexV);
		// console.log(targetVariation);

		if (variacoes[prodId]) {
			if (Object.keys(targetVariation) && targetVariation.id_doc) {
				// console.log('exists', targetVariation);
				// console.log('target', targetVariation.id_doc);
				managerService
					.deletarProdutos(liveId, [{ id_doc: targetVariation.id_doc }])
					.then(() => {
						managerService
							.buscarProdutos(liveId)
							.then((data) => formatData(data));
						setChanges(false);
					})
					.catch((e) => alert(e));
				setLoading(true);
			} else {
				const newVariacoes = variacoes[prodId].filter(
					(item, index) => index !== indexV,
				);

				const mergedObj = { [produtos[indexProd].ID]: newVariacoes };
				setVariacoes({ ...variacoes, ...mergedObj });
			}
		}
	};

	const changeProd = (prod, field, v) => {
		let newProds = produtos;
		const productSelected = newProds.find(
			(element) => element?.ID === prod?.ID,
		);

		if (productSelected?.ID) {
			productSelected[field] = v;
			// console.log(newProds);
			setProdutos(newProds);
			setChanges(true);
			forceUpdate();
		}
	};

	const onSubmitModal = (newProd) => {
		// console.log("indexProd", newProd);
		// console.log("produtos", produtos[indexProd]);
		// console.log("produtos[indexProd].ID", produtos[indexProd].ID);

		if (openModal === 1) {
			if (produtos && produtos[indexProd]) {
				let newProds = produtos;
				newProds[indexProd] = newProd;
				setProdutos(newProds);
			} else {
				const insertedRandomIdIntoProduct = {
					...newProd,
					ID: new Date().getTime(),
				};
				setProdutos([...produtos, insertedRandomIdIntoProduct]);
			}
		} else {
			if (newProd?.DESCRICAO_PRODUTO) {
				let array =
					variacoes && variacoes[produtos[indexProd].ID]
						? variacoes[produtos[indexProd].ID]
						: [];

				array.push({ ...newProd, PRINCIPAL: "N", ID: produtos[indexProd].ID });

				setVariacoes({
					...variacoes,
					[produtos[indexProd].ID]: array,
				});
			}
		}

		setOpenModal(false);
		setChanges(true);
	};

	const syncProducts = () => {
		setSyncingProducts(true);
		setSaving(true);

		managerService.buscarProdutos(liveId).then((data) => {
			const currentData = groupData();

			const untrackedProducts = currentData.filter((prod) => !prod.id_doc);
			const trackedProducts = currentData.reduce((acc, current) => {
				const x = acc.find((item) => item.id === current.id);
				if (!x) {
					return acc.concat([current]);
				} else {
					return acc;
				}
			}, data);

			formatData([...trackedProducts, ...untrackedProducts]);
		});
	};

	return (
		<>
			<s.Container visible={visible} className="fade-in">
				{openModal && (
					<ProdutoModal
						liveId={liveId}
						isOpen={openModal}
						onRequestClose={() => setOpenModal(false)}
						next={onSubmitModal}
						prod={
							produtos && produtos[indexProd]
								? openModal === 1
									? produtos[indexProd]
									: {
											GRADE_DISPONIVEL: produtos[indexProd].GRADE_DISPONIVEL,
											PRECO_VENDA: produtos[indexProd].PRECO_VENDA,
											PRECO_VITRINE: produtos[indexProd].PRECO_VITRINE,
									  }
								: null
						}
					/>
				)}
				<section style={{ margin: "15px 10px 5px", zIndex: 2 }}>
					{!realtime && (
						<>
							<s.HeaderCard>
								<s.Title>Definir set time</s.Title>
							</s.HeaderCard>
							<MiniPlayer
								produtos={groupData()}
								ladoCard={localStorage.getItem("LADO_CARD") || "esquerdo"}
								style={{
									maxWidth: "460px",
								}}
								url={
									localStorage.getItem("URL") ||
									`https://www.youtube.com/watch?v=NpEaa2P7qZI`
								}
							/>
						</>
					)}
					<Button
						label={
							loading
								? "Carregando"
								: saving
								? "Salvando"
								: old
								? "Salvar"
								: "Próximo"
						}
						style={{
							width: "200px",
							margin: "15px auto 0",
							display: visible ? "flex" : "none",
						}}
						onClick={saveData}
						disabled={saving || loading || !changes}
						isLoading={saving}
					/>
				</section>
				<section style={{ position: "relative" }} className="reduceMargin">
					<s.HeaderCard>
						<s.Title>Produtos</s.Title>
						<div>
							<s.Icon
								src={icons.ReloadIcon}
								alt="reload-icon"
								title="Sincronizar produtos"
								isLoading={syncingProducts}
								onClick={syncProducts}
							/>
							{/* FEATURE DE ADICIONAR PRODUTO POR BOTÃO */}
							{/* <s.Icon
                src={icons.plusIcon}
                alt='add-product-icon'
                title='Adicionar produto'
                onClick={saving || loading ? () => {} : () => setOpenModal(1)}
                isDisable={saving || loading}
              /> */}
						</div>
					</s.HeaderCard>
					<Scrollbars
						style={{
							height: "780px",
							scrollBehavior: "smooth",
						}}
						ref={scrollerRef}>
						{produtos &&
							[
								...produtos,
								{
									SEM_CARD: false,
									COMBO: false,
									t: true,
									ID: 123,
									PRECO_VITRINE: "",
									PRECO_VENDA: "",
								},
							].map((prod, i) => {
								return (
									<Produto
										key={`prod-${i + 1}`}
										prod={prod}
										i={i}
										t={prod.t}
										setOpenModal={(n) => {
											setOpenModal(n);
											setIndexProd(i);
										}}
										setIndexProd={setIndexProd}
										variacoes={variacoes}
										deleteProd={deleteProd}
										disabled={saving || loading}
										setCombo={(v) => changeProd(prod, "COMBO", v)}
										setVitrine={(v) => changeProd(prod, "PRECO_VITRINE", v)}
										setVenda={(v) => changeProd(prod, "PRECO_VENDA", v)}
										setSemCard={(v) => changeProd(prod, "SEM_CARD", v)}
										setProdutos={setProdutos}
										setChanges={setChanges}
										changes={changes}
										deleteVariacoes={deleteVariacoes}
										setVariacoes={(v) => {
											setVariacoes(v);
											forceUpdate();
											setChanges(true);
										}}
									/>
								);
							})}
					</Scrollbars>
				</section>
			</s.Container>
		</>
	);
};

const Produto = ({
	prod,
	i,
	t, //Usado para o último prod que sempre está vazio
	setOpenModal,
	setIndexProd,
	variacoes,
	deleteProd,
	disabled,
	setCombo,
	setSemCard,
	setChanges,
	changes,
	setVitrine,
	setVenda,
	setVariacoes,
	deleteVariacoes,
}) => {
	const [indexV, setIndexV] = useState(0); //posição da variação sendo exibida
	const [combo, setMyCombo] = useState(prod?.COMBO || false);
	const [semCard, setMySemCard] = useState(prod?.SEM_CARD || false);
	const [inicio, setInicio] = useState();
	const [fim, setFim] = useState();

	// console.log('prod', prod);
	// console.log('combo', combo);
	// console.log('============');

	useEffect(() => {
		setInicio(prod.TEMPO_INICIAL || "");
		setFim(prod.TEMPO_FINAL || "");
		setMyCombo(prod?.COMBO);
		setMySemCard(prod?.SEM_CARD);
		// document.getElementById(`prod-${prod.ID}-venda`).value = prod.PRECO_VENDA;
		// document.getElementById(`prod-${prod.ID}-vitrine`).value = prod.PRECO_VITRINE;
	}, [prod]);

	useEffect(() => {
		if ((inicio !== prod.TEMPO_INICIAL || fim !== prod.TEMPO_FINAL) && !changes)
			setChanges(true);
	}, [inicio, fim]);

	const deleteVariation = () => {
		// new Promise((resolve, reject) => {
		deleteVariacoes({ prodId: prod.ID, indexV });
		//     .then(resp => resolve(setIndexV(variacoes[prod.ID].length - 1)))
		//     .catch(e => reject(alert('não foi retornado dados da exlusão da variação')));
		// });
	};

	if (!prod.ID) {
		return;
	}

	const videoDuration = () => {
		if (document.getElementById("video")?.duration) {
			const duration = document.getElementById("video")?.duration || 0;
			const videoFormated = new Date(duration * 1000)
				.toISOString()
				.substr(14, 5);

			if (videoFormated < fim) return true;
			if (fim < inicio) return true;

			return false;
		}
	};

	console.log(prod);

	return (
		<s.ProdContainer key={`produto-${i}`}>
			<s.Produto timerError={videoDuration()}>
				<div className="absolute">
					<legend>00{i + 1}</legend>
					{!t && (
						<img
							alt="delete-icon"
							src={icons.trash}
							title="Deletar todo o produto"
							onClick={
								disabled
									? () => {}
									: () => deleteProd(i, prod.id_doc ? true : false)
							}
						/>
					)}
				</div>
				<div className="first">
					<label>Início do card</label>
					<span>
						<img
							alt={`icone-inicio-${i}`}
							src={icons.timeCard}
							onClick={() => {
								if (document.getElementById("video")) {
									let seconds = document.getElementById("video").currentTime;
									let v = new Date(seconds * 1000).toISOString().substr(14, 5);
									if (disabled || semCard) return;
									setInicio(v);
								}
							}}
						/>
						<input
							disabled={disabled || semCard}
							placeholder={"00:00"}
							id={`inicial-prod-${prod.ID}`}
							value={inicio}
							onChange={({ target: t }) =>
								handleChangeTime(
									t.value || "",
									inicio || "",
									(formatedValue) => {
										setInicio(formatedValue);
									},
								)
							}
							onDoubleClick={() => {
								if (document.getElementById("video")) {
									let seconds = document.getElementById("video").currentTime;
									let v = new Date(seconds * 1000).toISOString().substr(14, 5);
									setInicio(v);
								}
							}}
						/>
					</span>
					<label>Fim do card</label>
					<span>
						<img
							alt={`icone-fim-${i}`}
							src={icons.timeCard}
							onClick={() => {
								if (document.getElementById("video")) {
									let seconds = document.getElementById("video").currentTime;
									let v = new Date(seconds * 1000).toISOString().substr(14, 5);
									if (disabled || semCard) return;
									setFim(v);
								}
							}}
						/>
						<input
							disabled={disabled || semCard}
							placeholder={"00:00"}
							id={`final-prod-${prod.ID}`}
							value={fim}
							onChange={({ target: t }) =>
								handleChangeTime(t.value || "", fim || "", (formatedValue) => {
									setFim(formatedValue);
								})
							}
							onDoubleClick={() => {
								if (document.getElementById("video")) {
									let seconds = document.getElementById("video").currentTime;
									let v = new Date(seconds * 1000).toISOString().substr(14, 5);
									setFim(v);
								}
							}}
						/>
					</span>
				</div>
				<div className="middle">
					<div
						className={`img-container${
							prod.IMAGEM_GRANDE ? ` bg-white` : ` bg-gray`
						}`}>
						{prod.IMAGEM_GRANDE && (
							<img alt="img-produto" src={prod.IMAGEM_GRANDE} />
						)}
					</div>
					<Button
						label="Produto Principal"
						onClick={disabled ? () => {} : () => setOpenModal(1)}
					/>
					<s.Valores>
						{/* {prod.PRECO_VITRINE && ( */}
						<div>
							<label>Vitrine</label>
							<span>
								<label>R$</label>
								<input
									value={prod.PRECO_VITRINE}
									id={`prod-${prod.ID}-vitrine`}
									type="number"
									placeholder={"00,00"}
									onChange={({ target: t }) => {
										if (t.value.length <= 7) {
											setVitrine(t.value);
										}
									}}
								/>
							</span>
						</div>
						{/* )} */}
						{/* {prod.PRECO_VENDA?.length > -1 && ( */}
						<div>
							<label>Venda</label>
							<span>
								<label>R$</label>
								<input
									value={prod.PRECO_VENDA}
									id={`prod-${prod.ID}-venda`}
									type="number"
									placeholder={"00,00"}
									onChange={({ target: t }) => {
										if (t.value.length <= 7) {
											setVenda(t.value);
										}
									}}
								/>
							</span>
						</div>
						{/* )} */}
					</s.Valores>
				</div>
				<div className={`last ${prod.IMAGEM_VIDEO_LIVE ? ` bg-white` : ``}`}>
					{prod.IMAGEM_VIDEO_LIVE && (
						<img
							alt="card-produto"
							className="card fade-in"
							src={prod.IMAGEM_VIDEO_LIVE}
						/>
					)}
					<div>
						<Check
							disabled={disabled || prod.t || semCard}
							active={combo}
							key={prod.ID}
							id="comboFor"
							onClick={(v) => {
								setCombo(v);
								setMyCombo(v);
							}}>
							Produto para combo
						</Check>
					</div>
					<div>
						<Check
							disabled={false}
							active={semCard}
							key={prod.ID}
							onClick={(v) => {
								setSemCard(v);
								setMySemCard(v);
							}}>
							Produto sem card
						</Check>
					</div>
				</div>
			</s.Produto>
			<s.Variacoes>
				{variacoes && variacoes[prod.ID] && variacoes[prod.ID].length > 0 && (
					<img
						alt="deletar-variacao"
						src={icons.trash}
						className="deletar-variacao"
						onClick={() => {
							deleteVariation();
						}}
					/>
				)}
				{variacoes && variacoes[prod.ID] && variacoes[prod.ID].length > 0 && (
					<s.Arrows>
						<img
							alt="arrow-left"
							src={icons.arrowLeftRed}
							className="animated-linear"
							onClick={
								disabled
									? () => {}
									: () => {
											if (indexV - 1 >= 0) setIndexV(indexV - 1);
											if (indexV - 1 === -1)
												setIndexV(variacoes[prod.ID].length - 1);
									  }
							}
						/>
						<div>
							{variacoes[prod.ID].map((v, i2) =>
								i2 !== indexV ? (
									<span key={`dot-${i2}`} />
								) : (
									<a key={`dot-${i2}`} />
								),
							)}
						</div>
						<img
							alt="arrow-right"
							src={icons.arrowRightRed}
							className="animated-linear"
							onClick={
								disabled
									? () => {}
									: () => {
											if (indexV + 1 !== variacoes[prod.ID].length)
												setIndexV(indexV + 1);
											if (indexV + 1 === variacoes[prod.ID].length)
												setIndexV(0);
									  }
							}
						/>
					</s.Arrows>
				)}

				<div className="middle">
					<div
						className={`img-container ${
							variacoes &&
							variacoes[prod.ID] &&
							variacoes[prod.ID][indexV] &&
							variacoes[prod.ID][indexV].IMAGEM_GRANDE
								? ` bg-white`
								: ` bg-gray`
						}`}>
						{variacoes && variacoes[prod.ID] && variacoes[prod.ID][indexV] && (
							<img
								alt="img-produto"
								src={variacoes[prod.ID][indexV].IMAGEM_GRANDE}
							/>
						)}
					</div>
					<Button
						label={`Variações`}
						disabled={disabled || prod.t}
						onClick={
							disabled
								? () => {}
								: () => {
										setIndexProd(i);
										setOpenModal(2);
								  }
						}
					/>
					<s.Valores>
						<div>
							<label>Vitrine</label>
							<span>
								<label>R$</label>
								<input
									disabled
									value={
										variacoes &&
										variacoes[prod.ID] &&
										variacoes[prod.ID][indexV]
											? variacoes[prod.ID][indexV].PRECO_VITRINE
											: ""
									}
									onChange={() => {}}
									type="number"
									placeholder={"00,00"}
									id={`vitrine-variacao-${prod.ID}`}
								/>
							</span>
						</div>
						<div>
							<label>Venda</label>
							<span>
								<label>R$</label>
								<input
									disabled
									value={
										variacoes &&
										variacoes[prod.ID] &&
										variacoes[prod.ID][indexV]
											? variacoes[prod.ID][indexV].PRECO_VENDA
											: ""
									}
									onChange={() => {}}
									type="number"
									placeholder={"00,00"}
									id={`venda-variacao-${prod.ID}`}
								/>
							</span>
						</div>
					</s.Valores>
				</div>
			</s.Variacoes>
		</s.ProdContainer>
	);
};

export default ProdutosTab;
