import React, { useState } from "react";
import * as s from "./styled-produto";
import * as s2 from "./styled-favoritos";
import { icons, images } from "../../assets";
import { formatValor } from "../../utils";
import { Button, Select } from "../../components";
import { useSelector } from "react-redux";
import Carousel from "react-multi-carousel";
import "./styles.css";

import { produtosService } from "../../services";
import Modal from "react-modal";
import { cloneElement } from "react";
Modal.setAppElement(document.getElementById("root"));

const Produto = ({
  items,
  removeProd,
  deleting,
  openColors,
  setGrade,
  pares,
  setPares,
  setAplicar,
  aplicar,
  grade,
  i,
  last,
  deletados,
}) => {
  const grades = useSelector((state) => state.live.grades);
  const user = useSelector((state) => state.usuario.usuario);
  const [changing, setChanging] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [image, setImage] = useState(false);
  const screen = window.innerWidth;

  const renderOpt = (p, field) => {
    let opts = grade[p.docId] ? grade[p.docId].grade : [];
    return opts.map((g, i) => (
      <s.Opt key={`${field}-${g[field]}-${i}`}>{g[field]}</s.Opt>
    ));
  };

  const changeItem = (id, p, ultimaGrade, ultimaQuantidade) => {
    if (changing[id]) clearTimeout(changing[id]);
    let newChanging = changing;
    let gradeChange = ultimaGrade || grade[p.docId].value;
    let quantidadeChange = ultimaQuantidade || pares[p.docId];
    newChanging[id] = setTimeout(() => {
      produtosService.editFavorito(
        p.produto,
        gradeChange,
        quantidadeChange,
        id
      );
    }, 5000);
    setChanging(newChanging);
  };

  const getGrade = (p) => {
    if (p) {
      let gradesPermitidas = p.produto.GRADE_DISPONIVEL.split(",");
      let resp = [];
      grades.forEach((g) => {
        if (gradesPermitidas.includes(g.id))
          resp.push({
            value: g.id,
            label: `Grade ${g.id}`,
          });
      });
      return resp;
    }
  };

  const renderModal = () => {
    if (image)
      return (
        <Modal
          isOpen={image ? true : false}
          style={s.modalStyles}
          onRequestClose={() => setImage(false)}
        >
          <s2.ModalContainer style={{ maxWidth: "100%", borderRadius: "4px" }}>
            <img
              alt="icone-fechar-modal"
              src={icons.close}
              style={{
                width: "18px",
                height: "18px",
                position: "absolute",
                right: "20px",
                top: "20px",
                cursor: "pointer",
              }}
              onClick={() => setImage(false)}
            />
            <img
              alt="produto-imagem-grande"
              src={image}
              style={{ width: "100%", borderRadius: "4px" }}
            />
          </s2.ModalContainer>
        </Modal>
      );
  };

  const renderPares = (p) => {
    let label = "Pares";
    if (parseInt(grade[p.docId].value) === 60) label = "Unidades";
    return (
      <div className="opt">
        <label className="label-grade">{label}</label>
        <s.Pares>
          <button
            onClick={() => {
              setPares({
                ...pares,
                [p.docId]: pares[p.docId] - grade[p.docId].gradeTotal,
              });
              changeItem(
                p.docId,
                p,
                false,
                pares[p.docId] - grade[p.docId].gradeTotal
              );
            }}
            disabled={
              (pares[p.docId] && pares[p.docId] === 0) || !pares[p.docId]
            }
          >
            -
          </button>
          <label>{pares[p.docId] ? pares[p.docId] : 0}</label>
          <button
            onClick={() => {
              setPares({
                ...pares,
                [p.docId]: pares[p.docId]
                  ? pares[p.docId] + grade[p.docId].gradeTotal
                  : grade[p.docId].gradeTotal,
              });
              changeItem(
                p.docId,
                p,
                false,
                pares[p.docId] + grade[p.docId].gradeTotal
              );
            }}
          >
            +
          </button>
        </s.Pares>
      </div>
    );
  };

  const renderInfos = (prod) => {
    if (screen <= 800) {
      return (
        <s.Infos ocultaPreco={user.ocultaPreco}>
          <h1 className="title"> {prod.DESCRICAO_PRODUTO}</h1>
          <Button label={"Nova cor"} onClick={() => openColors(prod.ID)} />
        </s.Infos>
      );
    } else {
      return (
        <s.Infos ocultaPreco={user.ocultaPreco}>
          <h1 className="title"> {prod.DESCRICAO_PRODUTO}</h1>
          <div className="precos">
            <label>
              PREÇO: <br />
              R$
              <strong>{formatValor(prod.PRECO_VENDA, true)}</strong>
            </label>
            <label>
              VITRINE: <br />
              R$
              <strong>{formatValor(prod.PRECO_VITRINE, true)}</strong>
            </label>
          </div>
          <Button
            label={"Adicionar nova cor"}
            onClick={() => openColors(prod.ID)}
          />
        </s.Infos>
      );
    }
  };

  const renderAplicarGrade = (p, mobile) => {
    return (
      <div className="opt2" style={mobile && { minHeight: "0px" }}>
        {/* <Checkbox onChange={() => setAplicar(!aplicar)} value={aplicar} />
        Aplicar a grade a todas as cores deste modelo. */}
      </div>
    );
  };

  const renderContentResponsive = () => {
    if (items) {
      if (screen >= 1100)
        return items.map((p) => {
          const prod = p.produto;
          let label = "Pares";
          if (parseInt(grade[p.docId].value) === 60) label = "Unidades";
          if (p && prod && !deletados.includes(p.docId))
            return (
              <s.ProdContainer
                key={p.docId}
                className={`animated-linear ${
                  deleting && deleting.docId === p.docId ? "del" : "fade-in"
                }`}
                // className={`animated-linear fade-in`}
                white={i % 2 === 0}
                last={last && "sim"}
              >
                <img
                  alt="icone-deletar-desktop"
                  src={icons.trash}
                  className="trash-delete"
                  onClick={() => !deleting && removeProd(p)}
                />
                <s.Produto ocultaPreco={user.ocultaPreco}>
                  <img
                    alt="imagem-produto-favoritos"
                    src={prod.IMAGEM_PEQUENA || images.default}
                    style={{ cursor: "pointer" }}
                    onClick={() => setImage(prod.IMAGEM_GRANDE)}
                    id={`imagem-produto-live-${prod.docId}`}
                    onError={() => {
                      let e = document.getElementById(
                        `imagem-produto-live-${prod.docId}`
                      );
                      e.src = images.default;
                    }}
                  />
                  {renderInfos(prod)}
                  <s.Grades>
                    <div className="opt" style={{ marginBottom: "auto" }}>
                      <label className="label-grade">Grade</label>
                      <Select
                        value={grade[p.docId]}
                        onChange={(v) => {
                          grades.map((g) => {
                            if (g.id === v.value) {
                              setGrade({
                                ...grade,
                                [p.docId]: {
                                  ...v,
                                  grade: g.tamanXquantid,
                                  gradeTotal: g.gradeTotal,
                                },
                              });
                              setPares({
                                ...pares,
                                [p.docId]: g.gradeTotal,
                              });
                              changeItem(p.docId, p, v.value, g.gradeTotal);
                            }
                          });
                        }}
                        options={getGrade(p)}
                      />
                    </div>
                    {renderPares(p)}
                  </s.Grades>
                  <s.Tamanhos>
                    <div className="opt" style={{ marginBottom: "auto" }}>
                      <label className="label-tamanho">Tamanho</label>
                      <div className="tamanhos-container">
                        {renderOpt(p, "tamanho")}
                      </div>
                    </div>
                    <div className="opt">
                      <label className="label-tamanho">{label}</label>
                      <div className="tamanhos-container">
                        {renderOpt(p, "quantidade")}
                      </div>
                    </div>
                  </s.Tamanhos>
                  <s.Valor ocultaPreco={user.ocultaPreco}>
                    {renderAplicarGrade(p)}
                    <div className="opt">
                      <label className="label-valor">Valor total:</label>
                      <div className="valores-container">
                        {formatValor(
                          pares[p.docId] ? pares[p.docId] * prod.PRECO_VENDA : 0
                        )}
                      </div>
                    </div>
                  </s.Valor>
                </s.Produto>
              </s.ProdContainer>
            );
        });
      else if (screen >= 800 && screen < 1100)
        return items.map((p) => {
          const prod = p.produto;
          if (p && prod && !deletados.includes(p.docId))
            return (
              <s.ProdContainer
                key={p.docId}
                className={`animated-linear ${
                  deleting && deleting.docId === p.docId ? "del" : "fade-in"
                }`}
                // className={`animated-linear fade-in`}
                white={i % 2 === 0}
                last={last && "sim"}
              >
                <img
                  alt="icone-deletar-ipad"
                  src={icons.trash}
                  className="trash-delete"
                  onClick={() => !deleting && removeProd(p)}
                />
                <s.Produto ocultaPreco={user.ocultaPreco}>
                  <img
                    alt="imagem-produto-ipad"
                    src={prod.IMAGEM_PEQUENA}
                    style={{ cursor: "pointer" }}
                    onClick={() => setImage(prod.IMAGEM_GRANDE)}
                    id={`imagem-produto-live-${prod.docId}`}
                    onError={() => {
                      let e = document.getElementById(
                        `imagem-produto-live-${prod.docId}`
                      );
                      e.src = images.default;
                    }}
                  />
                  {renderInfos(prod)}
                  <s.Grades>
                    <div className="opt" style={{ marginBottom: "auto" }}>
                      <label className="label-grade">Grade</label>
                      <s.FalseSelect onClick={() => setIsOpen(p)}>
                        {grade[p.docId].label}
                        <img
                          alt="icone-seta-direita"
                          src={icons.arrowRight}
                          style={{ padding: "0 10px" }}
                        />
                      </s.FalseSelect>
                    </div>
                    {renderPares(p)}
                  </s.Grades>
                  <s.Valor
                    style={{ marginLeft: "20px" }}
                    ocultaPreco={user.ocultaPreco}
                  >
                    {renderAplicarGrade(p)}
                    <div className="opt">
                      <label className="label-valor">Valor total:</label>
                      <div className="valores-container">
                        {formatValor(
                          pares[p.docId] ? pares[p.docId] * prod.PRECO_VENDA : 0
                        )}
                      </div>
                    </div>
                  </s.Valor>
                </s.Produto>
              </s.ProdContainer>
            );
        });
      else
        return items.map((p) => {
          const prod = p.produto;
          if (p && prod && !deletados.includes(p.docId))
            return (
              <s.ProdContainer
                key={p.docId}
                className={`animated-linear ${
                  deleting && deleting.docId === p.docId ? "del" : "fade-in"
                }`}
                // className={`animated-linear fade-in`}
                white={i % 2 === 0}
                last={last && "sim"}
              >
                <img
                  alt="icone-deletar-mobile"
                  src={icons.trash}
                  className="trash-delete"
                  onClick={() => !deleting && removeProd(p)}
                />
                <s.Produto ocultaPreco={user.ocultaPreco}>
                  <div>
                    <div style={{ display: "flex" }}>
                      <img
                        alt="imagem-produto-mobile"
                        src={prod.IMAGEM_PEQUENA}
                        onClick={() => setImage(prod.IMAGEM_GRANDE)}
                        style={{
                          width: "70px",
                          height: "50px",
                          cursor: "pointer",
                        }}
                        id={`imagem-produto-live-${prod.docId}`}
                        onError={() => {
                          let e = document.getElementById(
                            `imagem-produto-live-${prod.docId}`
                          );
                          e.src = images.default;
                        }}
                      />
                      {renderInfos(prod)}
                    </div>
                    <s.Grades>
                      {renderPares(p)}
                      <div className="opt" style={{ marginBottom: "auto" }}>
                        <label className="label-grade">Grade</label>
                        <s.FalseSelect onClick={() => setIsOpen(p)}>
                          {grade[p.docId].label}
                        </s.FalseSelect>
                      </div>
                    </s.Grades>
                  </div>
                  <div className="precos">
                    <label>
                      PREÇO: <br />
                      R$
                      <strong>{formatValor(prod.PRECO_VENDA, true)}</strong>
                    </label>
                    <label>
                      VITRINE: <br />
                      R$
                      <strong>{formatValor(prod.PRECO_VITRINE, true)}</strong>
                    </label>
                    <label>
                      TOTAL: <br />
                      R$
                      <strong>
                        {formatValor(
                          pares[p.docId]
                            ? pares[p.docId] * prod.PRECO_VENDA
                            : 0,
                          true
                        )}
                      </strong>
                    </label>
                  </div>
                </s.Produto>
              </s.ProdContainer>
            );
        });
    }
  };

  return (
    <s.Container white={i % 2 === 0} last={last && "sim"}>
      {renderModal()}
      {screen < 1100 && (
        <Modal
          isOpen={isOpen ? true : false}
          style={s2.customStyles}
          onRequestClose={() => setIsOpen(false)}
        >
          <s2.ModalContainer>
            <div className="title">
              <h1>GRADE</h1>
            </div>
            <div className="list">
              <Select
                value={grade[isOpen.docId]}
                customStyles={s.customStyles}
                onChange={(v) => {
                  grades.map((g) => {
                    if (g.id === v.value) {
                      setGrade({
                        ...grade,
                        [isOpen.docId]: {
                          ...v,
                          grade: g.tamanXquantid,
                        },
                      });
                      setGrade({
                        ...grade,
                        [isOpen.docId]: {
                          ...v,
                          grade: g.tamanXquantid,
                          gradeTotal: g.gradeTotal,
                        },
                      });
                      setPares({
                        ...pares,
                        [isOpen.docId]: g.gradeTotal,
                      });
                      changeItem(isOpen.docId, isOpen, v.value, g.gradeTotal);
                    }
                  });
                }}
                options={getGrade(isOpen)}
              />
              <s.Tamanhos style={{ marginTop: "15px" }}>
                <div className="opt" style={{ marginBottom: "auto" }}>
                  <label className="label-tamanho">Tamanho</label>
                  <div className="tamanhos-container">
                    {renderOpt(isOpen, "tamanho")}
                  </div>
                </div>
                {isOpen && parseInt(grade[isOpen.docId].value) !== 60 && (
                  <div className="opt">
                    <label className="label-tamanho">Pares</label>
                    <div className="tamanhos-container">
                      {renderOpt(isOpen, "quantidade")}
                    </div>
                  </div>
                )}
              </s.Tamanhos>
              <s.Valor
                style={{ maxWidth: "295px", width: "100%" }}
                ocultaPreco={user.ocultaPreco}
              >
                <div
                  className="opt"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <label className="label-par">
                      {isOpen && parseInt(grade[isOpen.docId].value) === 60
                        ? "Unidades"
                        : "Pares"}
                    </label>
                    <div className="pares-container">
                      <label>
                        {pares[isOpen.docId] ? pares[isOpen.docId] : 0}
                      </label>
                    </div>
                  </div>
                  <div>
                    <label className="label-valor">Valor total:</label>
                    <div className="valores-container">
                      {formatValor(
                        pares[isOpen.docId]
                          ? pares[isOpen.docId] * isOpen.produto.PRECO_VENDA
                          : 0
                      )}
                    </div>
                  </div>
                </div>
                {renderAplicarGrade(isOpen, true)}
              </s.Valor>
              <Button
                label={"Confirmar"}
                onClick={() => setIsOpen(false)}
                style={{ margin: "10px auto 0 auto" }}
              />
            </div>
          </s2.ModalContainer>
        </Modal>
      )}
      <Carousel
        additionalTransfrom={0}
        arrows={screen < 1100 ? false : true}
        centerMode={false}
        className=""
        containerClass="container"
        dotListClass=""
        draggable={items.length > 0 ? true : false}
        infinite={items.length > 0 ? true : false}
        swipeable={items.length > 0 ? true : false}
        focusOnSelect={false}
        itemClass=""
        keyBoardControl={false}
        minimumTouchDrag={80}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={{
          desktop: {
            breakpoint: {
              max: 5000,
              min: 1100,
            },
            items: 1,
          },
          tablet: {
            breakpoint: {
              max: 1100,
              min: 801,
            },
            items: 1,
          },
          mobile: {
            breakpoint: {
              max: 800,
              min: 0,
            },
            items: 1,
          },
        }}
        showDots
        sliderClass=""
        slidesToSlide={1}
      >
        {renderContentResponsive()}
      </Carousel>
    </s.Container>
  );
};

export default Produto;
