import React, { useEffect, useState } from "react";
import * as s from "./styled-player.js";
import { useSelector } from "react-redux";
import { lives, produtosService } from "../../services";
import { icons, images } from "../../assets/index.js";
import enableInlineVideo from "iphone-inline-video";
import { INTRO_KEY, moveToElement } from "../../utils/index.js";
import Hls from "hls.js";

var isIOS =
  (/iPad|iPhone|iPod/.test(navigator.platform) ||
    (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)) &&
  !window.MSStream;

const PlayerFakeLiveIntro = () => {
  //data
  const usuario = useSelector((state) => state.usuario.usuario);
  let url;
  let videoUrl;
  let ladoCard = "esquerda";
  let realtime = false;
  let aoVivo = false;
  let id;
  if (usuario && usuario.live) {
    // url = usuario.live.live.split(".m3u8")[0] + ".m3u8";
    url = usuario.live.live;
    videoUrl = usuario.live.intro;
    ladoCard = usuario.live.ladoCard;
    realtime = usuario.live.realtime;
    aoVivo = usuario.live.transmissaoAoVivo;
    id = usuario.live.id;
  }
  const live = useSelector((state) => state.live);
  const { favoritos, produtos, grades } = live;
  //control
  const [mostrar, setMostrar] = useState([]);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [muted, setMuted] = useState(true);
  const [rodando, setRodando] = useState(false);
  const [timeVideo, setTimeVideo] = useState(0);
  const [first, setFirst] = useState(true);
  const [intro, setIntro] = useState(true);
  //hls
  const [levels, setLevels] = useState();
  const [levelSeleted, setLevel] = useState();
  const [hlsRef, setHlsRef] = useState();
  //video & slider
  const video = document.getElementById("video");
  var slider = document.querySelector(".slider");
  if (video) enableInlineVideo(video);

  function formatTime(seconds) {
    if (seconds) {
      if (intro && video) {
        var now = new Date().getTime() + seconds * 1000;
        var countDownDate =
          new Date().getTime() + parseInt(video.duration) * 1000;
        var distance = countDownDate - now;
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);
        return duas_casas(minutes) + ":" + duas_casas(seconds);
      } else {
        if (slider && parseInt(slider.max) <= parseInt(timeVideo + 5))
          return "Ao vivo";
        else return new Date(seconds * 1000).toISOString().substr(11, 8);
      }
    }
  }

  const playVideo = (urlBase) => {
    if (video) {
      video.src = urlBase;
      if (localStorage.getItem(INTRO_KEY + "_time_video") && !aoVivo)
        video.currentTime = localStorage.getItem(INTRO_KEY + "_time_video");
      playAndPause(true);
    }
  };
  const playHls = (urlBase) => {
    if (Hls.isSupported() && aoVivo) {
      isIOS = false;
      var hls = new Hls({
        capLevelToPlayerSize: false,
        startFragPrefetch: true,
        startLevel: 0,
        currentLevel: 0,
        startPosition: -1,
        liveSyncDurationCount: 9,
      });
      hls.attachMedia(video);
      hls.on(Hls.Events.MEDIA_ATTACHED, function () {
        hls.loadSource(urlBase);
        hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
          setLevels(data.levels);
          hls.currentLevel = 1;
          setLevel(data.levels[1]);
          playAndPause();
          setHlsRef(hls);
        });
      });
      hls.on(Hls.Events.ERROR, function (event, data) {
        if (data.fatal) {
          switch (data.type) {
            case Hls.ErrorTypes.NETWORK_ERROR:
              console.log("fatal network error encountered, try to recover");
              hls.startLoad();
              break;
            case Hls.ErrorTypes.MEDIA_ERROR:
              console.log("fatal media error encountered, try to recover");
              hls.recoverMediaError();
              break;
            default:
              console.log("other error encountered");
              hls.destroy();
              break;
          }
        }
      });
    } else if (
      video &&
      (video.canPlayType("application/vnd.apple.mpegurl") || !aoVivo)
    ) {
      if (aoVivo) isIOS = true;
      video.src = urlBase;
      video.addEventListener("loadedmetadata", function () {
        playAndPause(true);
      });
    }
  };

  useEffect(() => {
    if (localStorage.getItem(INTRO_KEY) || !videoUrl) {
      setIntro(false);
      if (aoVivo) playHls(url);
      else playVideo(url);
    } else playVideo(videoUrl);
    let principais = [];
    produtos.forEach((prod) => {
      if (!prod.COMBO && (prod.PRINCIPAL === "S" || prod.OWNER)) {
        principais.push(prod);
      }
    });
    principais.sort((a, b) => a.TEMPO_INICIAL - b.TEMPO_INICIAL);
    setMostrar(principais);
  }, [produtos]);

  useEffect(() => {
    if (realtime && id) {
      lives
        .doc(id)
        .collection("realtime")
        .onSnapshot(async (snapshot) => {
          let prods = [];
          snapshot.docs.map((doc) =>
            prods.push({ ...doc.data(), position: doc.id })
          );
          let prod1 = prods.filter((p) => p.position === "1")[0];
          let prod2 = prods.filter((p) => p.position === "2")[0];
          let aff = await produtosService.getFavoritos();
          let exist1 = false;
          let exist2 = false;
          aff.forEach((p) => {
            if (
              prod1 &&
              p.produto.ID === prod1.ID &&
              p.produto.COR === prod1.COR
            )
              exist1 = true;
            if (
              prod2 &&
              p.produto.ID === prod2.ID &&
              p.produto.COR === prod2.COR
            )
              exist2 = true;
          });
          if (!exist1) setShow(prod1);
          else setShow();
          if (!exist2) setShow2(prod2);
          else setShow2();
        });
    }
  }, [usuario, favoritos]);

  function duas_casas(numero) {
    if (numero <= 9) numero = "0" + numero;
    return numero + "";
  }

  useEffect(() => {
    if (slider && video && video.duration && !intro) {
      let dataInicio = new Date(usuario.live.dataInicio).getTime();
      let agora = new Date().getTime();
      let fim = dataInicio + video.duration * 1000;
      if (fim > agora) slider.max = (agora - dataInicio) / 1000;
      else slider.max = video ? video.duration : 0;
    }
    if (timeVideo > 0)
      localStorage.setItem(INTRO_KEY + "_time_video", timeVideo);
    if (!realtime && document.getElementById("video") && rodando && !intro) {
      var s = timeVideo;
      if (s > 0) {
        let hora = duas_casas(Math.trunc(s / 3600));
        let minuto = duas_casas(Math.trunc((s % 3600) / 60));
        let segundo = duas_casas((s % 3600) % 60).substring(0, 2);
        if (segundo === "9.") segundo = "09";
        let formatado = hora + ":" + minuto + ":" + segundo;
        mostrar.forEach((p) => {
          var timeFinal = p.TEMPO_FINAL;
          if (formatado === timeFinal && show.docId === p.docId) {
            if (show2) {
              setShow(show2);
              setShow2(false);
            } else setShow(false);
          }
          if (formatado === timeFinal && show2.docId === p.docId)
            setShow2(false);
          if (!show || !show2) {
            var time = p.TEMPO_INICIAL;

            if (formatado === time) {
              if (!show) setShow(p);
              else if (show && show.docId !== p.docId && !show2) setShow2(p);
            }
          }
        });
      }
    }
  }, [timeVideo]);

  const playAndPause = (auto) => {
    if (video) {
      if (first) setFirst(false);
      if (video.paused) {
        if (!auto) setMuted(false);
        video.play();
        setRodando(true);
      } else {
        video.pause();
        setRodando(false);
      }
    }
  };

  const addItemCombo = (combo, elementID) => {
    let id = "hearth-favoritos";
    if (window.innerWidth <= 1450) id = id + "-small";
    moveToElement(elementID, id);
    produtos.map((principal) => {
      if (principal.COMBO_ID && !principal.OWNER) {
        if (combo.PRODUTOS.includes(principal.docId)) {
          let gradeTotal;
          grades.forEach((g) => {
            if (g.id === principal.GRADE_DISPONIVEL.split(",")[0])
              gradeTotal = g.gradeTotal;
          });
          produtosService.addFavorito(
            principal,
            principal.GRADE_DISPONIVEL.split(",")[0],
            gradeTotal,
            principal.docId
          );
        }
      }
    });
  };

  const setItem = (showClicked, elementID) => {
    if (showClicked.OWNER) addItemCombo(showClicked, elementID);
    else {
      let id = "hearth-favoritos";
      if (window.innerWidth <= 1450) id = id + "-small";
      moveToElement(elementID, id);
      setTimeout(() => {
        if (showClicked && show2 && showClicked.docId === show2.docId)
          setShow2(false);
        else setShow(false);
      }, 2000);
      if (!verifyHearth(showClicked)) {
        let gradeTotal;
        grades.forEach((g) => {
          if (
            showClicked.GRADE_DISPONIVEL &&
            g.id === showClicked.GRADE_DISPONIVEL.split(",")[0]
          )
            gradeTotal = g.gradeTotal;
        });
        produtosService.addFavorito(
          showClicked,
          showClicked.GRADE_DISPONIVEL.split(",")[0],
          gradeTotal,
          showClicked.docId
        );
      }
    }
  };

  const verifyHearth = (prod) => {
    let test = false;
    favoritos.forEach((p) => {
      if (p.produto.ID === prod.ID && p.produto.COR === prod.COR) test = true;
    });
    return test;
  };

  var cem = document.querySelector(".controls")
    ? document.querySelector(".controls").clientWidth - 70
    : 0;

  return (
    <s.Container id="player-container" disabled={intro}>
      {show && show.IMAGEM_VIDEO_LIVE && (
        <s.Poppup
          ladoCard={ladoCard}
          src={show.IMAGEM_VIDEO_LIVE}
          // src={images.produto}
          onClick={() => setItem(show, `poppup-live-${show.docId}`)}
          className={`animated-linear fade-in`}
          id={`poppup-live-${show.docId}`}
          onError={() => {
            let e = document.getElementById(`poppup-live-${show.docId}`);
            e.src = images.default;
          }}
        />
      )}
      {show2 && show2.IMAGEM_VIDEO_LIVE && (
        <s.Poppup
          ladoCard={ladoCard}
          src={show2.IMAGEM_VIDEO_LIVE}
          // src={images.produto}
          onClick={() => setItem(show2, `poppup-live-${show2.docId}`)}
          className={`animated-linear fade-in`}
          bottom
          id={`poppup-live-${show2.docId}`}
          onError={() => {
            let e = document.getElementById(`poppup-live-${show2.docId}`);
            e.src = images.default;
          }}
        />
      )}
      {first || !rodando || (intro && video && video.paused) ? (
        <img
          alt="icone-play-player-fake-live"
          src={icons.play}
          style={{
            position: "absolute",
            top: "50%",
            left: "49%",
            width: "30px",
            height: "30px",
          }}
          onClick={playAndPause}
        />
      ) : null}
      {rodando && muted && (
        <img
          alt="icone-mudo-player-fake-live"
          src={icons.mute}
          style={{
            position: "absolute",
            top: "50%",
            left: "49%",
            width: "30px",
            height: "30px",
            zIndex: "3",
          }}
          onClick={() => setMuted(false)}
        />
      )}

      <video
        onContextMenu={(e) => e.preventDefault()}
        muted={muted}
        controls={false}
        playsInline
        id="video"
        className="video"
        onClick={() => {
          if (muted) setMuted(false);
          else if (intro || window.innerWidth >= 1010) playAndPause();
        }}
        onTimeUpdate={(e) => {
          slider.value = e.currentTarget.currentTime;
          setTimeVideo(e.currentTarget.currentTime);
        }}
        onEnded={() => {
          if (intro) {
            localStorage.removeItem(INTRO_KEY + "_time_video");
            setTimeVideo(0);
            setIntro(false);
            if (aoVivo) playHls(url);
            else playVideo(url);
            localStorage.setItem(INTRO_KEY, true);
            slider.value = 0;
          } else setRodando(false);
        }}
      ></video>
      <div className="controls">
        <div className="buttons">
          <button id="play-pause" onClick={playAndPause}>
            <img
              alt="icone-rodando-pause-player-live-intro"
              src={rodando ? icons.pause : icons.play}
            />
          </button>
        </div>
        <input
          className="slider"
          disabled={intro}
          type="range"
          max={video && !intro ? video.duration : 0}
          style={{
            background: "white",
            width: video
              ? !intro
                ? "100%"
                : (video.currentTime / video.duration) * cem + "px"
              : "0%",
          }}
          onChange={(e) => {
            video.currentTime = e.target.value;
            setShow(false);
            setShow2(false);
          }}
        />
        {intro && <div className="base-intro" />}
        <label
          style={{
            margin: 0,
            minWidth: "60px",
            fontSize: "13px",
            marginLeft: "5px",
          }}
        >
          {formatTime(timeVideo) || "00:00:00"}
        </label>
        {!intro && (
          <label
            className="go-live"
            onClick={() => {
              if (video && slider) video.currentTime = slider.max;
            }}
          >
            Go Live
          </label>
        )}
      </div>
    </s.Container>
  );
};

export default PlayerFakeLiveIntro;
