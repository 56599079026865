import React, { useState, useEffect, useCallback, useRef } from "react";
import * as s from "./styled-combos-tab";
import { Scrollbars } from "react-custom-scrollbars";
import { icons } from "../../../../assets";
import { Button, MiniPlayer } from "../../../../components";
import CombosModal from "./combos-modal";
import { managerService } from "../../../../services";
import { handleChangeTime } from "../../../../utils";

const CombosTab = ({ visible, liveId, next, old, realtime }) => {
	const [combos, setCombos] = useState([]);
	const [saving, setSaving] = useState(false);
	const [loading, setLoading] = useState(true);
	const [openModal, setOpenModal] = useState(false); //1=cards/2=produtos
	const [indexCombo, setIndexCombo] = useState(false);
	const [changes, setChanges] = useState(false);
	const getElement = (id) => document.getElementById(id);

	const scrollerRef = useRef(null);

	useEffect(() => {
		managerService
			.buscarCombos(liveId)
			.then((resp) => formatData(resp))
			.catch((e) => {
				alert(e);
				setLoading(false);
			});
	}, []);

	// console.log('liveId', liveId);

	const formatData = (resp) => {
		let owners = resp.filter((p) => p.OWNER);
		let data = owners.map((o) => ({
			...o,
			PRODUTOS: resp
				.filter((p) => p.COMBO_ID === o.COMBO_ID && !p.OWNER)
				.map((prod) => ({
					...prod,
					PRECO_VITRINE: parseFloat(prod.PRECO_VITRINE).toFixed(2),
					PRECO_VENDA: parseFloat(prod.PRECO_VENDA).toFixed(2),
					TEMPO_INICIAL: prod.TEMPO_INICIAL.substr(3, 5),
					TEMPO_FINAL: prod.TEMPO_FINAL.substr(3, 5),
				})),
		}));
		setCombos(data);
		setLoading(false);
		setSaving(false);
		if (data.length > 0)
			data.forEach((p, i) => {
				getElement(`inicial-combo-${i}`).value = p.TEMPO_INICIAL.substr(3, 5);
				getElement(`final-combo-${i}`).value = p.TEMPO_FINAL.substr(3, 5);
			});
	};

	const saveData = () => {
		if ((!combos || (combos && combos.length === 0)) && !old) next();
		else {
			let data = combos.map((c, i) => ({
				...c,
				COMBO_ID: i + 1,
				TEMPO_INICIAL: `00:${
					getElement(`inicial-combo-${i}`) &&
					getElement(`inicial-combo-${i}`).value
						? getElement(`inicial-combo-${i}`).value
						: "00:00"
				}`,
				TEMPO_FINAL: `00:${
					getElement(`final-combo-${i}`) && getElement(`final-combo-${i}`).value
						? getElement(`final-combo-${i}`).value
						: "00:00"
				}`,
				PRODUTOS: c.PRODUTOS.map((p) => p.id_doc),
			}));

			// verify timer's error
			const videoDuration = document.getElementById("video")?.duration || 0;
			const convertedVideoDuration = `00:${new Date(videoDuration * 1000)
				.toISOString()
				.substr(14, 5)}`;

			const hasIndexError = data.findIndex(
				(item, index) =>
					item.TEMPO_FINAL < item.TEMPO_INICIAL ||
					convertedVideoDuration < item.TEMPO_FINAL,
			);

			if (hasIndexError >= 0) {
				setSaving(false);

				scrollerRef.current.scrollTop(265 * hasIndexError);
				return alert("O tempo do produto contém erros!");
			}

			setSaving(true);

			let post = data.filter((c) => !c.id_doc_combo);
			let put = data.filter((c) => c.id_doc_combo);
			let hasPost = false;
			let hasPut = false;

			// console.log('saveData', data);

			if (post && post.length > 0) hasPost = true;
			if (put && put.length > 0) hasPut = true;

			if (hasPost && hasPut) {
				Promise.all([
					managerService.cadastrarCombos(liveId, post),
					managerService.alterarCombos(liveId, put),
				])
					.then(() => {
						managerService
							.buscarCombos(liveId)
							.then((resp) => formatData(resp));
						setSaving(false);
						setChanges(false);
						if (!old) next();
					})
					.catch(() => {
						alert(
							`Ocorreu um erro ao salvar suas alterações. Atualize a página e tente novamente!`,
						);
						setSaving(false);
					});
			} else {
				if (hasPost)
					managerService
						.cadastrarCombos(liveId, post)
						.then(() => {
							managerService
								.buscarCombos(liveId)
								.then((resp) => formatData(resp));
							setSaving(false);
							setChanges(false);
							if (!old) next();
						})
						.catch((e) => {
							alert(e);
							setSaving(false);
						});
				if (hasPut)
					managerService
						.alterarCombos(liveId, put)
						.then(() => {
							setSaving(false);
							setChanges(false);
						})
						.catch((e) => {
							alert(e);
							setSaving(false);
						});
			}
		}
	};

	const deleteCombo = (id, saved) => {
		setLoading(true);
		if (saved) {
			managerService
				.deletarCombo(liveId, id)
				.then(() => {
					setCombos([]);
					managerService
						.buscarCombos(liveId)
						.then((resp) => formatData(resp))
						.catch((e) => {
							alert(e);
							setLoading(false);
							setChanges(false);
						});
				})
				.catch((e) => {
					alert(e);
					setLoading(false);
				});
		} else {
			setCombos(combos.filter((c, i) => i !== id));
			setLoading(false);
			setChanges(false);
		}
	};

	return (
		<>
			<s.Container visible={visible} className="fade-in">
				{openModal && (
					<CombosModal
						liveId={liveId}
						i={indexCombo}
						isOpen={openModal}
						produtosCombo={combos[indexCombo]}
						onRequestClose={() => setOpenModal(false)}
						next={(data) => {
							if (openModal === 1) {
								let newCombos = combos;
								combos[indexCombo] = {
									...combos[indexCombo],
									IMAGEM_VIDEO_LIVE: data,
								};
								setCombos(newCombos);
							} else {
								let newCombos = combos;
								combos[indexCombo] = { ...combos[indexCombo], PRODUTOS: data };
								setCombos(newCombos);
							}
							setOpenModal(false);
							setChanges(true);
						}}
					/>
				)}
				<section style={{ margin: "15px 10px 5px" }}>
					{!realtime && (
						<>
							<s.Title>Definir set time</s.Title>
							<MiniPlayer
								combo
								produtos={
									combos &&
									combos.map((c, i) => ({
										...c,
										COMBO_ID: i + 1,
										TEMPO_INICIAL: `00:${
											getElement(`inicial-combo-${i}`) &&
											getElement(`inicial-combo-${i}`).value
												? getElement(`inicial-combo-${i}`).value
												: "00:00"
										}`,
										TEMPO_FINAL: `00:${
											getElement(`final-combo-${i}`) &&
											getElement(`final-combo-${i}`).value
												? getElement(`final-combo-${i}`).value
												: "00:00"
										}`,
										PRODUTOS: c.PRODUTOS && c.PRODUTOS.map((p) => p.id_doc),
									}))
								}
								ladoCard={localStorage.getItem("LADO_CARD") || "esquerdo"}
								style={{
									maxWidth: "460px",
								}}
								url={
									localStorage.getItem("URL") || `https://youtu.be/-ZXhUxl9Fb0`
								}
							/>
						</>
					)}
					<Button
						label={
							loading
								? "Carregando"
								: saving
								? "Salvando"
								: old
								? "Salvar"
								: "Próximo"
						}
						style={{
							width: "200px",
							margin: "15px auto 0",
							display: visible ? "flex" : "none",
						}}
						onClick={saveData}
						disabled={saving || loading || !changes}
						isLoading={saving}
					/>
				</section>
				<section className="reduceMargin">
					<s.Title>Combos</s.Title>
					<Scrollbars
						style={{
							height: "780px",
							scrollBehavior: "smooth",
						}}
						ref={scrollerRef}
						renderTrackHorizontal={() => <div />}>
						{combos &&
							[...combos, {}].map((combo, i) => (
								<Combo
									key={`combo-${i + 1}`}
									combo={combo}
									i={i}
									setOpenModal={setOpenModal}
									setIndexCombo={setIndexCombo}
									deleteCombo={deleteCombo}
									disabled={saving || loading}
									changes={changes}
									setChanges={() => {
										setChanges(true);
										setLoading(true);
										setTimeout(() => setLoading(false), 100);
									}}
								/>
							))}
					</Scrollbars>
				</section>
			</s.Container>
		</>
	);
};
const Combo = ({
	combo,
	i,
	deleteCombo,
	disabled,
	setOpenModal,
	setIndexCombo,
	setChanges,
	changes,
}) => {
	const [inicio, setInicio] = useState();
	const [fim, setFim] = useState();
	// const [changing, setChanging] = useState();

	const filterTimer = (timer) => {
		const initialZeros = timer.slice(0, 2);

		if (timer.length > 5 && initialZeros === "00") {
			return timer.substring(3);
		}

		return timer;
	};

	useEffect(() => {
		if (combo?.TEMPO_FINAL) {
			setFim(filterTimer(combo?.TEMPO_FINAL));
		}
		if (combo?.TEMPO_INICIAL) {
			setInicio(filterTimer(combo?.TEMPO_INICIAL));
		}
	}, [combo]);

	useEffect(() => {
		if (
			(inicio !== combo.TEMPO_INICIAL || fim !== combo.TEMPO_FINAL) &&
			!changes
		)
			setChanges(true);
	}, [inicio, fim]);

	const videoDuration = () => {
		if (document.getElementById("video")?.duration) {
			const duration = document.getElementById("video")?.duration || 0;
			const videoFormated = new Date(duration * 1000)
				.toISOString()
				.substr(14, 5);

			if (videoFormated < fim) return true;
			if (fim < inicio) return true;

			return false;
		}
	};

	return (
		<s.ComboContainer key={`combo-${i + 1}`}>
			<s.Combo>
				<s.Legend>
					00{i + 1} <p>combo</p>
				</s.Legend>
				{combo.IMAGEM_VIDEO_LIVE && (
					<s.Del
						alt="delete-icon"
						src={icons.trash}
						onClick={
							disabled
								? () => {}
								: () =>
										deleteCombo(
											combo.id_doc_combo ? combo.id_doc_combo : i,
											combo.id_doc_combo ? true : false,
										)
						}
					/>
				)}
				<s.TimeContainer timerError={videoDuration()}>
					<label>Início do card</label>
					<span>
						<img
							alt={`icone-inicio-${i}`}
							src={icons.timeCard}
							onClick={() => {
								if (document.getElementById("video-combo")) {
									let seconds =
										document.getElementById("video-combo").currentTime;
									let v = new Date(seconds * 1000).toISOString().substr(14, 5);
									if (disabled) return;
									setInicio(v);
								}
							}}
						/>
						{/* {inicio && ( */}
						<input
							disabled={disabled}
							placeholder={"00:00"}
							id={`inicial-combo-${i}`}
							value={inicio}
							onChange={(e) => {
								e.preventDefault();
								handleChangeTime(
									e.target.value || "",
									inicio || "",
									(formatedValue) => {
										setInicio(formatedValue);
									},
								);
							}}
							onDoubleClick={() => {
								if (document.getElementById("video-combo")) {
									let seconds =
										document.getElementById("video-combo").currentTime;
									let v = new Date(seconds * 1000).toISOString().substr(14, 5);
									setInicio(v);
								}
							}}
						/>
						{/* )} */}
					</span>
					<label>Fim do card</label>
					<span>
						<img
							alt={`icone-fim-${i}`}
							src={icons.timeCard}
							onClick={() => {
								if (document.getElementById("video-combo")) {
									let seconds =
										document.getElementById("video-combo").currentTime;
									let v = new Date(seconds * 1000).toISOString().substr(14, 5);
									if (disabled) return;
									setFim(v);
								}
							}}
						/>
						{/* {fim && ( */}
						<input
							disabled={disabled}
							placeholder={"00:00"}
							id={`final-combo-${i}`}
							value={fim}
							onChange={(e) => {
								e.preventDefault();
								handleChangeTime(
									e.target.value || "",
									fim || "",
									(formatedValue) => {
										setFim(formatedValue);
									},
								);
							}}
							onDoubleClick={() => {
								if (document.getElementById("video-combo")) {
									let seconds =
										document.getElementById("video-combo").currentTime;
									let v = new Date(seconds * 1000).toISOString().substr(14, 5);
									setFim(v);
								}
							}}
						/>
						{/* )} */}
					</span>
				</s.TimeContainer>
				<s.CardContainer>
					<s.BlueButton
						className="animated-linear"
						disabled={disabled}
						onClick={() => {
							setIndexCombo(i);
							setOpenModal(1);
						}}>
						Adicionar card
					</s.BlueButton>
					<div
						className={`card${
							combo.IMAGEM_VIDEO_LIVE ? ` bg-white` : ` bg-gray`
						}`}>
						{combo.IMAGEM_VIDEO_LIVE && (
							<img alt="card-img" src={combo.IMAGEM_VIDEO_LIVE} />
						)}
					</div>
				</s.CardContainer>

				<s.ProdsContainer>
					<Button
						label="Selecionar produtos"
						style={{ zIndex: 99, width: "100%" }}
						disabled={false}
						onClick={() => {
							setIndexCombo(i);
							setOpenModal(2);
						}}
					/>
					<div style={{ display: "flex", marginTop: "26px" }}>
						{[0, 1, 2].map((n) => (
							<s.Prod key={"produto-do-combo-" + n}>
								<div
									className={`card-container${
										combo.PRODUTOS && combo.PRODUTOS[n]
											? ` bg-white`
											: ` bg-gray`
									}`}>
									{combo.PRODUTOS && combo.PRODUTOS[n] && (
										<img alt="prod-img" src={combo.PRODUTOS[n].IMAGEM_GRANDE} />
									)}
								</div>
								<s.Valores key={n}>
									<div>
										<label>Vitrine</label>
										<span>
											<label>R$</label>
											<input
												disabled
												value={
													combo.PRODUTOS && combo.PRODUTOS[n]
														? combo.PRODUTOS[n].PRECO_VITRINE
														: ""
												}
												type="number"
												placeholder={"00,00"}
											/>
										</span>
									</div>
									<div>
										<label>Venda</label>
										<span>
											<label>R$</label>
											<input
												disabled
												value={
													combo.PRODUTOS && combo.PRODUTOS[n]
														? combo.PRODUTOS[n].PRECO_VENDA
														: ""
												}
												type="number"
												placeholder={"00,00"}
											/>
										</span>
									</div>
								</s.Valores>
							</s.Prod>
						))}
					</div>
				</s.ProdsContainer>
			</s.Combo>
		</s.ComboContainer>
	);
};

export default CombosTab;
