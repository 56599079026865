import React, { useState, useEffect } from "react";
import * as s from "./styled-live-studio";
import LiveTab from "./tabs/live-tab/live-tab";
import SalasTab from "./tabs/salas-tab/salas-tab";
import PublicarTab from "./tabs/publicar-tab/publicar-tab";
import CardsTab from "./tabs/cards-tab/cards-tab";
import ProdutosTab from "./tabs/produtos-tab/produtos-tab";
import CombosTab from "./tabs/combos-tab/combos-tab";
import RealtimeTab from "./tabs/realtime-tab/realtime-tab";
import AoVivoTab from "./tabs/ao-vivo-tab/ao-vivo-tab";

const LiveStudio = ({ history }) => {
	let { state } = history.location;

	const [stepIndex, setStepIndex] = useState(state?.initialStep || 0);
	const [maxStep, setMaxStep] = useState(0);
	const [id, setId] = useState();
	const [old, setOld] = useState(false);
	const [realtime, setRealtime] = useState(false);
	const [aoVivo, setAoVivo] = useState(false);
	const [steps, setSteps] = useState([
		"Live",
		"Salas",
		"Cards",
		"Produtos",
		"Combos",
		"Publicar",
	]);

	useEffect(() => {
		localStorage.removeItem("salas_preenchidas");
		localStorage.removeItem("live_preenchida");
		localStorage.removeItem("live_publicada");
	}, []);

	useEffect(() => {
		// if (state?.editar) {
		const newState = { ...state, initialStep: stepIndex };
		history.replace({ ...history.location, state: newState });
		// }
	}, [stepIndex]);

	useEffect(() => {
		if (state && state.editar) {
			setOld(true);
			setId(state.id);
		}
	}, [history]);

	useEffect(() => {
		if (aoVivo && steps[steps.length - 1] !== "Transmissão")
			setSteps([...steps, "Transmissão"]);
	}, [aoVivo]);

	useEffect(() => {
		if (stepIndex > maxStep) setMaxStep(stepIndex);

		if (document.getElementById("video")) {
			document.getElementById("video").pause();
		}
		if (document.getElementById("video-combo")) {
			document.getElementById("video-combo").pause();
		}
	}, [stepIndex]);

	return (
		<s.Container>
			<s.Steps>
				{steps.map((step, i) => (
					<s.Step active={stepIndex === i} key={step}>
						<div className="animated-linear">
							<a />
						</div>
						<span
							className="animated-linear"
							onClick={() => {
								if (id) setStepIndex(i);
							}}>
							{step}
						</span>
					</s.Step>
				))}
			</s.Steps>
			{maxStep >= 0 && (
				<LiveTab
					setRealtime={setRealtime}
					setAoVivo={setAoVivo}
					old={old}
					visible={stepIndex === 0}
					liveId={id}
					next={(id) => {
						if (id) {
							setId(id);
							setStepIndex(1);
						}
					}}
					setSteps={(realtime) => {
						if (realtime && steps[steps.length - 1] !== "Real-time")
							setSteps([...steps, "Real-time"]);
						else if (steps[steps.length - 1] === "Real-time")
							setSteps(steps.splice(0, 6));
					}}
				/>
			)}
			{maxStep >= 1 && (
				<SalasTab
					liveId={id}
					aoVivo={aoVivo}
					old={old}
					visible={stepIndex === 1}
					next={() => setStepIndex(2)}
				/>
			)}
			{maxStep >= 2 && (
				<CardsTab
					liveId={id}
					old={old}
					visible={stepIndex === 2}
					next={() => setStepIndex(3)}
				/>
			)}
			{maxStep >= 3 && (
				<ProdutosTab
					realtime={realtime}
					liveId={id}
					old={old}
					visible={stepIndex === 3}
					next={() => setStepIndex(4)}
				/>
			)}
			{maxStep >= 4 && (
				<CombosTab
					realtime={realtime}
					liveId={id}
					old={old}
					visible={stepIndex === 4}
					next={() => setStepIndex(5)}
				/>
			)}
			{maxStep >= 5 && <PublicarTab liveId={id} visible={stepIndex === 5} />}
			{maxStep >= 6 && realtime && (
				<RealtimeTab liveId={id} visible={stepIndex === 6} />
			)}
			{(maxStep >= 7 && aoVivo && realtime) ||
			(maxStep >= 6 && aoVivo && !realtime) ? (
				<AoVivoTab
					liveId={id}
					visible={
						(aoVivo && !realtime && stepIndex === 6) ||
						(aoVivo && realtime && stepIndex === 7)
					}
				/>
			) : (
				<div />
			)}
		</s.Container>
	);
};

export default LiveStudio;
