import React, { useEffect, useState } from "react";
import * as s from "./styled-player.js";
import { useSelector } from "react-redux";
import Hls from "hls.js";
import { produtosService } from "../../services";
import { icons, images } from "../../assets/index.js";
import enableInlineVideo from "iphone-inline-video";
import { INTRO_KEY } from "../../utils/index.js";

var isIOS =
	(/iPad|iPhone|iPod/.test(navigator.platform) ||
		(navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)) &&
	!window.MSStream;

const Player = () => {
	const usuario = useSelector((state) => state.usuario.usuario);
	var { url } = usuario;
	var mp4 = false;
	if (url.split(".mp4")[0]) {
		mp4 = true;
	}

	var videoUrl = usuario.video;

	if (isIOS && !url.split("&end")[1]) url = url.split(".m3u8")[0] + ".m3u8";

	const [intro, setIntro] = useState(true);
	const [deleting, setDeleting] = useState(false);
	const live = useSelector((state) => state.live);
	const { favoritos, produtos, grades } = live;
	const [mostrar, setMostrar] = useState([]);
	const [show, setShow] = useState(false);
	const [show2, setShow2] = useState(false);
	const [menu, setMenu] = useState(false);
	const [muted, setMuted] = useState(true);
	const [first, setFirst] = useState(true);
	const [rodando, setRodando] = useState(false);
	const [dataPause, setdataPause] = useState();
	const [tempoPausado, setTempoPausado] = useState(0);
	const [aoVivo, setVivo] = useState(true);
	const [timeVideo, setTimeVideo] = useState(0);
	const video = document.getElementById("video");
	var slider = document.querySelector(".slider");
	const [levels, setLevels] = useState();
	const [levelSeleted, setLevel] = useState();
	const [hlsRef, setHlsRef] = useState();

	if (video) enableInlineVideo(video);

	function formatTime(seconds) {
		if (seconds) {
			if (intro && video) {
				let minutes = Math.floor(video.duration - seconds);
				minutes = Math.floor(minutes / 60) || 0;
				minutes = minutes >= 10 ? minutes : "0" + minutes;
				let s = Math.floor((60 - (seconds % 60)) % 60);
				s = s >= 10 ? s : "0" + s;
				return minutes + ":" + s;
			} else return new Date(seconds * 1000).toISOString().substr(11, 8);
		}
	}

	const playHls = (urlBase) => {
		if (Hls.isSupported() && !mp4) {
			isIOS = false;
			var hls = new Hls({
				capLevelToPlayerSize: false,
				startFragPrefetch: true,
				startLevel: 0,
				currentLevel: 0,
				startPosition: -1,
				liveSyncDurationCount: 9,
			});
			hls.attachMedia(video);
			hls.on(Hls.Events.MEDIA_ATTACHED, function () {
				hls.loadSource(urlBase);
				hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
					setLevels(data.levels);
					hls.currentLevel = 1;
					setLevel(data.levels[1]);
					playAndPause();
					setHlsRef(hls);
				});
			});
			hls.on(Hls.Events.ERROR, function (event, data) {
				if (data.fatal) {
					switch (data.type) {
						case Hls.ErrorTypes.NETWORK_ERROR:
							console.log("fatal network error encountered, try to recover");
							hls.startLoad();
							break;
						case Hls.ErrorTypes.MEDIA_ERROR:
							console.log("fatal media error encountered, try to recover");
							hls.recoverMediaError();
							break;
						default:
							console.log("other error encountered");
							hls.destroy();
							break;
					}
				}
			});
		} else if (
			video &&
			(video.canPlayType("application/vnd.apple.mpegurl") || mp4)
		) {
			if (!mp4) isIOS = true;
			video.src = urlBase;
			video.addEventListener("loadedmetadata", function () {
				playAndPause(true);
			});
		}
	};
	const playVideo = (urlBase) => {
		if (video) video.src = urlBase;
	};

	useEffect(() => {
		if (localStorage.getItem(INTRO_KEY)) {
			setIntro(false);
			playHls(url);
		} else playVideo(videoUrl);
		let principais = [];
		produtos.forEach((prod) => {
			if (prod.PRINCIPAL === "S") {
				principais.push(prod);
			}
		});
		principais.sort((a, b) => a.TEMPO_INICIAL - b.TEMPO_INICIAL);
		setMostrar(principais);
	}, [produtos]);

	// useEffect(() => localStorage.setItem("tempo_live", timeVideo), [timeVideo]);

	useEffect(() => playHls(url), [usuario]);

	function duas_casas(numero) {
		if (numero <= 9) {
			numero = "0" + numero;
		}
		return numero + "";
	}

	useEffect(() => {
		if (document.getElementById("video") && rodando && !intro) {
			var s = timeVideo;
			if (!mp4) s = s - 300;
			if (s > 0) {
				let hora = duas_casas(Math.trunc(s / 3600));
				let minuto = duas_casas(Math.trunc((s % 3600) / 60));
				let segundo = duas_casas((s % 3600) % 60).substring(0, 2);
				if (segundo === "9.") segundo = "09";
				let formatado = hora + ":" + minuto + ":" + segundo;
				mostrar.forEach((p) => {
					var timeFinal = p.TEMPO_FINAL;
					if (formatado === timeFinal && show.docId === p.docId) {
						if (show2) {
							setShow(show2);
							setShow2(false);
						} else setShow(false);
					}
					if (formatado === timeFinal && show2.docId === p.docId)
						setShow2(false);
					if (!show || !show2) {
						var time = p.TEMPO_INICIAL;
						if (formatado === time) {
							if (!show) setShow(p);
							if (show && show.docId !== p.docId && !show2) setShow2(p);
						}
					}
				});
			}
		}
	}, [timeVideo]);

	const playAndPause = (auto) => {
		if (video) {
			if (first) setFirst(false);
			if (video.paused) {
				if (!auto) setMuted(false);
				video.play();
				if (dataPause) {
					let dataAgora = new Date();
					let tempoP =
						tempoPausado +
						parseFloat(
							`${(dataAgora - dataPause).toString().slice(0, -3)}.${(
								dataAgora - dataPause
							)
								.toString()
								.slice(-3)}`,
						);
					setTempoPausado(tempoP);
					setdataPause(false);
				}

				setRodando(true);
				setVivo(false);
			} else {
				video.pause();
				setdataPause(new Date());
				setRodando(false);
				setVivo(false);
			}
		}
	};

	const setItem = (showClicked) => {
		setDeleting(showClicked);
		setTimeout(() => {
			if (showClicked.docId === show2.docId) setShow2(false);
			else {
				if (show2) {
					setShow(show2);
					setShow2(false);
				} else setShow(false);
			}
			setDeleting(false);
		}, 600);
		if (!verifyHearth(showClicked)) {
			let gradeTotal;
			grades.forEach((g) => {
				if (g.id === showClicked.GRADE_DISPONIVEL.split(",")[0])
					gradeTotal = g.gradeTotal;
			});
			produtosService.addFavorito(
				showClicked,
				showClicked.GRADE_DISPONIVEL.split(",")[0],
				gradeTotal,
				showClicked.docId,
			);
		}
	};

	const verifyHearth = (prod) => {
		let test = false;
		favoritos.forEach((p) => {
			if (p.produto.ID === prod.ID && p.produto.COR === prod.COR) test = true;
		});
		return test;
	};

	return (
		<s.Container id="player-container" disabled={intro} menu={menu}>
			{show && show.IMAGEM_VIDEO_LIVE && (
				<s.Poppup
					src={show.IMAGEM_VIDEO_LIVE}
					// src={images.produto}
					onClick={() => setItem(show)}
					className={`animated-linear ${
						deleting === show ? "remove" : "fade-in"
					}`}
					id={`poppup-live-${show.docId}`}
					onError={() => {
						let e = document.getElementById(`poppup-live-${show.docId}`);
						e.src = images.default;
					}}
				/>
			)}
			{show2 && show2.IMAGEM_VIDEO_LIVE && (
				<s.Poppup
					src={show2.IMAGEM_VIDEO_LIVE}
					// src={images.produto}
					onClick={() => setItem(show2)}
					className={`animated-linear ${
						deleting === show2 ? "remove" : "fade-in"
					}`}
					bottom
					id={`poppup-live-${show2.docId}`}
					onError={() => {
						let e = document.getElementById(`poppup-live-${show2.docId}`);
						e.src = images.default;
					}}
				/>
			)}
			{first || !rodando || (intro && video && video.paused) ? (
				<img
					alt="icone-player-first-intro"
					src={icons.play}
					style={{
						position: "absolute",
						top: "50%",
						left: "49%",
						width: "30px",
						height: "30px",
					}}
					onClick={playAndPause}
				/>
			) : null}
			{rodando && muted && (
				<img
					alt="icone-mudo-player-normal"
					src={icons.mute}
					style={{
						position: "absolute",
						top: "50%",
						left: "49%",
						width: "30px",
						height: "30px",
						zIndex: "3",
					}}
					onClick={() => setMuted(false)}
				/>
			)}

			<video
				muted={muted}
				controls={false}
				playsInline
				onEnded={() => {
					if (intro) {
						setIntro(false);
						playHls(url);
						localStorage.setItem(INTRO_KEY, true);
					}
				}}
				id="video"
				className="video"
				onClick={() => {
					if (muted) setMuted(false);
					else if (intro) playAndPause();
				}}
				onTimeUpdate={(e) => {
					let tempoComeco =
						usuario.url.split(".m3u8") && usuario.url.split(".m3u8")[1]
							? usuario.url.split(".m3u8")[1].replace("?start=", "")
							: "";

					let tempoAtual =
						parseInt(
							(new Date() - new Date(tempoComeco)).toString().slice(0, -3),
						) -
						15 -
						tempoPausado;

					slider.value = aoVivo
						? isIOS
							? 9999999999999
							: video.duration - 0.3
						: e.currentTarget.currentTime;

					setTimeVideo(
						isIOS && usuario.status !== "finalizada"
							? tempoAtual
							: e.currentTarget.currentTime,
					);
				}}></video>
			<div className="controls">
				<div className="buttons">
					<button id="play-pause" onClick={playAndPause}>
						<img
							alt="icone-play-pause-normal"
							src={rodando ? icons.pause : icons.play}
						/>
					</button>
				</div>
				<input
					className="slider"
					disabled={intro || (isIOS && usuario.status !== "finalizada")}
					type="range"
					max={video ? video.duration - 0.3 : 0}
					style={{ background: "white", width: "100%" }}
					onChange={(e) => {
						if (usuario.status !== "finalizada") {
							if (e.target.value >= video.duration - 2) setVivo(true);
							else setVivo(false);
						}
						video.currentTime = e.target.value;
						setShow(false);
						setShow2(false);
					}}
				/>
				<div className="frames-levels" onClick={() => setMenu(!menu)}>
					<label>{levelSeleted && levelSeleted.height + "p"}</label>
					<div class="dropdown-content">
						{levels &&
							levels.map(
								(l, i) =>
									l !== levelSeleted && (
										<a
											key={l.height}
											onClick={() => {
												setLevel(l);
												hlsRef.loadLevel = i;
												hlsRef.nextLevel = i;
											}}>
											{l.height}p
										</a>
									),
							)}
					</div>
				</div>
				<label style={{ margin: 0, minWidth: "60px", fontSize: "13px" }}>
					{aoVivo && !intro ? "Ao vivo" : formatTime(timeVideo) || "00:00:00"}
				</label>
				{!intro && usuario.status !== "finalizada" && !mp4 && (
					<label
						className="go-live"
						onClick={() => {
							if (video && !aoVivo && video.currentTime && video.duration) {
								video.currentTime = video.duration - 0.3;
								setVivo(true);
								video.play();
								setRodando(true);
								setTempoPausado(0);
								setdataPause(false);
							}
						}}>
						Go Live
					</label>
				)}
			</div>
		</s.Container>
	);
};

export default Player;
