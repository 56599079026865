import styled from "styled-components";
import { colors } from "../../assets";

export const Container = styled.div`
  margin: 20px;
  width: 100%;
  max-width: 1200px;
  height: 680px;
  background: black;
  border-radius: 4px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4), -2px -2px 6px rgba(0, 0, 0, 0.4);
  position: relative;
  overflow: hidden;
  :hover .controls {
    transform: translateY(0);
    -webkit-transform: translateY(0);
  }
  @media (max-width: 1700px) {
    height: 40vw;
  }
  @media (max-width: 1010px) {
    width: 100%;
    height: 50vw;
    margin: 0;
    border: none;
    border-radius: none;
  }
  .go-live {
    margin-left: 0;
    min-width: 50px;
    font-size: 13px !important;
    cursor: pointer;
    font-size: 13px;
    :hover {
      color: ${colors.primary};
    }
  }
  .video {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    z-index: 0;
    /* overflow: hidden; */
  }
  .controls {
    min-height: 30px;
    -webkit-z-index: 1000;
    z-index: 1000;
    display: flex;
    position: absolute;
    align-items: center;
    bottom: 0;
    width: 100%;
    background: linear-gradient(0deg, #d62645 0%, rgba(233, 78, 92, 0) 100%);
    ${(props) =>
      !props.menu &&
      `transform: translateY(100%);-webkit-transform: translateY(100%);`}
    transition: all 0.2s;
    label {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      color: #ffffff;
      margin: 0 10px;
    }
    .slider {
      z-index: 100;
      -webkit-appearance: none; /* Override default CSS styles */
      appearance: none;
      width: 100%; /* Full-width */
      height: 4px; /* Specified height */
      background: rgba(255, 255, 255, 0.3);
      outline: none; /* Remove outline */
      -webkit-transition: 0.2s; /* 0.2 seconds transition on hover */
      transition: opacity 0.2s;

      ${(props) =>
        props.disabled && `background: yellow !important;margin-left: 10px;`}
    }
    .base-intro {
      z-index: 99;
      -webkit-appearance: none; /* Override default CSS styles */
      appearance: none;
      flex: 1;
      height: 4px; /* Specified height */
      background: rgba(255, 255, 255, 0.3);
      outline: none; /* Remove outline */
      -webkit-transition: 0.2s; /* 0.2 seconds transition on hover */
      transition: opacity 0.2s;
    }

    /* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
    .slider::-webkit-slider-thumb {
      -webkit-appearance: none; /* Override default look */
      appearance: none;
      width: 10px; /* Set a specific slider handle width */
      height: 10px; /* Slider handle height */
      background: ${colors.primary}; /* Green background */
      cursor: pointer; /* Cursor on hover */

      ${(props) => props.disabled && `display: none;`}
    }

    .slider::-moz-range-thumb {
      width: 10px; /* Set a specific slider handle width */
      height: 10px; /* Slider handle height */
      background: ${colors.primary}; /* Green background */
      cursor: pointer; /* Cursor on hover */

      ${(props) => props.disabled && `display: none;`}
    }
    .video::-webkit-media-controls-panel {
      display: none !important;
      -webkit-appearance: none;
    }

    .video::--webkit-media-controls-play-button {
      display: none !important;
      -webkit-appearance: none;
    }

    .video::-webkit-media-controls-start-playback-button {
      display: none !important;
      -webkit-appearance: none;
    }
    .IIV::-webkit-media-controls-play-button,
    .IIV::-webkit-media-controls-start-playback-button {
      opacity: 0;
      pointer-events: none;
      width: 5px;
    }
  }
  .frames-levels {
    position: relative;
    display: grid;
    label,
    a {
      font-size: 13px;
      cursor: pointer;
      color: white;
    }
    a {
      opacity: 0.5;
      :hover {
        opacity: 1;
      }
    }
    .dropdown-content {
      display: ${(props) => (props.menu ? "grid" : "none")};
      position: absolute;
      background-color: transparent;
      z-index: 100;
      bottom: 17px;
      padding-left: 10px;
    }
  }
  .buttons button {
    ${(props) => props.disabled && `display: none;`}
    z-index: 1;
    background: none;
    border: 0;
    outline: 0;
    cursor: pointer;
    width: 40px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  ${(props) =>
    props.disabled &&
    `#play-pause{
    display: none;
  }`}
  .remove {
    animation: remove-animation 0.6s ease-out forwards;
    transform-origin: 50% 50%;
  }

  @keyframes remove-animation {
    0% {
      opacity: 1;
      transform: scale(1);
    }

    100% {
      opacity: 0;
      transform: scale(1.5);
    }
  }
`;

export const Poppup = styled.img`
  transition: all 2s;
  min-width: 135px;
  min-height: 120px;
  width: ${({ ladoCard: l }) => (l === "centro" ? "15vw" : "10vw")};
  height: ${({ ladoCard: l }) => (l === "centro" ? "14vw" : "9vw")};
  cursor: pointer;
  position: absolute;
  z-index: 99;
  ${({ ladoCard: l, bottom: b }) =>
    l === "direito"
      ? "right: 20px;"
      : l === "esquerdo"
      ? "left: 20px;"
      : b
      ? `left: 12vw;`
      : `right: 12vw;`}

  top: ${({ bottom: b, ladoCard: l }) =>
    l === "centro" ? "9vw" : b ? "11vw" : "1vw"};

  ${({ ladoCard: l, bottom: b }) =>
    l === "centro"
      ? `@media (max-width: 1010px) {
    min-width: 30vw;
    min-height: 29vw;
  }
  @media (max-width: 1050px) {
    ${b ? `left: 10vw;` : `right: 10vw;`}
  }
  `
      : ` @media (max-width: 1250px) {
    top: ${({ bottom: b }) => (b ? "15vw" : "1vw")};
  }
  @media (max-width: 900px) {
    top: ${({ bottom: b }) => (b ? "140px" : "1vw")};
  }
  @media (max-width: 600px) {
    min-width: 85px;
    min-height: 75px;
  }
  @media (max-width: 500px) {
    top: ${({ bottom: b }) => (b ? "90px" : "1vw")};
    min-width: 65px;
    min-height: 55px;
  }`}
`;
