import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { icons, images } from "../../assets";
import { managerService } from "../../services";
import { usuariosActions, liveActions } from "../../store";
import { getStatus, orderLives } from "../../utils";
import DeleteModal from "./modals/deleteModal";
import SearchInput from "./search-input/search-input";

import * as s from "./styled-lives-adm";

const LivesAdm = ({ history }) => {
	const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
	const [liveSelectedToDelete, setLiveSelectedToDelete] = useState({});
	const [filterOption, setFilterOption] = useState();
	const [livesToShow, setLivesToShow] = useState([]);
	const [filters, setFilters] = useState([
		"Não publicada",
		"Transmitindo",
		"Agendada",
	]);

	const dispatch = useDispatch();
	const usuario = useSelector((state) => state.usuario.usuario);
	const lives = usuario && usuario.lives ? usuario.lives : [];
	var { live } = usuario;

	const headerContainerRef = useRef(null);

	useEffect(() => {
		if (lives.length > 0 && filters.length > 0) {
			handleLiveFilters(filters);
		}
	}, [lives]);

	useEffect(() => {
		localStorage.removeItem("live_preenchida");
		localStorage.removeItem("live_publicada");
	}, []);

	useEffect(() => {
		const resizeHeaderWidth = () => {
			const cardsInLine = window.innerWidth / 356;
			const floor = Math.floor(cardsInLine);
			const sidesRest = window.innerWidth - 356 * floor;
			headerContainerRef.current.style.width = `${
				window.innerWidth - sidesRest - 10 * 2 // 10 * 2: side css margins
			}px`;
		};

		resizeHeaderWidth();

		window.addEventListener("resize", resizeHeaderWidth);
		return () => window.removeEventListener("resize", resizeHeaderWidth);
	}, [headerContainerRef]);

	const getDate = (date) => {
		let resp = `${new Date(date).toLocaleDateString()} às ${new Date(date)
			.toLocaleTimeString()
			.substr(0, 5)}`;
		return resp;
	};

	const selectLive = (live) => {
		localStorage.setItem("KEY", live.id);
		managerService
			.getLiveInfo(live.id)
			.then((data) => {
				dispatch(liveActions.setFormasPagamento(data.condicoesPagamento));
				dispatch(usuariosActions.setLiveUsuario({ ...live, ...data }));

				setTimeout(() => history.push(`/live`), 1000);
			})
			.catch((e) => alert(e));
	};

	const handleClickExcluir = (id) => {
		const actualLiveData = lives.find((live) => live.id === id);
		setLiveSelectedToDelete(actualLiveData);
		setDeleteModalIsOpen(true);
	};

	const deleteLive = ({ liveId, isPublish }) => {
		managerService
			.excluirLive(liveId)
			.then((res) => {
				window.location.reload();
				closeDeleteModal();
			})
			.catch((e) => alert(e));
	};

	const closeDeleteModal = () => {
		setDeleteModalIsOpen(false);
	};

	const handleLiveFilters = (params) => {
		if (params.length >= 0) {
			const filterByStatus = lives.filter((live) =>
				params.includes(
					getStatus(live.publicado, live.dataInicio, live.dataFim),
				),
			);
			setFilters(params);

			setLivesToShow(orderLives(filterByStatus));
			return;
		}

		const showIt = lives.filter((live) =>
			live.nome.toLowerCase().match(params.nome.toLowerCase()),
		);

		setLivesToShow(orderLives(showIt));
		setFilterOption(params.nome);
	};

	return (
		<s.Container className="fade-in">
			{deleteModalIsOpen && (
				<DeleteModal
					isOpen={deleteModalIsOpen}
					onRequestClose={closeDeleteModal}
					deleteLive={deleteLive}
					liveSelected={liveSelectedToDelete}
				/>
			)}

			<s.Header ref={headerContainerRef}>
				<s.Title>Selecione uma live para vê-la</s.Title>
				<SearchInput
					placeholder="Filtrar lives"
					selectedOption={filterOption}
					setSelected={handleLiveFilters}
					statusActive={filters}
				/>
			</s.Header>

			<s.ContainerItems>
				{livesToShow && livesToShow.length > 0 ? (
					livesToShow
						.filter((live) => !live.desativada)
						.map(
							(
								{
									nome,
									descricao,
									dataFim,
									dataInicio,
									id,
									publicado,
									thumbnail,
								},
								i,
							) => (
								<div
									key={id}
									className={`fade-in ${
										live && live.id === id ? "selecionada" : "normal"
									}`}>
									<section key={id}>
										<div
											className="image-card"
											id={`live-${id}-thumbnail`}
											// style={
											//   thumbnail
											//     ? { backgroundImage: `url(${thumbnail})` }
											//     : { backgroundImage: `url(${images.default})` }
											// }
										>
											<img
												src={thumbnail}
												alt="thumbnail"
												onError={() => {
													const thumb = document.querySelector(
														`#live-${id}-thumbnail img`,
													);
													thumb.src = images.default;
													thumb.style.width = "50%";
												}}
											/>
										</div>
										<legend>{getStatus(publicado, dataInicio, dataFim)}</legend>

										<span
											className="fade-in animated-linear"
											onClick={() =>
												history.push("/live-studio", {
													editar: true,
													id,
													initialStep: 0,
												})
											}>
											Editar
										</span>

										<span
											className="fade-in animated-linear excluirButton"
											onClick={() => handleClickExcluir(id)}>
											Excluir
										</span>
										<h1 title={nome} onClick={() => selectLive(livesToShow[i])}>
											{nome}
										</h1>
										<h2 title={descricao}>{descricao}</h2>
										<div>
											<label>
												Início: <p>{getDate(dataInicio)}</p>
											</label>
											<label>
												Fim: <p>{getDate(dataFim)}</p>
											</label>
										</div>
									</section>
								</div>
							),
						)
				) : (
					<s.Empty>
						Você ainda não possui lives criadas. Vá para o{" "}
						<a onClick={() => history.push("/live-studio")}>Live Stúdio</a> e
						crie uma para aparecer aqui!
					</s.Empty>
				)}
			</s.ContainerItems>
		</s.Container>
	);
};

export default LivesAdm;
