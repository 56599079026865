import React, { useState, useEffect, useCallback } from "react";
import * as s from "./styled-salas-tab";
import { Button, Loader, Check } from "../../../../components";
import Modal from "react-modal";
import { managerService } from "../../../../services";
import CardSala from "./card-sala";
import CardSalaUnica from "./card-sala-unica";

const SalasTab = ({ liveId, visible, next, old, aoVivo }) => {
	const [salas, setSalas] = useState([]);
	const [deleting, setDeleting] = useState(false);
	const [loading, setLoading] = useState(true);
	const [saving, setSaving] = useState(false);
	const [options, setOptions] = useState([]);
	const [changes, setChanges] = useState(false);
	const [salaUnica, setSalaUnica] = useState({
		chat: false,
		live: false,
		intro: false,
	}); //Dados da sala única
	const [salaGeral, setSalaGeral] = useState(false); //Boolean ter ou não sala única
	const [, updateState] = useState();
	const forceUpdate = useCallback(() => updateState({}), []);

	useEffect(() => {
		managerService.getRegionais().then((data) => {
			setOptions(
				data.map((reg) => ({
					...reg,
					value: reg.codigoRegional,
					label: reg.nomeRegional,
				})),
			);
		});
		if (liveId) {
			managerService
				.getLiveSalas(liveId)
				.then((data) => {
					if (data && data.length >= 1) {
						localStorage.setItem("salas_preenchidas", true);

						setSalas(
							data.map((sala) => ({
								...sala,
								regional: {
									codigoRegional: sala.codigoRegional,
									nomeRegional: sala.nomeRegional,
									regional: sala.regional,
									value: sala.codigoRegional,
									label: sala.nomeRegional,
								},
							})),
						);
					} else if (data && data.live) {
						setSalaGeral(true);
						setSalaUnica(data);
					}
					setLoading(false);
					setChanges(false);
				})
				.catch((e) => {
					alert(e);
					setSalas([{}]);
					setLoading(false);
				});
		} else {
			setSalas([{}]);
			setLoading(false);
		}
	}, [liveId]);

	useEffect(() => {
		if (salas[0] && salas[0].live) {
			localStorage.setItem("URL", salas[0].live);
		} else if (salaUnica && salaUnica.live) {
			localStorage.setItem("URL", salaUnica.live);
		}
	}, [salas, changes, saving, salaUnica]);

	const getOptions = () => {
		var result = options.filter(function (opt) {
			return !salas.some(({ regional }) => {
				if (regional)
					return opt.value === regional.value && opt.label === regional.label;
				else return null;
			});
		});
		return result;
	};

	const saveSalaUnica = () => {
		if (salaUnica.live || aoVivo) {
			setSaving(true);
			if (old)
				managerService
					.editarSalas(liveId, salaUnica, true)
					.then(() => setSaving(false))
					.catch((e) => {
						alert(e);
						setSaving(false);
					});
			else
				managerService
					.saveSalas(liveId, salaUnica, true)
					.then(() => {
						next();
						setSaving(false);
					})
					.catch((e) => {
						alert(e);
						setSaving(false);
					});
		} else alert("A sala precisa ter um video para live!");
	};

	const saveSalas = () => {
		let error;
		let body = salas.map(({ chat, regional, live, intro, sala }) => {
			if (
				!regional?.codigoRegional ||
				!regional?.nomeRegional ||
				!regional?.regional
			)
				return (error = "Todas as salas devem ter uma regional selecionada!");
			if (!live && !aoVivo)
				return (error = "Todas as salas devem ter um vídeo de live!");
			return {
				chat,
				codigoRegional: regional.codigoRegional,
				nomeRegional: regional.nomeRegional,
				regional: regional.regional,
				live,
				intro,
				sala: sala ? sala : null,
			};
		});

		if (error) {
			alert(error);
		} else {
			setSaving(true);
			if (old)
				managerService
					.editarSalas(liveId, body)
					.then(() => setSaving(false))
					.catch((e) => {
						alert(e);
						setSaving(false);
					});
			else
				managerService
					.saveSalas(liveId, body)
					.then(() => {
						next();
						setSaving(false);
					})
					.catch((e) => {
						alert(e);
						setSaving(false);
					});
		}
	};

	if (loading && visible)
		return (
			<div
				style={{
					display: "flex",
					flex: 1,
					justifyContent: "center",
					height: "100%",
					alignItems: "center",
				}}>
				<Loader />
			</div>
		);

	return (
		<>
			<s.Container className="fade-in" visible={visible}>
				<Modal
					isOpen={deleting !== false}
					style={s.modalStyles}
					onRequestClose={() => setDeleting(false)}>
					<s.ModalContent
						className="fade-in"
						onSubmit={() => {
							if (salas.length > 1) {
								if (
									salas[deleting].regional &&
									salas[deleting].regional.regional
								)
									managerService.deleteSala(
										liveId,
										salas[deleting].regional.regional,
									);
								setSalas(salas.filter((a, i2) => i2 !== deleting));
								setDeleting(false);
							}
						}}>
						<h1>Você tem certeza que deseja excluir esta sala?</h1>
						<section>
							<Button
								outline
								label="Cancelar"
								onClick={() => setDeleting(false)}
							/>
							<Button red label="Excluir" type="submit" />
						</section>
					</s.ModalContent>
				</Modal>
				{salaGeral ? (
					<CardSalaUnica
						salaUnica={salaUnica}
						setSalaUnica={setSalaUnica}
						setChanges={setChanges}
						liveId={liveId}
						saving={saving}
						aoVivo={aoVivo}
					/>
				) : (
					<>
						{salas &&
							salas.map((sala, i) => (
								<CardSala
									key={i + 1}
									sala={sala}
									i={i}
									setSalas={(data) => {
										setChanges(true);
										setSalas(data);
									}}
									salas={salas}
									options={getOptions()}
									setDeleting={setDeleting}
									liveId={liveId}
									saving={saving}
									forceUpdate={forceUpdate}
									aoVivo={aoVivo}
								/>
							))}
						<section key={"nova-sala"}>
							<Button
								disabled={saving}
								label={`Adicionar ${salas.length === 0 ? "uma" : "outra"} sala`}
								style={{ margin: "auto 22px" }}
								onClick={() => {
									setChanges(true);
									setSalas([...salas, {}]);
								}}
							/>
						</section>
					</>
				)}
			</s.Container>
			<Button
				label={old ? "Salvar" : "Próximo"}
				style={{
					width: "200px",
					margin: "40px auto",
					display: visible ? "flex" : "none",
				}}
				onClick={salaGeral ? saveSalaUnica : saveSalas}
				disabled={saving || !changes}
				isLoading={saving}
			/>
			{!old && (
				<div
					style={{
						width: "235px",
						margin: "40px auto",
						display: visible ? "flex" : "none",
					}}
					title="Cria uma sala única para todos os usuários">
					<s.Title
						style={{
							display: "flex",
							color: salaGeral ? "white" : "",
						}}>
						<Check
							disabled={saving}
							active={salaGeral}
							onClick={() => {
								setSalaGeral(!salaGeral);
								setChanges(true);
							}}
						/>
						Sala única para todos os usuários
					</s.Title>
					<s.Title
						style={{
							maxWidth: "235px",
							paddingRight: "50px",
							textAlign: "center",
							marginTop: "5px",
						}}>
						Obs: Uma vez escolhido o modo sala única e salvo, não é mais
						possível trocar para múltiplas salas!
					</s.Title>
				</div>
			)}
		</>
	);
};

export default SalasTab;
