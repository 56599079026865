import React, { useEffect } from "react";
import { appActions } from "./store";
import { useDispatch, useSelector } from "react-redux";
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";
import { Loader, PdfOverview } from "./components";
import {
	Header,
	Home,
	Login,
	Dashboard,
	Usuarios,
	LiveStudio,
	Lives,
	LivesAdm,
	Timer,
	Relatorio,
} from "./screens";
import { PubNubProvider } from "pubnub-react";
import { produtosService } from "./services";

const App = () => {
	const dispatch = useDispatch();
	const usuario = useSelector((state) => state.usuario);
	const app = useSelector((state) => state.app);
	const live = useSelector((state) => state.live);
	const client = useSelector((state) => state.subscription);
	const manager = usuario.usuario && usuario.usuario.manager;
	const admin = usuario.usuario && usuario.usuario.tipoUsuario === 5;
	let isPdfRoute = useRouteMatch("/pdf/:slug");

	useEffect(() => dispatch(appActions.initialize()), [dispatch]);

	if (!app.initialized)
		return (
			<div
				className="fade-in"
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					flex: 1,
					height: "100vh",
				}}>
				<Loader />
			</div>
		);

	return (
		<div className="fade-in" style={{ height: "100vh" }}>
			{!usuario.usuario ? (
				<Switch>
					<Route path="/login" component={Login} />
					<Redirect to="/login" />
				</Switch>
			) : (
				<PubNubProvider client={client.pubnub}>
					{!isPdfRoute && <Header />}
					{admin || manager ? (
						<Switch>
							<Route path="/live" exact component={Home} />
							<Route path="/lives" exact component={LivesAdm} />
							<Route path="/usuarios" exact component={Usuarios} />
							<Route path="/dashboard" exact component={Dashboard} />
							<Route path="/relatorio" exact component={Relatorio} />
							<Route path="/live-studio" exact component={LiveStudio} />
							<Route path="/gerenciador" exact component={LivesAdm} />
							<Route path="/pdf/:slug" component={PdfOverview} />
							<Route path="*">
								<Redirect to="/gerenciador" />
							</Route>
						</Switch>
					) : (
						<Switch>
							<Route path="/" exact component={Home} />
							<Route path="/lives" exact component={Lives} />
							<Route path="/timer" exact component={Timer} />
							<Route path="*">
								<Redirect to="/lives" />
							</Route>
						</Switch>
					)}
				</PubNubProvider>
			)}
		</div>
	);
};

export default App;
