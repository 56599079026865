import styled from "styled-components";

export const CountdownContainer = styled.div`
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  background: #e5e5e5;
`;
export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
`;
export const CountDown = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #be1622;
  height: 40vh;
`;
export const TimeBox = styled.div`
  width: 45vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: ${(props) => props.size}px;
  border: 4px solid #ffffff;
  border-radius: 4px;
  padding: 5px;
  position: absolute;
  .starts {
    font-size: 1.5em;
    color: #fff;
  }
  .countdown {
    font-size: 20px;
    font-weight: bold;
    color: #fff;
  }
`;
export const Image = styled.img`
  width: 40vw;
  max-width: 400px;
  min-width: 260px;
  margin: auto;
`;
