import styled from 'styled-components';
import { colors } from '../../assets';

export const Container = styled.div`
  width: 100%;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
`;
export const Steps = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
`;
export const Step = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  animation: all 2s;
  span {
    width: 120px;
    @media (max-width: 600px) {
      font-size: 12px;
      width: 80px;
    }
    cursor: pointer;
    margin: 20px 5px 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    font-style: normal;
    font-weight: 600;
    border-radius: 5px;
    color: ${colors.grayDark};
    ${({ active }) =>
      active &&
      `background: ${colors.primary};color: white;
    box-shadow: 0px 3px 0px #ffffff;
    `}
  }
  div {
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    a {
      width: 16px;
      height: 16px;
      background: ${({ active }) => (active ? colors.primary : colors.grayDark)};
      border-radius: 50%;
    }
  }
`;
