import styled from "styled-components";
import { colors } from "../../../../assets";

export const Container = styled.div`
  width: 100%;
  display: ${({ visible }) => (visible ? "flex" : "none")};
  flex-wrap: wrap;
  justify-content: center;
  section {
    margin: 15px 10px;
    width: 100%;
    height: 460px;
    background: #3f3f3f;
    box-shadow: -10px -10px 30px rgba(0, 0, 0, 0.08),
      10px 10px 30px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    :last-child {
      width: 460px;
      display: flex;
      flex-direction: column;
    }
    :first-child {
      position: relative;
      article {
        margin: 10px;
        display: flex;
        flex-wrap: wrap;
      }
      max-width: 1000px;
    }
  }
`;

export const Title = styled.h1`
  font-weight: 600;
  font-size: 14px;
  color: #989898;
  position: absolute;
  margin-top: -25px;
`;
export const Card = styled.div`
  margin: 10px 10px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 120px;
  height: 115px;
  cursor: pointer;
  img {
    :first-child {
      width: 120px;
      height: 115px;
      background: white;
      border-radius: 4px;
    }
  }
  span {
    font-weight: 600;
    font-size: 10px;
    color: #989898;
    position: absolute;
    margin-bottom: -15px;
  }
`;
export const FirstCard = styled.div`
  height: 115px;
  margin: 20px;
  display: flex;
  img {
    min-width: 120px;
    max-width: 120px;
    min-height: 115px;
    max-height: 115px;
    border-radius: 5px;
  }
  label {
    color: white;
    margin-left: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5; /* number of lines to show */
    -webkit-box-orient: vertical;
    line-height: 23px;
    text-align: justify;
  }
  h2 {
    text-align: center;
    font-size: 20px;
    max-width: 350px;
    margin: 0 auto;
    color: #989898;
    font-weight: 600;
  }
`;
export const LiveCards = styled.div`
  margin: auto 20px 20px 20px;
  display: flex;
  img {
    min-width: 120px;
    max-width: 120px;
    min-height: 115px;
    max-height: 115px;
    border-radius: 5px;
  }
  article {
    height: 250px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
const ButtonTemplate = styled.button`
  width: 130px;
  height: 30px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  outline: none;
  opacity: 0.8;
  border: none;
  :hover {
    :disabled {
      opacity: 0.6;
    }
    opacity: 1;
  }
  :disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;
export const BlueButton = styled(ButtonTemplate)`
  background: #75b6cb;
  color: white;
  :disabled {
    color: white;
  }
`;
export const RedButton = styled(ButtonTemplate)`
  background: ${colors.primary};
  color: white;
  :disabled {
    color: white;
  }
`;
export const Search = styled.input`
  position: absolute;
  top: -35px;
  right: 0px;
  background: #3f3f3f;
  color: white;
  border-radius: 5px 5px 0 0;
  height: 40px;
  width: 220px;
  padding: 0 33px 0 10px;
`;
export const SearchIcon = styled.img`
  position: absolute;
  top: -25px;
  right: 10px;
  width: 18px;
  height: 18px;
  opacity: 0.6;
`;
