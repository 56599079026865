import React from "react";
import * as s from "./styled-usuarios";
import { useSelector } from "react-redux";
import { Button } from "../../components";
import { useState } from "react";
import { useEffect } from "react";
import { firebase } from "../../services/index.js";
import { icons } from "../../assets";

const Usuarios = () => {
	const subscription = useSelector((state) => state.subscription);
	const user = useSelector((state) => state.usuario.usuario);
	const [usersMock, setUsersMock] = useState(0);
	const [users, setUsers] = useState(0);
	const [testUser, setTestUser] = useState("");
	const [testUsers, setTestUsers] = useState([]);
	const [adding, setAdding] = useState(false);
	const live = user
		? user.live
			? user.live
			: user.lives
			? user.lives[0]
			: null
		: null;
	useEffect(() => {
		if (live.id)
			firebase
				.firestore()
				.collection("mocks")
				.doc(live.id)
				.onSnapshot((snapshot) => {
					if (snapshot.data()) {
						if (snapshot.data().users || snapshot.data().users === 0) {
							setUsers(snapshot.data().users);
							setUsersMock(snapshot.data().users);
						}
						if (snapshot.data().testUsers)
							setTestUsers([...snapshot.data().testUsers]);
					}
				});
	}, [live]);

	const changeUsers = () => {
		let d = {};
		if (usersMock) d.users = Number(usersMock);
		if (testUsers) d.testUsers = testUsers;
		firebase
			.firestore()
			.collection("mocks")
			.doc(live.id)
			.set(d)
			.then(() => alert("Usuários alterados com sucesso!"))
			.catch(() => alert("Ocorreu um erro ao salvar suas alterações!"));
	};
	const removeTestUser = (u) => {
		let d = {};
		let newUsers = testUsers.filter((a) => a !== u);
		d.users = users;
		d.testUsers = newUsers;
		firebase
			.firestore()
			.collection("mocks")
			.doc(live.id)
			.set(d)
			.then(() => alert("Usuários removido com sucesso!"))
			.catch(() => alert("Ocorreu um erro ao remover esse usuário!"));
	};
	const maxLengthCheck = (object) => {
		if (object.target.value.length > object.target.maxLength) {
			object.target.value = object.target.value.slice(
				0,
				object.target.maxLength,
			);
		}
	};
	const changeTestUser = () => {
		setAdding(true);
		let d = {};
		d.users = users;
		d.testUsers = [...testUsers, testUser];
		firebase
			.firestore()
			.collection("mocks")
			.doc(live.id)
			.set(d)
			.then(() => {
				setAdding(false);
				setTestUser("");
				alert("Usuário adicionado com sucesso!");
			})
			.catch((e) => {
				console.log("e", e);
				setAdding(false);
				alert("Ocorreu um erro ao adicionar usuário!");
			});
	};

	return (
		<s.Container>
			<s.Title>
				<label className="title">Chat: </label>
				<label>{live && live.nome}</label>
			</s.Title>
			<s.Box>
				<section>
					<label>Usuários online</label>
					<span>
						<img src={icons.user} alt="icone-current-users" />
						{subscription.currentUsers || 0} + {users}
					</span>
				</section>
				<section>
					<label>Acrescentar</label>
					<div>
						<s.Input
							type="number"
							min={0}
							maxLength="6"
							onInput={maxLengthCheck}
							onKeyPress={(event) => {
								if (!/[0-9]/.test(event.key)) {
									event.preventDefault();
								}
							}}
							value={usersMock}
							onChange={({ target }) => setUsersMock(target.value)}
						/>
						<Button label="Alterar" onClick={changeUsers} />
					</div>
				</section>
			</s.Box>
			<s.Title>
				<label className="title">Usuários de teste: </label>
				<label>{live && live.nome}</label>
			</s.Title>
			<s.Box>
				<section>
					<label>Usuários de teste</label>
					<span>
						<img src={icons.user} alt="icone-test-users" />
						{testUsers.length || 0}
					</span>
				</section>
				<section>
					<label>Acrescentar</label>
					<div>
						<s.Input
							style={{ fontSize: "18px" }}
							value={testUser}
							placeholder={"Cód. usuário"}
							onChange={({ target }) =>
								setTestUser(String(target.value).toUpperCase())
							}
						/>
						<Button
							label="Adicionar"
							onClick={changeTestUser}
							disabled={!testUser || adding}
							isLoading={adding}
						/>
					</div>
				</section>
			</s.Box>
			{testUsers && testUsers.length > 0 && (
				<>
					<s.Title>Remover</s.Title>
					<s.Box>
						<s.ListItem>
							{testUsers.sort().map((u) => (
								<span key={u}>
									<div>{u}</div>
									<img
										src={icons.trash}
										alt=""
										onClick={() => removeTestUser(u)}
									/>
								</span>
							))}
						</s.ListItem>
					</s.Box>
				</>
			)}
		</s.Container>
	);
};

export default Usuarios;
