import styled from "styled-components";

export const Container = styled.div`
	width: 100%;
	display: ${({ visible }) => (visible ? "flex" : "none")};
	flex-direction: column;
	align-items: center;
`;
export const Content = styled.div`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	section {
		display: flex;
		flex-direction: column;
		width: 380px;
		min-height: 450px;
		margin: 10px;
	}
`;

export const Title = styled.label`
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	color: #989898;
	margin-bottom: 5px;
`;
export const Thumbnail = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 199px;
	background: #ffffff;
	mix-blend-mode: normal;
	box-shadow: -10px -10px 30px rgba(0, 0, 0, 0.08),
		10px 10px 30px rgba(0, 0, 0, 0.08);
	border-radius: 4px;
	margin: 0 0 11px 0;

	img {
		border-radius: 4px;
	}

	p {
		position: absolute;
		top: 30%;
		left: 50%;
		width: 80%;
		transform: translate(-50%, -50%);
		font-size: 22px;
		color: #bfbfbf;
		font-weight: 900;
		text-align: center;
		display: none;
		opacity: 0;
		transition: 200ms;
	}

	.thumbnail-shape {
		background: rgba(0, 0, 0, 0.5);
		transition: 200ms;

		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0;
	}

	:hover {
		.thumbnail-shape {
			opacity: 1;
		}
		p {
			display: block;
			opacity: 1;
		}
	}
`;

export const PlayerOffset = styled.div`
	display: grid;
`;
