import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import * as s from "./styled-conclusao";
import { Button, Select } from "../../components";
import { produtosService } from "../../services";
import Modal from "react-modal";
import { formatValor, DESCONTO } from "../../utils";
import { format } from "date-fns";

const Conclusao = ({ setConclusao, setRealizouPedido }) => {
	const { favoritos, formasPagamento } = useSelector((state) => state.live);
	const user = useSelector((state) => state.usuario.usuario);
	const grades = useSelector((state) => state.live.grades);
	const [options, setOptions] = useState([]);
	const [Observacao, setObservacao] = useState("");
	const [email, setEmail] = useState("");
	const [Loading, setLoading] = useState(false);
	const [LoadingEmail, setLoadingEmail] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [pagamento, setPagamento] = useState({
		label: "Selecione",
		desconto: 0,
	});
	const [error, setError] = useState();
	const [totalPares, setTotalPares] = useState(0);
	const [totalValor, setTotalValor] = useState(0);

	useEffect(() => {
		if (formasPagamento.length > 0) {
			const tmp = formasPagamento.map((pag) => {
				return {
					label: pag.condicaoDescricao,
					value: pag.condicaoCodigo,
					livroCodigo: pag.livroCodigo,
					desconto: Number(pag.condicaoDesconto) || 0,
				};
			});

			setOptions(tmp);
		}
	}, [formasPagamento]);

	const styleButton = {
		minWidth: "220px",
		height: "65px",
		fontSize: "16px",
		textTransform: "none",
	};

	useEffect(() => {
		let total = 0;
		let preco = 0;
		favoritos.forEach((fav) => {
			total = total + fav.quantidade;
			preco = preco + fav.quantidade * fav.produto.PRECO_VENDA;
		});
		setTotalPares(total);
		setTotalValor(preco);
	}, [favoritos]);

	const fechar = () => {
		setLoading(true);
		produtosService
			.fecharPedido(favoritos, email, pagamento, Observacao)
			.then(() => {
				setRealizouPedido(true);
				setLoading(false);
				setConclusao(true);
			})
			.catch(() => {
				setLoading(false);
				setError("Não foi possível concluir seu pedido!");
			});
	};

	const getGrade = (grade) => {
		let val;
		grades.map((grad) => {
			if (grad.id === grade) val = grad.tamanXquantid;
		});
		if (val) return val;
		else return [];
	};

	const enviarEmail = () => {
		setLoadingEmail(true);
		var html = `<!DOCTYPE html>
    <html lang="pt-BR">
      <head>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </head>
      <body style="width: 930px;">
        <div style="display: flex; align-items: center; justify-content: space-between; margin: 0 10px">
        <img src="https://lh3.googleusercontent.com/KAOmf_Lo-9kgy8PdbZCvYZwro_TfGRgbX-JXTqNx1mMCeQb6Ig5NznHwcxVla33G_hMM0fy4Gid9-amKIZ-zfTkSbq9YSqKfK4GFizcVA_DRncttoF1kb_zkXYrOWEf3BA2a0Aee=w2400" alt="Logo Calçados Beira Rio Live Show" title="Logo Calçados Beira Rio Live Show" style="display: block; height: 60px; width: 100px;"/>
          <label 
            style="font-size: 16px; margin: 50px 0 0 280px"
          >
            Pedido Live Show
          </label>
        </div>

        <div
          style="
            margin: 5px 10px;
            border-top: 1px solid;
            border-bottom: 1px solid;
            margin: 0 10px;
          "
        >
          <p style="text-align: flex-start">Cliente: <strong>${
						user.nome
					}</strong></p>
          <p style="text-align: flex-start">Data: <strong>${format(
						new Date(),
						"d/L/y",
					)}</strong></p>
          <p style="text-align: flex-start">Total de pares: <strong>${totalPares}</strong></p>
          ${
						!user.ocultaPreco
							? `<p style="text-align: flex-start">Valor total: <strong>${formatValor(
									totalValor,
							  )}</strong></p>
            <p style="text-align: flex-start">Valor de live: <strong>${formatValor(
							totalValor - totalValor * (pagamento?.desconto / 100),
						)}</strong></p>
            `
							: ""
					}
          </div>
          <div
            style="
              height: 35px;
              display: flex;
              font-weight: bold;
              background: white;
              border-bottom: 1px solid;
              margin: 0 10px;
              align-items: center;
              width: calc(100% - 20px);
              "
          >
            <label style="max-width: 100px; width: 100px; font-size: 12px; padding: 0 5px">
              Seq
            </label>
            <label style="max-width: 160px; width: 160px; font-size: 12px">Produto</label>
            <label style="max-width: 100px; width: 100px; font-size: 12px">
            Qtd.
          </label>
          ${
						!user.ocultaPreco
							? `<label style="max-width: 100px; width: 100px; font-size: 12px">
          Preço
        </label>
        <label style="max-width: 100px; width: 100px; font-size: 12px">
          Vitrine
        </label>`
							: ""
					}
            <label style="max-width: 50px; width: 50px; font-size: 12px">
              Marca
            </label>
            <label style="max-width: 50px; width: 50px; font-size: 12px">
              Grade
            </label>
            <label style="max-width: 235px; width: 235px; font-size: 12px"></label>
          </div>

          ${favoritos.map(
						(fav, i) => `
          <div
            style="
              display: flex;
              background: ${i % 2 !== 0 ? "white" : "#F4F4F4"};
              font-size: 13px;
              margin: 0 10px;
            "
          >
            <img src="${
							fav.produto.IMAGEM_GRANDE
						}" alt="Imagem do produto" title="Imagem do produto" style="display: block; padding: 20px 5px; width: 100px; height: 10%;" />
            <label style="padding: 5px 0; max-width: 160px; width: 160px; font-size: 12px">
              ${fav.produto.DESCRICAO_PRODUTO}
            </label>
            <label style="padding: 5px 0; max-width: 100px; width: 100px; font-size: 12px">
            ${fav.quantidade}
          </label>
          ${
						!user.ocultaPreco
							? `<label style="padding: 5px 0; max-width: 100px; width: 100px; font-size: 12px">
          ${formatValor(fav.produto.PRECO_VENDA)}
        </label>
        <label style="padding: 5px 0; max-width: 100px; width: 100px; font-size: 12px">
          ${formatValor(fav.produto.PRECO_VITRINE)}
        </label>`
							: ""
					}
            <label style="padding: 5px 0; max-width: 50px; width: 50px; font-size: 12px">
              ${fav.produto.MARCA}
            </label>
            <label style="padding: 5px 0; max-width: 50px; width: 50px; font-size: 12px">
              ${fav.grade}
            </label>
            <div style="display: flex; padding: 5px 0; max-width: 235px; width: 235px; font-size: 12px">
            ${getGrade(fav.grade)
							.map(
								(g) => `
                      <div style="display: block">
                        <div
                          style="
                            width: 22px;
                            height: 22px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border: 1px solid #b3b3b3;
                            border-radius: 3px;
                            font-size: 12px;
                            color: #767676;
                            margin-right: 8px;
                            background: #fff;
                          "
                        >
                          ${g.tamanho}
                        </div>
                        <div
                          style="
                            width: 22px;
                            height: 22px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border: 1px solid #b3b3b3;
                            border-radius: 3px;
                            font-size: 12px;
                            color: #767676;
                            margin-right: 8px;
                            background: #fff;
                          "
                        >
                          ${g.quantidade}
                        </div>
                      </div>`,
							)
							.join("")}
            </div>
            </div>`,
					)}  
      </body>
    </html>
    `;
		produtosService
			.sendEmail(email, html, Observacao)
			.then(() => {
				setLoadingEmail(false);
				setIsOpen(false);
				setConclusao(2);
			})
			.catch(() => {
				setLoadingEmail(false);
				setError("Não foi possível enviar a lista por email!");
			});
	};

	return (
		<s.Container>
			<Modal
				isOpen={isOpen ? true : false}
				style={s.customStyles}
				onRequestClose={() => setIsOpen(false)}>
				<s.ModalContainer>
					<h1>Insira seu e-mail:</h1>
					<input
						name="email"
						value={email}
						onChange={(e) => {
							setEmail(e.target.value);
							setError();
						}}
						placeholder="E-mail"
					/>
					<Button
						label={isOpen === 2 ? "Concluir pedido" : "Enviar lista"}
						onClick={() => (isOpen === 2 ? fechar() : enviarEmail())}
						isLoading={isOpen === 2 ? Loading : LoadingEmail}
						disabled={!email}
					/>
					<label>
						Ao concluir sua compra seus produtos serão enviados por email e seus
						favoritos serão limpos!
					</label>
					{error && <legend>{error}</legend>}
				</s.ModalContainer>
			</Modal>
			<s.Title>RESUMO DO PEDIDO:</s.Title>
			<s.Infos ocultaPreco={user.ocultaPreco}>
				<h3>
					Total de pares: <br />
					{totalPares}
				</h3>
				<div>
					<h3>Condições de pagamento:</h3>
					<Select
						customStyles={s.selectStyles}
						options={options}
						value={pagamento}
						onChange={(v) => setPagamento(v)}
					/>
				</div>
				<h2 style={pagamento?.desconto ? { opacity: 1 } : { opacity: 0 }}>
					Valor total:
					<p>{formatValor(totalValor)}</p>
					{/* <span>{`${pagamento?.desconto}%`}</span> */}
				</h2>
				<h2>
					Valor da live: <br />
					{formatValor(totalValor - totalValor * (pagamento?.desconto / 100))}
				</h2>
			</s.Infos>
			<s.Footer ocultaPreco={user.ocultaPreco}>
				<input
					disabled={Loading}
					placeholder="Quero escrever uma observação"
					value={Observacao}
					onChange={(e) => {
						setObservacao(e.target.value);
						setError();
					}}
				/>
				<div className="buttons">
					<Button
						disabled={Loading}
						label={"Enviar lista por e-mail"}
						style={{ marginRight: "20px", ...styleButton }}
						onClick={() => {
							setIsOpen(true);
							setError();
						}}
					/>
					<Button
						disabled={Loading || !pagamento.value}
						isLoading={Loading}
						onClick={() => {
							setIsOpen(2);
							setError();
						}}
						label={"Concluir a compra"}
						green
						style={{ ...styleButton }}
					/>
				</div>
			</s.Footer>
		</s.Container>
	);
};
export default Conclusao;
