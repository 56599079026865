import styled from "styled-components";
import { colors } from "../../assets";

export const Container = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 60px;
	padding-bottom: 5px;
	a {
		cursor: pointer;
		padding: 0 30px;
		font-size: 12px;
		text-transform: uppercase;
		font-weight: bold;
		height: 100%;
		display: flex;
		align-items: center;
		:hover {
			background: white;
		}
	}
	background: ${colors.gray};
	box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
	.logo {
		margin: 0 10px;
		cursor: pointer;
	}
`;
