import React from "react";
import * as s from "./styled-input";

const Input = (props) => {
  const {
    disabled,
    type,
    name,
    id,
    placeholder,
    value,
    onChange,
    style,
    maxLength,
    className,
    textarea,
  } = props;
  if (textarea)
    return (
      <s.TextArea
        id={id}
        disabled={disabled}
        className={"animated-linear " + className}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        style={style}
        maxLength={maxLength}
      />
    );
  return (
    <s.Input
      disabled={disabled}
      id={id}
      className={"animated-linear " + className}
      type={type}
      name={name}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      style={style}
      maxLength={maxLength}
    />
  );
};
export default Input;
