import styled from "styled-components";
import { colors } from "../../../assets";

// INPUT
export const InputContainer = styled.form`
	display: flex;
	background: ${colors.primary};
	border-radius: 4px;
	width: 330px;
	position: relative;

	input {
		width: calc(100% - 40px);
		height: 40px;
		padding: 0 10px;
		background: #f7f7f7;
		border-radius: 4px;
	}
	button {
		border: none;
		outline: none;
		cursor: pointer;
		height: 40px;
		width: 40px;
		background: ${colors.primary};
		border-radius: 0px 5px 5px 0px;
		display: flex;
		align-items: center;
		justify-content: center;
		img {
			height: 15px;
			width: 15px;
		}
		:disabled {
			opacity: 0.8;
			cursor: not-allowed;
		}
	}
`;

export const Drop = styled.div`
	width: 290px;
	max-height: 290px;
	height: 150px;
	position: absolute;
	box-shadow: -1px -1px 3px rgb(0 0 0 / 10%), 1px 1px 3px rgb(0 0 0 / 10%);
	border-radius: 4px;
	margin: 53px 0 0 1px;
	background: white;
	bottom: 0;
	transform: translateY(100%);
	display: block;

	div {
		display: block;
	}

	.item {
		width: 100%;
		cursor: pointer;
		font-size: 16px;
		line-height: 25px;
		padding: 5px 10px;
		color: gray;
		display: block;

		:hover {
			background: #f4f4f4;
		}
	}

	.item-checkbox {
		cursor: pointer;
		font-size: 16px;
		line-height: 25px;
		padding: 5px 10px;
		color: gray;
		display: flex;
		align-items: center;
		justify-content: space-between;

		:hover {
			background: #f4f4f4;
		}
	}

	legend {
		color: gray;
		text-align: center;
		margin-top: 20px;
	}
`;

export const Check = styled.span`
	display: flex;
	min-width: 18px;
	min-height: 18px;
	max-width: 18px;
	max-height: 18px;
	margin-right: 10px;
	cursor: pointer;
	justify-content: center;
	align-items: center;
	background: ${({ all }) => (all ? `#e94e5c` : "white")};
	border-radius: 4px;
	border: 1px solid gray;
	img {
		width: 18px;
		height: 10px;
	}
	:active {
		animation: fadeIn ease 1s;
		-webkit-animation: fadeIn ease 1s;
		-moz-animation: fadeIn ease 1s;
		-o-animation: fadeIn ease 1s;
		-ms-animation: fadeIn ease 1s;
	}
`;
