import React, { useState, useEffect } from "react";
import * as s from "./styled-produtos.js";
import { useSelector } from "react-redux";
import { Loader } from "../../components";
import { icons, images } from "../../assets/index.js";
import { produtosService } from "../../services/index.js";
import { Scrollbars } from "react-custom-scrollbars";

const Produtos = ({ noHeader, show }) => {
  const live = useSelector((state) => state.live);
  const loading = useSelector((state) => state.live.loadingProdutos);
  const { favoritos, produtos, grades } = live;
  const [favoritados, setFavoritados] = useState([]);

  const setItem = (item) => {
    if (!verifyHearth(item)) {
      setFavoritados([...favoritados, item.docId]);
      let gradeTotal;
      grades.forEach((g) => {
        if (g.id === item.GRADE_DISPONIVEL.split(",")[0])
          gradeTotal = g.gradeTotal;
      });
      produtosService.addFavorito(
        item,
        item.GRADE_DISPONIVEL.split(",")[0],
        gradeTotal,
        item.docId
      );
    }
  };

  useEffect(() => {
    let newFavoritados = [];
    favoritos.forEach(
      (fav) => favoritados.includes(fav) && newFavoritados.push(fav)
    );
    setFavoritados(newFavoritados);
  }, [favoritos]);

  const verifyHearth = (prod) => {
    let test = false;
    favoritos.forEach((item) => {
      if (item.produto.ID === prod.ID && item.produto.COR === prod.COR)
        test = true;
    });
    return test;
  };

  var HEADER_TABS = window.innerWidth >= 1450 ? 65 : 0;

  const renderList = () => {
    return (
      <s.List className="animated-linear fade-in">
        {!loading &&
          produtos &&
          produtos.length > 0 &&
          produtos.map(
            (prod) =>
              prod.PRINCIPAL === "S" &&
              !prod.COMBO && (
                <s.Produto
                  key={prod.docId}
                  onClick={() => setItem(prod)}
                  className="animated-linear"
                >
                  <img
                    alt="coração-produtos"
                    src={
                      verifyHearth(prod) || favoritados.includes(prod.docId)
                        ? icons.hearthRed
                        : icons.hearthGray
                    }
                    className={`hearth ${
                      verifyHearth(prod) ||
                      (favoritados.includes(prod.docId) && `pulse-heart-prod`)
                    }`}
                  />
                  <img
                    alt="imagem-produto-pequeno"
                    src={prod.IMAGEM_PEQUENA || images.default}
                    id={`imagem-produtos-live-${prod.docId}`}
                    onError={() => {
                      let e = document.getElementById(
                        `imagem-produtos-live-${prod.docId}`
                      );
                      e.src = images.default;
                    }}
                  />
                  <div className="ref-container">
                    <label className="ref">CAB.: {prod.CABEDAL}</label>
                  </div>
                </s.Produto>
              )
          )}
      </s.List>
    );
  };

  if (!show) return <div />;
  return (
    <s.Container id="container-chat">
      {!noHeader && (
        <s.Header>
          {loading ? <Loader size={30} color="red" /> : "PRODUTOS DA LIVE"}
        </s.Header>
      )}
      {HEADER_TABS > 0 ? (
        <Scrollbars style={{ height: `calc(100% - ${HEADER_TABS}px)` }}>
          {renderList()}
        </Scrollbars>
      ) : (
        renderList()
      )}
    </s.Container>
  );
};

export default Produtos;
