import React, { useState, useEffect, useRef } from "react";
import { Check, Loader } from "../../../components";
import Scrollbars from "react-custom-scrollbars";
import * as s from "./styled-search-input";
import { icons } from "../../../assets";
import { useSelector } from "react-redux";

const livesStatus = [
	{ id: 1, nome: "Não publicada", status: true },
	{ id: 2, nome: "Transmitindo", status: true },
	{ id: 3, nome: "Finalizada", status: true },
	{ id: 4, nome: "Agendada", status: true },
];

const SearchInput = React.memo(
	({
		placeholder,
		icon = icons.search,
		options = livesStatus,
		selectedOption,
		setSelected,
		statusActive = [],
		loading,
		optionToShow = "nome",
	}) => {
		const [drop, setDrop] = useState(false);
		const [searching, setSearching] = useState(false);
		const searchRef = useRef(null);
		const [value, setValue] = useState("");
		const [filteredOptions, setFilteredOptions] = useState([]);

		const usuario = useSelector((state) => state.usuario.usuario);
		const lives = usuario && usuario.lives ? usuario.lives : [];

		useEffect(() => {
			if (setDrop) {
				document.addEventListener("click", handleClickOutside, true);
				return () => {
					document.removeEventListener("click", handleClickOutside, true);
				};
			}
		}, [setDrop]);

		const handleClickOutside = ({ target }) => {
			if (setDrop) if (!searchRef.current.contains(target)) setDrop(false);
		};

		useEffect(() => {
			setFilteredOptions(options || []);
		}, [options]);

		useEffect(() => {
			setValue(selectedOption);
		}, [selectedOption]);

		const handleInputValue = (opt) => {
			setSelected(opt);
			setDrop(false);
		};

		const search = (value) => {
			setFilteredOptions(
				lives.filter((info) =>
					info.nome.toLowerCase().match(value.toLowerCase()),
				),
			);
		};

		const handleStatusOption = (opt) => {
			if (statusActive.includes(opt.nome)) {
				setSelected(statusActive.filter((status) => status !== opt.nome));
				return;
			}

			setSelected([...statusActive, opt.nome]);
		};

		return (
			<s.InputContainer
				onSubmit={(e) => {
					e.preventDefault();
					if (!value) {
						setSelected([
							"Não publicada",
							"Transmitindo",
							"Agendada",
							"Finalizada",
						]);
						return;
					}
					search(value);
				}}
				ref={searchRef}>
				<input
					value={value || ""}
					placeholder={placeholder}
					onClick={() => setDrop(true)}
					onChange={(e) => {
						if (!drop) {
							setDrop(true);
						}
						if (e.target.value.length <= 0) {
							setDrop(false);
						}
						setValue(e.target.value);
					}}
				/>
				<button type="submit">
					{loading ? <Loader size={20} /> : <img alt="input-icon" src={icon} />}
				</button>
				{drop && filteredOptions.length > 0 && (
					<s.Drop elements={filteredOptions.length}>
						<Scrollbars
							style={{ padding: 0, display: "block" }}
							renderTrackHorizontal={() => <div />}>
							{filteredOptions.map((opt) => {
								if (value) {
									return (
										<div
											key={opt.id}
											className="item"
											onClick={() => handleInputValue(opt)}>
											{opt[optionToShow]}
										</div>
									);
								}
								return (
									<div
										key={opt.id}
										className="item-checkbox"
										onClick={() => handleStatusOption(opt)}>
										{opt[optionToShow]}
										<s.Check all={statusActive.includes(opt[optionToShow])}>
											{statusActive.includes(opt[optionToShow]) && (
												<img src={icons.check} />
											)}
										</s.Check>
									</div>
								);
							})}
						</Scrollbars>
					</s.Drop>
				)}
			</s.InputContainer>
		);
	},
);

export default SearchInput;
